// images
import smsImage from "../../../../assets/sms.svg";
import vincereImage from "../../../../assets/vincere.svg";
import emailImage from "../../../../assets/email.svg";
import adaIcon from "../../../../assets/Ada_icon.png";

interface WorkflowPerformanceMobilePropsDefinition {
  jobPanelData: any;
}

const WorkflowPerformanceMobile: React.FC<
  WorkflowPerformanceMobilePropsDefinition
> = ({ jobPanelData }) => {
  let emailApplied =
    (jobPanelData.email.engaged / jobPanelData.email.applied) * 100;
  let smsApplied = (jobPanelData.sms.engaged / jobPanelData.sms.applied) * 100;
  let candidatesApplied = jobPanelData.email.applied + jobPanelData.sms.applied;
  let candidatesAppliedViaEmail = jobPanelData.email.applied;
  let candidatesAppliedViaSMS = jobPanelData.sms.applied;
  let candidatesUnsubscribed =
    jobPanelData.email.unsubscribed + jobPanelData.sms.unsubscribed;
  let candidatesUnsubscribedViaEmail = jobPanelData.email.unsubscribed;
  let candidatesUnsubscribedViaSMS = jobPanelData.sms.unsubscribed;

  return (
    <div aria-label="workflow-performance-mobile" className="w-[375px]">
      <h2
        className="text-2xl text-black font-bold mb-8 mt-10"
        aria-label="main-heading"
      >
        Workflow performance
      </h2>
      {/** Jobs section */}
      <div className="border border-grey rounded-t-xl border-b-0">
        <div className="block py-5 bg-sidebar rounded-t-xl px-6 border-b">
          <h2 className="text-xl font-medium pl-3">Jobs</h2>
        </div>
        <div className="flex flex-row border-l-2 border-l-jobCoverageBorder">
          <div className="heading-section border-r border-r-grey py-5 px-6 pr-16">
            <div className="flex flex-col">
              <p className="font-medium mt-2 mb-3 pl-2">Tools</p>
              <p className="font-medium mb-3 pl-2">Targets</p>
              <p className="font-medium mb-4 pl-2">Results</p>
            </div>
          </div>
          <div className="content-section py-5 px-7">
            <div className="flex flex-col">
              <img
                src={vincereImage}
                alt="Vincere Logo"
                className="w-1/4 mb-2"
              />
              <div className="flex flex-row mb-3">
                <p className="font-semibold">{jobPanelData.jobs}</p>
                <p className="text-md font-normal pl-2">
                  {jobPanelData.jobs === 0 || jobPanelData.jobs > 1
                    ? "Jobs"
                    : "Job"}
                </p>
              </div>
              <div className="flex flex-col mb-2">
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">{jobPanelData.location}</p>
                  <p className="text-md font-normal pl-2">Location</p>
                </div>
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">{jobPanelData.salary}</p>
                  <p className="text-md font-normal pl-2">Salary</p>
                </div>
                <div className="flex flex-row">
                  <p className="font-semibold">
                    {jobPanelData.no_valid_details}
                  </p>
                  <p className="text-md font-normal pl-2">No Details</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Ada matches section */}
      <div className="border border-grey border-b-0">
        <div className="block py-5 bg-sidebar px-6 border-b">
          <h2 className="text-xl font-medium pl-3">Ada Matches</h2>
        </div>
        <div className="flex flex-row border-l-2 border-l-yellow">
          <div className="heading-section border-r border-r-grey py-5 px-6 pr-16">
            <div className="flex flex-col">
              <p className="font-medium mt-2 mb-3 pl-2">Tools</p>
              <p className="font-medium mb-3 pl-2">Targets</p>
              <p className="font-medium mb-4 pl-2">Results</p>
            </div>
          </div>
          <div className="content-section py-5 px-7">
            <div className="flex flex-col">
              <img src={adaIcon} alt="Ada Icon" className="w-1/6 mb-2" />
              <div className="flex flex-row mb-3">
                <p className="font-semibold">{jobPanelData.matches.matches}</p>
                <p className="text-md font-normal pl-2">
                  {jobPanelData.matches.matches === 0 ||
                  jobPanelData.matches.matches > 1
                    ? "Matches"
                    : "Match"}
                </p>
              </div>
              <div className="flex flex-col mb-2">
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">
                    {jobPanelData.matches.per_job}
                  </p>
                  <p className="text-md font-normal pl-2">Per job</p>
                </div>
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">
                    {jobPanelData.matches.per_owner}
                  </p>
                  <p className="text-md font-normal pl-2">Per owner</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Email matches section */}
      <div className="border border-grey border-b-0">
        <div className="block py-5 bg-sidebar px-6 border-b">
          <div className="col-span-1 flex flex-row justify-between">
            <div className="flex flex-row">
              <h2 className="font-medium text-md">Email</h2>
            </div>
            <p className="text-sm font-medium mt-1">
              {jobPanelData.email.total_sent} emails sent
            </p>
          </div>
        </div>
        <div className="flex flex-row border-l-2 border-l-purple">
          <div className="heading-section border-r border-r-grey py-5 px-6 pr-16">
            <div className="flex flex-col">
              <p className="font-medium mt-2 mb-3 pl-2">Tools</p>
              <p className="font-medium mb-3 pl-2">Targets</p>
              <p className="font-medium mb-4 pl-2">Results</p>
            </div>
          </div>
          <div className="content-section py-5 px-7">
            <div className="flex flex-col">
              <img src={emailImage} alt="Email Image" className="w-1/6 mb-2" />
              <div className="flex flex-row mb-3">
                <p className="font-semibold">{jobPanelData.email.total_sent}</p>
                <p className="text-md font-normal pl-2">
                  {jobPanelData.email.total_sent === 0 ||
                  jobPanelData.email.total_sent > 1
                    ? "Candidates"
                    : "Candidate"}
                </p>
              </div>
              <div className="flex flex-col mb-2">
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">{jobPanelData.email.engaged}</p>
                  <p className="text-md font-normal pl-2">Engaged</p>
                </div>
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">{jobPanelData.email.applied}</p>
                  <p className="text-md font-normal pl-2">Applied</p>
                </div>
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">
                    {!Number.isNaN(emailApplied)
                      ? Math.round(emailApplied) + "%"
                      : "0%"}
                  </p>
                  <p className="text-md font-normal pl-2">Applied</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** SMS matches section */}
      <div className="border border-grey ">
        <div className="block py-5 bg-sidebar px-6 border-b">
          <div className="col-span-1 flex flex-row justify-between">
            <div className="flex flex-row">
              <h2 className="font-medium text-md">SMS</h2>
            </div>
            <p className="text-sm font-medium mt-1">
              {jobPanelData.sms.sent} SMS sent
            </p>
          </div>
        </div>
        <div className="flex flex-row border-l-2 border-l-purple">
          <div className="heading-section border-r border-r-grey py-5 px-6 pr-16">
            <div className="flex flex-col">
              <p className="font-medium mt-2 mb-3 pl-2">Tools</p>
              <p className="font-medium mb-3 pl-2">Targets</p>
              <p className="font-medium mb-4 pl-2">Results</p>
            </div>
          </div>
          <div className="content-section py-5 px-7">
            <div className="flex flex-col">
              <img src={smsImage} alt="SMS Image" className="w-1/5 mb-2" />
              <div className="flex flex-row mb-3">
                <p className="font-semibold">{jobPanelData.sms.sent}</p>
                <p className="text-md font-normal pl-2">
                  {jobPanelData.sms.sent === 0 || jobPanelData.sms.sent > 1
                    ? "Candidates"
                    : "Candidate"}
                </p>
              </div>
              <div className="flex flex-col mb-2">
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">{jobPanelData.sms.engaged}</p>
                  <p className="text-md font-normal pl-2">Engaged</p>
                </div>
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">{jobPanelData.sms.applied}</p>
                  <p className="text-md font-normal pl-2">Applied</p>
                </div>
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">
                    {!Number.isNaN(smsApplied)
                      ? Math.round(smsApplied) + "%"
                      : "0%"}
                  </p>
                  <p className="text-md font-normal pl-2">Applied</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Applied section */}
      <div className="border border-grey border-b-0">
        <div className="block py-5 bg-sidebar px-6 border-b">
          <h2 className="text-xl font-medium pl-3">Applied</h2>
        </div>
        <div className="flex flex-row border-l-2 border-l-purple">
          <div className="heading-section border-r border-r-grey py-5 px-6 pr-16">
            <div className="flex flex-col">
              <p className="font-medium mt-2 mb-3 pl-2">Tools</p>
              <p className="font-medium mb-3 pl-2">Targets</p>
              <p className="font-medium mb-4 pl-2">Results</p>
            </div>
          </div>
          <div className="content-section py-5 px-7">
            <div className="flex flex-col">
              <img
                src={vincereImage}
                alt="Vincere Image"
                className="w-1/4 mb-2"
              />
              <div className="flex flex-row mb-3">
                <p className="font-semibold">{candidatesApplied}</p>
                <p className="text-md font-normal pl-2">
                  {candidatesApplied === 0 || candidatesApplied > 1
                    ? "Candidates"
                    : "Candidate"}
                </p>
              </div>
              <div className="flex flex-col mb-2">
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">{candidatesAppliedViaEmail}</p>
                  <p className="text-md font-normal pl-2">Via email</p>
                </div>
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">{candidatesAppliedViaSMS}</p>
                  <p className="text-md font-normal pl-2">Via SMS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Unsubscribed section */}
      <div className="border border-grey">
        <div className="block py-5 bg-sidebar px-6 border-b">
          <h2 className="text-xl font-medium pl-3">Unsubscribed</h2>
        </div>
        <div className="flex flex-row border-l-2 border-l-purple">
          <div className="heading-section border-r border-r-grey py-5 px-6 pr-16">
            <div className="flex flex-col">
              <p className="font-medium mt-2 mb-3 pl-2">Tools</p>
              <p className="font-medium mb-3 pl-2">Targets</p>
              <p className="font-medium mb-4 pl-2">Results</p>
            </div>
          </div>
          <div className="content-section py-5 px-7">
            <div className="flex flex-col">
              <img
                src={vincereImage}
                alt="Vincere Image"
                className="w-1/4 mb-2"
              />
              <div className="flex flex-row mb-3">
                <p className="font-semibold">{candidatesUnsubscribed}</p>
                <p className="text-md font-normal pl-2">
                  {candidatesUnsubscribed === 0 || candidatesUnsubscribed > 1
                    ? "Candidates"
                    : "Candidate"}
                </p>
              </div>
              <div className="flex flex-col mb-2">
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">
                    {candidatesUnsubscribedViaEmail}
                  </p>
                  <p className="text-md font-normal pl-2">Via email</p>
                </div>
                <div className="flex flex-row mb-2">
                  <p className="font-semibold">
                    {candidatesUnsubscribedViaSMS}
                  </p>
                  <p className="text-md font-normal pl-2">Via SMS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowPerformanceMobile;
