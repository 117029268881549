import WorkflowInfo from "../../common/WorkflowInfo.component";

interface WorkflowPropsDefinition {
  workflowInfoData: any;
}

const Workflow: React.FC<WorkflowPropsDefinition> = ({ workflowInfoData }) => {
  return (
    <div aria-label="workflow-information-panel">
      {/** Parameters value will changed based on the API requests */}
      <WorkflowInfo id={"job-coverage"} name={"Job Coverage "} description={"Discover better matched and motivated candidates to make more placements."} status={"Active"} frequency={"Weekely"} activityStatus={"Yesterday"}/>
    </div>
    );
};

export default Workflow;
