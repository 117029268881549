import axios from "../utils/axiosDataHandler";
import { CandidateRequest } from "../types/candidate";

export const workflowDetails = async () => {
  const responseHeaders = {
    headers: {
      correlation: "Candidates Data values",
    },
  };
  return axios
    .get(
      `/workflows/ucd`,
      responseHeaders
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
};

export const workflowInformation = async (id: any) => {
  const responseHeaders = {
    headers: {
      correlation: "Candidates Data values",
    },
  };
  return axios
    .get(
      `/workflows/${id}`,
      responseHeaders
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
};

export const workflowPerformanceDetails = async (id: any, fromDate: any, toDate: any) => {
  const responseHeaders = {
    headers: {
      correlation: "Candidates Data values",
    },
  };
  return axios
  .get(
    `/workflows/${id}/activity-metrics?from_date=${fromDate}&to_date=${toDate}`,
    responseHeaders
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
}

export const recruiterDetails = async (id: any, fromDate: any, toDate: any) => {
  const responseHeaders = {
    headers: {
      correlation: "Candidates Data values",
    },
  };
  return axios
  .get(
    `/workflows/${id}/recruiter-metrics?from_date=${fromDate}&to_date=${toDate}`,
    responseHeaders
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
}

export const fetchRecruiters = (param: CandidateRequest) => {

  const responseHeaders = {
    headers: {
      correlation: "Candidates Data values",
    },
  };

  return axios
    .get(
      `/workflows/ucd/recruiter-drilldown?q=${param.q}&offset=${param.offset}&limit=${param.limit}&sort_field=${param.sort_field}&sort_by=${param.sort_by}`,
      responseHeaders
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
};

export const jobDetails = async (id: any, fromDate: any, toDate: any) => {
  const responseHeaders = {
    headers: {
      correlation: "Candidates Data values",
    },
  };
  return axios
  .get(
    `/workflows/${id}/job-metrics?from_date=${fromDate}&to_date=${toDate}`,
    responseHeaders
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
}