import * as React from "react";
import ChartContent from "../workflows/ucd/chart";

// helpers
import { numberWithCommas } from "../../helpers/common.helpers";

// Icons
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

interface WorkflowPropsDefinition {
  databaseSize: any;
  livingDatabase: any;
  excludedFromFlow: any;
  uncontactable: any;
  id: string;
}

const DataHealthInfo: React.FC<WorkflowPropsDefinition> = ({
  databaseSize,
  livingDatabase,
  excludedFromFlow,
  uncontactable,
  id,
}) => {
  const [showDropdown, setShowDropDown] = useState(false);

  const openDropDownMenu = () => {
    setShowDropDown(!showDropdown);
  };

  return (
    <div aria-label={`${id}-data-health-panel`}>
      <div className="grid grid-cols-2 md:flex md:justify-between lg:grid lg:grid-cols-2 lg:gap-2 xl:gap-4">
        <div className="col-span-2 lg:col-span-1">
          <h4 className="font-medium text-base md:text-sm mb-1 md:mb-0 ">
            Data Health
          </h4>
        </div>
        <div
          className="col-span-2 lg:col-span-1 flex justify-between md:justify-end md:items-end"
          aria-label="total-database"
        >
          <div className="flex flex-row">
            <p className="mr-2 text-sm md:text-xs mb-[1px] font-medium">
              Total Database
            </p>
            <p className="font-semibold text-xs flex flex-col justify-center">
              {numberWithCommas(databaseSize)}
            </p>
          </div>
          <button onClick={openDropDownMenu} className="md:hidden">
            {showDropdown ? (
              <ChevronUpIcon className="w-6 h-6 ml-7" />
            ) : (
              <ChevronDownIcon className="w-6 h-6 ml-7" />
            )}
          </button>
        </div>
      </div>
      {showDropdown ? (
        <div className="grid grid-cols-3 md:grid-cols-4 gap-2 mt-2">
          {/** Left side */}
          <div className="col-span-2 md:col-span-3 mt-6 mr-8">
            <div className="flex flex-row justify-between flex-wrap lg:w-15 xl:w-64 2xl:w-auto">
              {/** Living Database section */}
              <div className="flex flex-col mb-4" aria-label="target-segment">
                <div className="flex flex-row">
                  <div className="w-3 h-3 bg-darkBlue rounded-full mr-2 mt-0.5"></div>
                  <h5 className="text-xs">Target Segment</h5>
                </div>
                <p className="font-semibold text-black text-xs mt-2">
                  {numberWithCommas(livingDatabase)}
                </p>
              </div>
              {/** Excluded from flow section */}
              <div className="flex flex-col" aria-label="excluded">
                <div className="flex flex-row lg:max-w-[100px] 2xl:max-w-full justify-center items-center">
                  <div className="w-3 h-3 bg-lightBlue rounded-full mr-2"></div>
                  <h5 className="text-xs">Excluded</h5>
                </div>
                <p className="font-semibold text-black text-xs mt-2">
                  {numberWithCommas(excludedFromFlow)}
                </p>
              </div>
              {/** Uncontactable section */}
              <div className="flex flex-col" aria-label="invalid-email">
                <div className="flex flex-row">
                  <div className="w-3 h-3 bg-grey rounded-full mr-2 mt-0.5"></div>
                  <h5 className="text-xs">Invalid Email</h5>
                </div>
                <p className="font-semibold text-black text-xs mt-2">
                  {numberWithCommas(uncontactable)}
                </p>
              </div>
            </div>
          </div>
          {/** right side */}
          <div className="col-span-1 flex justify-end">
            <div className="container" id="container">
              <ChartContent
                livingDatabase={livingDatabase}
                excluded={excludedFromFlow}
                uncontactable={uncontactable}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className="hidden md:block">
        <div className="grid grid-cols-3 md:grid-cols-4 gap-2 mt-2">
          {/** Left side */}
          <div className="col-span-2 md:col-span-3 mt-6 mr-8">
            <div className="flex flex-row justify-between flex-wrap lg:w-15 xl:w-64 2xl:w-auto">
              {/** Living Database section */}
              <div className="flex flex-col mb-4" aria-label="target-segment">
                <div className="flex flex-row">
                  <div className="w-3 h-3 bg-darkBlue rounded-full mr-2 mt-0.5"></div>
                  <h5 className="text-xs">Target Segment</h5>
                </div>
                <p className="font-semibold text-black text-xs mt-2">
                  {numberWithCommas(livingDatabase)}
                </p>
              </div>
              {/** Excluded from flow section */}
              <div className="flex flex-col mb-4 md:mb-0" aria-label="excluded">
                <div className="flex flex-row lg:max-w-[100px] 2xl:max-w-full justify-center items-center">
                  <div className="w-3 h-3 bg-lightBlue rounded-full mr-2"></div>
                  <h5 className="text-xs">Excluded</h5>
                </div>
                <p className="font-semibold text-black text-xs mt-2">
                  {numberWithCommas(excludedFromFlow)}
                </p>
              </div>
              {/** Uncontactable section */}
              <div className="flex flex-col" aria-label="invalid-email">
                <div className="flex flex-row">
                  <div className="w-3 h-3 bg-grey rounded-full mr-2 mt-0.5"></div>
                  <h5 className="text-xs">Invalid Email</h5>
                </div>
                <p className="font-semibold text-black text-xs mt-2">
                  {numberWithCommas(uncontactable)}
                </p>
              </div>
            </div>
          </div>
          {/** right side */}
          <div className="col-span-1 flex justify-end">
            <div className="container" id="container">
              <ChartContent
                livingDatabase={livingDatabase}
                excluded={excludedFromFlow}
                uncontactable={uncontactable}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataHealthInfo;
