import * as React from "react";
import { useState, useRef, useEffect } from "react";
import logo from "../assets/autometry-logo.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HomeIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Bars3Icon,
  XMarkIcon,
  UserIcon,
} from "@heroicons/react/24/solid";

import { workflowDetails } from "./services/workflowsDetails";
import { getItem, setItem } from "../components/utils/persistanceStorage";

//reducers
import { setSelectionID } from "../reducers/common-reducer";

const {
  REACT_APP_BASE_AUTH_URL,
} = process.env;

interface SideBarProps {
  pathName?: string;
}

const SideBar: React.FC<SideBarProps> = ({ pathName }) => {
  const navigate = useNavigate();

  //stored state
  const dispatch = useDispatch();

  const ref = useRef(null as any);
  const [showDropdown, setShowDropDown] = useState(false);
  const [activeDashboard, setActiveDashboard] = useState(false);
  const [activeGDPR, setActiveGDPR] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [dropDownMenu, setDropDownMenu] = useState([] as any);

  let name = localStorage.getItem("name") as any;

  const openDropDownMenu = () => {
    setShowDropDown(!showDropdown);
  };

  let selectedID = useSelector((state: any) => state.selection.selectionID);
  let workflowID = localStorage.getItem("selectedID") as string;

  useEffect(() => {
    workflowDetails()
      .then((data) => {
        if (data) {
          setDropDownMenu(data);
        } else {
          console.log("no data found");
        }
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  }, []);

  const userLogout = async () => {
    const token = getItem("token");
    const organisation = getItem("organisation");
    if (token) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            token,
            organisation
          }
        )
      };
      fetch(`${REACT_APP_BASE_AUTH_URL}/logout`, requestOptions);
    }
    localStorage.clear();
    navigate("/");
    return Promise.resolve();
  }

  React.useEffect(() => {
    if(name !== null) {
      if (pathName === "/dashboard") {
        setActiveGDPR(false);
        setActiveDashboard(true);
      } else if (pathName?.match("/workflows/")) {
        setShowDropDown(true);
        setActiveGDPR(true);
        setActiveDashboard(false);
      }
    } else {
      navigate("/");
    }
  }, []);

  const openMobileMenu = () => {
    setShowSideMenu(true);
  };

  const showPopupMenu = () => {
    setShowLogout(!showLogout);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showSideMenu && ref.current && !ref.current.contains(e.target)) {
        setShowSideMenu(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showSideMenu]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showLogout && ref.current && !ref.current.contains(e.target)) {
        setShowLogout(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showLogout]);

  const getComputedClasses = (id: any) => {
    let name =
      workflowID !== null ? workflowID.replace(/['"]+/g, "") == id : null;
    if (selectedID === id || name) {
      return "block px-4 py-2 border-l-4 border-green hover:border-lightgreen lg:bg-sidebar text-black text-left mb-2 font-semibold text-xs";
    } else {
      return "block px-4 py-2 border-l-4 border-sidebar hover:border-l-4 hover:border-green text-black text-left mb-2 font-semibold text-xs";
    }
  };
  let dashboardPermissions = localStorage.getItem("dashboard") as any;
  let doShowDropdown = true;
  let homePath = dashboardPermissions==="true"?"/dashboard": "/workflows/ucd";
  let workflowsTitle = dashboardPermissions==="true"? "Workflows": "Home";
  return (
    <div
      className="flex flex-col xs:absolute top-8 left-8 lg:relative lg:top-0 lg:left-0 lg:p-0 xs:top-0 xs:left-0 xs:px-5 xs:py-3 xs:w-full xs:bg-sidebar xs:border-b-2 xs:border-grey z-50"
      aria-label="side-bar"
    >
      <div className="xs:grid xs:grid-cols-2 lg:hidden">
        <div className="flex flex-row">
          <Bars3Icon
            className="h-10 w-10 lg:hidden block cursor-pointer col-span-1 border border-grey rounded-md py-1.5"
            onClick={openMobileMenu}
            aria-label="bar-icon"
          />
          <p className="font-semibold text-black text-sm ml-3 flex flex-col justify-center">Home</p>
        </div>

        <div className="flex col-span-1 justify-end">
          <img
            src={logo}
            alt="autometry-logo-mobile"
            className="lg:w-3/12 md:w-1/3 w-1/2 sm:w-1/2"
          />
        </div>
      </div>
      <div
        className={
          showSideMenu
            ? "flex flex-col h-screen p-5 xs:bg-white shadow w-60 absolute xs:-left-1 xs:-top-1 lg:relative lg:top-0 lg:left-0 lg:bg-sidebar z-50 lg:min-w-fit"
            : "flex flex-col h-screen sticky p-5 xs:bg-white shadow w-60 lg:bg-sidebar lg:block hidden"
        }
        ref={ref as any}
      >
          <div className="flex items-center mt-8 mb-10 xs:hidden lg:block">
            <a
              href={homePath}
              onClick={() => {
                dispatch(setSelectionID(""));
                setItem("selectedID", "");
              }}
            >
              <img src={logo} alt="autometry-logo-desktop" className="w-11/12" />
            </a>
          </div>

          <div className="flex items-center mt-5 lg:hidden">
            <XMarkIcon
              className="w-8 h-8 cursor-pointer"
              onClick={() => setShowSideMenu(false)}
            />
          </div>
        <div className="flex-1">
          <ul className="pt-2 pb-4 space-y-1 text-sm">
            { dashboardPermissions === "true" && (
            <li
              className={
                activeDashboard
                  ? "rounded-md hover:bg-gray-100 bg-activeLink rounded-10 mb-3"
                  : "rounded-md hover:bg-gray-100 mb-3"
              }
              aria-label="home"
            >
              <button
                onClick={() => {
                  dispatch(setSelectionID(""));
                  setItem("selectedID", "");
                  navigate(homePath);
                }}
                className="flex items-center p-2 space-x-3 rounded-md w-full"
              >
                <HomeIcon className="h-6 w-6" />
                <span className="font-semibold text-sm">Home</span>
              </button>
            </li>
                )}
            <li
              className={
                activeGDPR
                  ? "rounded-md bg-activeLink hover:bg-activeLink"
                  : "rounded-md hover:bg-sidebar"
              }
              aria-label="workflow-tab"
            >
              <button
                className="flex items-center pt-2 pb-2 rounded-md flex-row hover:bg-gray-100 w-full"
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                onClick={openDropDownMenu}
              >
                <div className="text-black font-semibold rounded-lg text-sm inline-flex ml-2 flex flex-row">
                  { dashboardPermissions === "true" ? (
                      <svg className="svg-inline--fa fa-bolt fa-w-10 icon-wrapper nav-icon workflows w-5 h-5 mr-3 mt-1" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="bolt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 8 29.5 23.3 29.5 8.4 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36z"></path></svg>
                      ) :
                      <HomeIcon className="h-6 w-7" />

                  }
                  <p className="flex flex-col justify-center">{workflowsTitle}</p>
                  {doShowDropdown ? (
                    <ChevronUpIcon className="w-6 h-6 ml-6 lg:ml-4 lg:mr-2" />
                  ) : (
                    <ChevronDownIcon className="w-6 h-6 ml-6 lg:ml-4 lg:mr-2" />
                  )}
                </div>
              </button>
              </li>
              <div
                id="dropdown"
                className={
                  doShowDropdown ? "block bg-white lg:bg-sidebar" : "hidden"
                }
              >
                {/** 
                * 
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton"
                >
                 
                  {dropDownMenu.map((item: any) => (
                    // display a <li> element with the item.name
                    // each element needs to have a unique key
                    <li
                      key={item.id}
                    >
                      <button
                        onClick={() => {
                          setShowDropDown(true);
                          setItem("selectedID", item.id);
                          dispatch(setSelectionID(item.id));
                          navigate(`/workflows/${item.id}`);
                        }}
                        className={getComputedClasses(item.id) as any}
                        aria-label={item.name + " - " + item.id}
                      >
                        {item.name}
                      </button>
                    </li>
                  ))}
                </ul>
               */}

                <ul
                  className="py-2 text-gray-700 dark:text-gray-200 mt-3"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li
                    key={dropDownMenu.id}
                    aria-label={dropDownMenu.description}
                  >
                    <button
                      onClick={() => {
                        setShowDropDown(true);
                        setItem("selectedID", dropDownMenu.id);
                        dispatch(setSelectionID(dropDownMenu.id));
                        navigate(`/workflows/ucd`);
                      }}
                      className={getComputedClasses(dropDownMenu.id) as any}
                      aria-label={dropDownMenu.name}
                    >
                      {dropDownMenu.name}
                    </button>
                  </li>
                  {/*<li
                    key={"123"}
                    aria-label={"job coverage workflow description"}
                  >
                    <button
                      onClick={() => {
                        setShowDropDown(true);
                        setItem("selectedID", "123");
                        dispatch(setSelectionID("123"));
                        navigate(`/workflows/job_coverage`);
                      }}
                      className={getComputedClasses("123") as any}
                      aria-label={"job coverage"}
                    >
                      Job coverage
                    </button>
                    </li>*/}
                </ul>
              </div>

          </ul>
          {showLogout ? (
            <ul className="bg-white border-t-2 border-black absolute w-11/12 left-3 shadow-md bottom-16">
              <button
                className="w-full text-left"
                onClick={userLogout}
              >
                <li className="p-5 w-full text-base" aria-label="logout">
                  Logout
                </li>
              </button>
            </ul>
          ) : null}
          {/** footer */}
          <button
            className="cursor-pointer w-full absolute inset-x-0 bottom-2"
            onClick={showPopupMenu}
          >
            <div className="p-5 w-full flex flex-row">
              <UserIcon className="w-6 h-6 mt-0.5" />
              <div
                className={
                  showLogout
                    ? "font-semibold text-base ml-4 w-full text-left py-[3px]"
                    : "font-semibold text-base ml-4 w-full text-left border-r-2 border-green py-[3px]"
                }
              >
                {name ? name.replace(/['"]+/g, "") : null}
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
