import CandidateDrillDownTable from "./CandidateDrillDownTable.component";
import SideBar from "./SideBar.component";

const CandidateDrilldown = () => {
  return (
    <div className="flex h-screen" aria-label="candidate-drilldown">
      <div className="flex flex-1 overflow-hidden">
        <div className="flex bg-gray-100">
          <SideBar pathName={window.location.pathname} />
        </div>
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 overflow-y-auto">
            <CandidateDrillDownTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateDrilldown;
