import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DateRange } from "react-day-picker";
import { parseISO, startOfMonth } from "date-fns";

import { InputField } from "@autometry/ui-components";

import { fetchTotalUsers } from "../services/candidateData";

//components
import NewCandidates from "./sections/NewCandidates";
import NewJobs from "./sections/NewJobs";
import CandidateSent from "./sections/CandidateSent";
import LiveJobs from "./sections/LiveJobs";
import CandidateDatabase from "./sections/CandidateDatabase";
import ClientDatabase from "./sections/ClientDatabase";
import RadioButton from "../forms/RadioButton";
import DatePicker from "../common/DatePicker.component";
import DropDownSelect from "../forms/dropDownSelect";
import { useDispatch, useSelector } from "react-redux";
import { setDateRangeSelection } from "../../reducers/date-selection.reducer";
import { formattedDate } from "../../helpers/date.helpers";
import LoadingComponent from "../Loading/LoadingComponent";
import { setDateSelected } from "../../reducers/common-reducer";

const LandingPage = () => {
  const location = useLocation(); // location.state.recruiter_name
  let name = localStorage.getItem("name") as any;

  //stored state
  const dispatch = useDispatch();
  const [totalUsers, setTotalUsers] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [selectedRange, setSelectedRange] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });

  const monthType = useSelector((state: any) => state.monthType.monthSelection);
  const dateRange = useSelector(
    (state: any) => state.rangeSelection.selectedRange
  );

  const [param, setParam] = useState({
    q: "is_active:true",
    offset: 0,
    limit: 5,
    sort_field: "id",
    sort_by: 1,
  });

  useEffect(() => {
    setLoading(true);
    fetchTotalUsers(param, "recruiters")
      .then((data) => {
        if (data) {
          setTotalUsers(data.total);
          setLoading(false);
        } else {
          console.log("no data found");
        }
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  }, []);

  useEffect(() => {
    if (monthType === "current_month") {
      setSelectedRange({
        from: parseISO(
          formattedDate(startOfMonth(new Date()), "y-MM-dd'T'HH:mm:ss")
        ),
        to: parseISO(formattedDate(new Date(), "y-MM-dd'T'HH:mm:ss")),
      });
      dispatch(
        setDateRangeSelection({
          from: formattedDate(startOfMonth(new Date()), "y-MM-dd'T'HH:mm:ss"),
          to: formattedDate(new Date(), "y-MM-dd'T'HH:mm:ss"),
        })
      );
      dispatch(setDateSelected(false));
    } else {
      setSelectedRange({ from: undefined, to: undefined });
      dispatch(setDateRangeSelection(dateRange));
    }
  }, [monthType]);

  if (isLoading) {
    return <LoadingComponent showLoader={true} />;
  }

  return (
    <React.Fragment>
      <div className="landing-page w-full" aria-label="landing-page">
        <div className="block mt-20 lg:mt-10 px-8 pt-4 lg:pt-0 ">
          <div className="grid grid-cols-2 gap-4 content-start">
            <h1 className="font-semibold flex items-center justify-start text-3xl">
              {" "}
              Hello, {name.replace(/['"]+/g, "")}
            </h1>
            <div className="flex justify-end">
              <InputField dataTestId="total-users" width={"fit"} border={true}>
                <div className="p-1.5 flex flex-row justify-between">
                  <p className="text-black font-medium text-sm flex justify-center flex-col">
                    Number of Users
                  </p>
                  <h3 className="text-black pl-5 flex items-center font-medium text-2xl">
                    {totalUsers}
                  </h3>
                </div>
              </InputField>
            </div>
          </div>
        </div>

        <div className="block lg:px-8 lg:pt-3 lg:pb-12 2xl:px-8 2xl:pt-3 2xl:pb-20 py-3 px-8 pb-14 w-full">
          {/** Header section for selection */}
          <div className="flex justify-end items-end md:flex-row flex-col mt-2 mb-4">
            {/** Radio button selection */}
            <RadioButton setShowDatePicker={setShowDatePicker} />

            {/** Drop down menu */}
            {/*
            <div className="relative w-full max-w-xs -top-1">
              <DropDownSelect />
            </div>
            */}
          </div>
          {showDatePicker ? (
            <DatePicker
              setSelectedRange={setSelectedRange as any}
              selectedRange={selectedRange}
              pathName={window.location.pathname}
            />
          ) : null}
          {/** Header section end */}

          {/** Landing Page Cards */}
          <div className="grid grid-cols-2 gap-8 content-start">
            {/** New Candidated Card */}
            <div className="col-span-2 lg:col-span-1">
              <NewCandidates />
            </div>

            {/** New Jobs Card*/}
            <div className="col-span-2 lg:col-span-1">
              <NewJobs />
            </div>

            {/** Candidate Sent Jobs Card*/}
            <div className="col-span-2 lg:col-span-1">
              <CandidateSent />
            </div>

            {/** Live Jobs Card*/}
            <div className="col-span-2 lg:col-span-1">
              <LiveJobs />
            </div>

            {/** Candidate Database Card*/}
            <div className="col-span-2 lg:col-span-1">
              <CandidateDatabase />
            </div>

            {/** Client Database Card*/}
            <div className="col-span-2 lg:col-span-1">
              <ClientDatabase />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LandingPage;
