import axios from "../utils/axiosDataHandler";
import { CandidateRequest } from "../types/candidate";

export const fetchRevenue = (param: CandidateRequest) => {
  let application_data = JSON.parse(localStorage.getItem("ucd_dashbord_revenue_applications") as any);

  const data = {
    applications: application_data,
  };

  const responseHeaders = {
    headers: {
      correlation: "Candidates Data values",
    },
  };

  return axios
    .post(
      `/workflows/ucd/revenue-drilldown?q=${param.q}&offset=${param.offset}&limit=${param.limit}&sort_field=${param.sort_field}&sort_by=${param.sort_by}`,
      data,
      responseHeaders
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
};
