import * as React from "react";
import { useState, useCallback, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

// components
import { Button, ClickableButton, Table } from "@autometry/ui-components/";
import LandingPageLoader from "../Loading/LandingPageLoader";

//icons
import { MagnifyingGlassIcon, ArrowLeftIcon } from "@heroicons/react/24/solid";

// image
import desktopImage from "../../assets/desktop.svg";
import csvIcon from "../../assets/csv.svg";
import { SortingRule } from "react-table";
import { useSelector } from "react-redux";

interface DrillDownTableProps {
  tableData: any;
  loading: boolean;
  pageCount: number;
  pageNo: number;
  rowsPerPage: number;
  searchFilterText: string;
  setSearchFilterText: React.Dispatch<React.SetStateAction<string>>;
  setPageNo: Dispatch<SetStateAction<number>>;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  setParam: React.Dispatch<
    React.SetStateAction<{
      q: any;
      offset: number;
      limit: number;
      sort_field: any;
      sort_by: number;
    }>
  >;
  columns: {
    Header: string;
    accessor: string;
    width: string;
    Cell: (props: { cell: any; value: any }) => JSX.Element | null;
  }[];
}

const DrillDownTable: React.FC<DrillDownTableProps> = ({
  setSearchFilterText,
  columns,
  tableData,
  loading,
  pageCount,
  pageNo,
  setPageNo,
  setRowsPerPage,
  rowsPerPage,
  setParam,
  searchFilterText,
}) => {
  const navigate = useNavigate();
  const [sortTableBy, setSortTableBy] = useState<
    SortingRule<Record<string, unknown>>[] | undefined
  >([]);

  let header_name = useSelector((state: any) => state.selection.headerName);
  let tagName = useSelector((state: any) => state.selection.filterTags);
  let adminUser = localStorage.getItem("admin") as any;

  const handleSearch = (event: any) => {
    let searchValue = event.target.value;
    // Set the state to trigger a re-rendering
    setSearchFilterText(searchValue);
  };

  const handleFetchData = useCallback(
    ({ sortBy, pageIndex, pageSize }: any) => {
      if (sortBy?.length) {
        let currentPageNo: number;
        if (sortBy !== sortTableBy) {
          currentPageNo = 0;
        } else {
          currentPageNo = pageIndex;
        }
        setSortTableBy(sortBy);
        setPageNo(currentPageNo);
        setRowsPerPage(pageSize);
        const sortReq = sortBy?.map((item: any) => {
          return { field: item.id, order: item.desc ? -1 : 1 };
        });

        setParam((prevState) => ({
          ...prevState,
          offset: currentPageNo * pageSize,
          limit: pageSize,
          sort_field: sortReq[0].field,
          sort_by: sortReq[0].order,
        }));
      } else {
        if (
          pageIndex !== pageNo ||
          pageSize !== rowsPerPage ||
          sortBy !== sortTableBy
        ) {
          setSortTableBy(sortBy);
          setPageNo(pageIndex);
          setRowsPerPage(pageSize);
          setParam((prevState) => ({
            ...prevState,
            offset: pageIndex * pageSize,
            limit: pageSize,
          }));
        }
      }
    },
    [sortTableBy, pageNo, rowsPerPage]
  );

  return (
    <>
      {/** restricting view on small devices */}
      <div className="block lg:hidden w-full">
        <div className="flex flex-col justify-center items-center h-screen">
          <img src={desktopImage} alt="desktop-image" />
          <div className="mt-5 text-center">
            <h1 className="font-bold text-3xl"> View on desktop</h1>
            <p className="font-normal text-lg mt-3 m-auto max-w-[65%]">
              This page is not available on mobile. Please visit the desktop
              site to access this page.
            </p>
          </div>
        </div>
      </div>
      {/** table display on big screens */}
      <div className="hidden lg:block w-full block p-10 mt-5 h-screen">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <div className="flex flex-row xl:mt-0 mt-8">
              <ClickableButton
                onClick={() => navigate(`/workflows/ucd`)}
                buttonProps={{
                  color: "transparent",
                  size: "small",
                  dynamicClassProperty: "rounded-2xl hover:bg-grey !py-1",
                }}
              >
                <ArrowLeftIcon className="w-6 h-6" />
              </ClickableButton>

              <p className="font-bold text-2xl pl-3">{header_name}</p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="flex items-end justify-end mb-5 mt-8 xl:mt-0">
              <div className="flex flex-row relative">
                <input
                  type="text"
                  value={searchFilterText}
                  className="px-4 w-80 rounded-full border border-black py-4 focus:ring-0 focus:border-grey focus:outline-none"
                  placeholder="Search"
                  onChange={handleSearch}
                />
                <span className="flex items-center justify-center absolute right-8 top-5">
                  <MagnifyingGlassIcon className="w-6 h-6" />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/** Filter tags and export CSV button */}
        <div className="grid grid-cols-2">
          <div className="col-span-2">
            <div className="flex flex-row mb-3">
              {tagName.map((item: any, index: any) => (
                <div key={index} className="bg-sidebar p-3 rounded-md mr-2">
                  <span key={index}>{item}</span>
                </div>
              ))}
            </div>
          </div>
          {adminUser === "true" && (
            <div className="col-span-1">
              <div className="flex justify-end items-end mb-3">
                {tableData && (
                  <Button
                    size="small"
                    color="black"
                    role="button"
                    isRounded={true}
                    dynamicClassProperty="!py-3"
                  >
                    <CSVLink data={tableData} filename="export">
                      <span className="flex flex-row items-center justify-center font-bold">
                        <img src={csvIcon} className="w-6 h-6 mr-2" />
                        Export data
                      </span>
                    </CSVLink>
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>

        {tableData !== undefined ? (
          <Table
            columnDef={columns}
            dataArray={tableData}
            isLoading={loading}
            pageCount={pageCount}
            currentPageNo={pageNo}
            fetchData={handleFetchData}
            perPage={rowsPerPage as any}
            sort={sortTableBy}
            handleSearchTerm={setSearchFilterText}
            debounceTime={275}
          />
        ) : (
          <LandingPageLoader showLoader={true} />
        )}
      </div>
    </>
  );
};

export default DrillDownTable;
