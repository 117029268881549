import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

// components
import Icon from "../../Icon/Icon.component";
import Toast from "../../Toast/Toast.component";
import LoadingComponent from "../../Loading/LoadingComponent";
import { Button, ClickableButton } from "@autometry/ui-components";

// hooks
import useResetPasswordMutation from "../../hooks/useResetPasswordMutation";

const schema = yup.object().shape({
  resetEmail: yup.string().email().required(),
});

interface PropsDefinition {
  setCurrentStage: (stage: number) => void;
}

const PasswordResetForm = (props: PropsDefinition) => {
  const [isError, setIsError] = useState(false);
  const [displayToast, setDisplayToast] = useState(false);
  const [isRounded, setIsRounded] = useState(true);

  const resetPasswordMutation = useResetPasswordMutation(
    setIsError,
    setDisplayToast
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  function formSubmit(data: any) {
    resetPasswordMutation.mutate({
      email: data.resetEmail,
    });
  }

  if (resetPasswordMutation.isSuccess)
    return (
      <div
        className="flex h-screen w-full justify-center items-center w-full flex-col"
        aria-label="submit-form"
      >
        <h2 className="text-black font-bold text-3xl pb-8 text-center">
          Password reset
        </h2>
        <div className="text-black text-center pb-8">
          We&apos;ve sent you an email. Just follow the instructions
          <br /> to reset your password.
        </div>
      </div>
    );

  if (resetPasswordMutation.isLoading) {
    return <LoadingComponent showLoader={true} />;
  }

  return (
    <div
      className="flex h-screen w-full justify-center items-center w-full flex-col"
      aria-label="reset-password-form"
    >
      {displayToast ? (
        <Toast
          displayToast={displayToast}
          text={"We did not recognize this email address"}
        />
      ) : null}
      <ClickableButton
        onClick={() => props.setCurrentStage(1)}
        data-testid="btn-go-back"
        buttonProps={{
          color: "transparent",
          dynamicClassProperty: "flex items-center text-black cursor-pointer p-4 font-medium h-7 hover:opacity-60 absolute lg:top-5 lg:left-1/3 text-sm left-0 top-24 border-0",
        }}
      >
        <Icon id="arrow-left" className="h-5 w-5 mr-3 transform" />
        <span className="text-lg">Back</span>
      </ClickableButton>

      <h2 className="text-black font-bold text-3xl pb-8 text-center">
        Password reset
      </h2>
      <div className="w-1/2 lg:w-1/2 xl:w-1/3 2xl:w-1/4 block">
        <div className="text-black text-center pb-8">
          Enter your <span className="font-bold">Autometry</span> email address
          that you used to register. We&apos;ll send you a link to reset your
          password.
        </div>
        <form onSubmit={handleSubmit(formSubmit)}>
          {/** Email form field */}
          <div className="mb-8">
            <label
              htmlFor="resetEmail"
              className={`block font-normal text-sm mb-2 text-left ${
                errors.resetEmail ? "text-red-400" : "text-black"
              }`}
            >
              {errors.resetEmail ? "Error - Email address" : "Email address"}
            </label>
            <input
              type="text"
              {...register("resetEmail")}
              id="resetEmail"
              role="textbox"
              className={`block w-full bg-transparent border-b py-2 px-4 rounded placeholder-grey focus:border-black focus:outline-none focus:ring-transparent ${
                errors.resetEmail
                  ? "text-red-300 border-red-400 focus:border-red-400"
                  : "text-black border-lightgrey"
              }`}
            />
          </div>
          <div className="mb-8 flex justify-center">
            <Button
              children="Send"
              size="large"
              color="black"
              role="button"
              isRounded={isRounded}
              dynamicClassProperty="w-full"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetForm;
