import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { useDispatch, useSelector } from "react-redux";
import { subDays } from "date-fns";
import { jobData } from "./mockData/data";

import {
  recruiterDetails,
  workflowInformation,
  jobDetails
} from "../../services/workflowsDetails";

// components
import Workflow from "./WorkflowInfo.component";
import LoadingComponent from "../../Loading/LoadingComponent";
import DataHealth from "./DataHealth.component";

//reducers
import {
  setJobMetricsRangeSelection,
  setRevenueMetricsRangeSelection,
} from "../../../reducers/date-selection.reducer";

import {
  setJobMetricsRange,
  setRevenueMetricsRange,
} from "../../../reducers/common-reducer";

//helpers
import {
  computedEndDate,
  computedStartDate,
} from "../../../helpers/date.helpers";

import RevenueMetrics from "./RevenueMetrics/RevenueMetrics.component";
import JobMetrics from "./JobMetrics/JobMetrics.component";
import RecruiterPanel from "./RevenueMetrics/RecruiterPanel";
import RecruiterPanelMobile from "./RevenueMetrics/RecruiterPanelMobile";
import WorkflowPerformance from "./JobMetrics/WorkflowPerformanceTable";
import WorkflowPerformanceMobile from "./JobMetrics/WorkflowPerformanceTableMobile";

const JobCoverageComponent = () => {
  //stored state
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);
  const [workflowInfoData, setWorkflowInfoData] = useState();
  const [jobPanelData, setJobPanelData] = useState(jobData) as any;
  const [recruiterPanelData, setRecruiterPanelData] = useState() as any;

  const jobMetricsDateRange = useSelector(
    (state: any) => state.rangeSelection.jobMetricsRange
  );
  const revenueMetricsDateRange = useSelector(
    (state: any) => state.rangeSelection.revenueMetricsRange
  );

  // Metrics Custom Date Range
  const jobMetricsRange = useSelector(
    (state: any) => state.selection.jobMetrics
  );
  const revenueMetricsRange = useSelector(
    (state: any) => state.selection.revenueMetrics
  );

  const [jobMetricsSelectedRange, setJobMetricsSelectedRange] =
    useState<DateRange>({
      from: subDays(new Date(), 30),
      to: new Date(),
    });

  const [revenueMetricsSelectedRange, setRevenueMetricsSelectedRange] =
    useState<DateRange>({
      from: subDays(new Date(), 30),
      to: new Date(),
    });

  const jobSelection = useSelector(
    (state: any) => state.metricsSelection.jobMetricSelection
  );

  const revenueMetricSelection = useSelector(
    (state: any) => state.metricsSelection.revenueMetricSelection
  );

  useEffect(() => {
    workflowInformation("ucd")
      .then((data) => {
        if (data) {
          setWorkflowInfoData(data);
          setLoading(false);
        } else {
          console.log("no data found");
        }
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  }, []);

  useEffect(() => {
    if (jobMetricsRange) setLoading(true);
  }, [jobMetricsRange]);

  useEffect(() => {
    if (revenueMetricsRange) setLoading(true);
  }, [revenueMetricsRange]);

  useEffect(() => {
    let fromDate = computedStartDate(jobMetricsDateRange);
    let toDate = computedEndDate(jobMetricsDateRange);
    
    if (jobMetricsSelectedRange.from !== undefined && jobMetricsSelectedRange.to !== undefined) {
      jobDetails("ucd", fromDate, toDate)
        .then((data) => {
          if (data) {
            setJobPanelData(jobData);
            setLoading(false);
          } else {
            console.log("no data found");
          }
        })
        .catch(function (error) {
          console.log("error : ", error);
        });
    }
  }, [jobMetricsSelectedRange]);

  useEffect(() => {
    let fromDate = computedStartDate(revenueMetricsDateRange);
    let toDate = computedEndDate(revenueMetricsDateRange);

    if (revenueMetricsSelectedRange.from !== undefined && revenueMetricsSelectedRange.to !== undefined) {
      recruiterDetails("ucd", fromDate, toDate)
        .then((data) => {
          if (data) {
            setRecruiterPanelData(data);
            setLoading(false);
          } else {
            console.log("no data found");
          }
        })
        .catch(function (error) {
          console.log("error : ", error);
        });
    }
  }, [revenueMetricsSelectedRange]);

  useEffect(() => {
    if (jobSelection === "Last 30 days") {
      setLoading(true);
      dispatch(setJobMetricsRange(false));
      setJobMetricsSelectedRange({
        from: subDays(new Date(), 30),
        to: new Date(),
      });
      dispatch(
        setJobMetricsRangeSelection({
          from: subDays(new Date(), 30),
          to: new Date(),
        })
      );
    } else if (jobSelection === "Custom") {
      setLoading(false);
      setJobMetricsSelectedRange({ from: undefined, to: undefined });
      dispatch(setJobMetricsRangeSelection(jobMetricsDateRange));
    } else {
      setLoading(true);
      dispatch(setJobMetricsRange(false));
      setJobMetricsSelectedRange({
        from: new Date("01-01-2000"),
        to: new Date(),
      });
      dispatch(
        setJobMetricsRangeSelection({
          from: new Date("01-01-2000"),
          to: new Date(),
        })
      );
    }
  }, [jobSelection]);

  useEffect(() => {
    if (revenueMetricSelection === "Last 30 days") {
      setLoading(true);
      dispatch(setRevenueMetricsRange(false));
      setRevenueMetricsSelectedRange({
        from: subDays(new Date(), 30),
        to: new Date(),
      });
      dispatch(
        setRevenueMetricsRangeSelection({
          from: subDays(new Date(), 30),
          to: new Date(),
        })
      );
    } else if (revenueMetricSelection === "Custom") {
      setLoading(false);
      setRevenueMetricsSelectedRange({ from: undefined, to: undefined });
      dispatch(setRevenueMetricsRangeSelection(revenueMetricsDateRange));
    } else {
      setLoading(true);
      dispatch(setRevenueMetricsRange(false));
      setRevenueMetricsSelectedRange({
        from: new Date("01-01-2000"),
        to: new Date(),
      });
      dispatch(
        setRevenueMetricsRangeSelection({
          from: new Date("01-01-2000"),
          to: new Date(),
        })
      );
    }
  }, [revenueMetricSelection]);

  if (isLoading) {
    return <LoadingComponent showLoader={true} />;
  }

  return (
    <div
    className="flex flex-1 flex-col p-10 overflow-y-auto"
    aria-label="job-coverage-panel"
  >
    {workflowInfoData && (
        <div className="grid grid-cols-2 xl:gap-5 gap-10">
          <div className="lg:col-span-1 col-span-2 px-4">
            <Workflow workflowInfoData={workflowInfoData} />
          </div>
          <div className="lg:col-span-1 col-span-2">
            <DataHealth workflowInfoData={workflowInfoData} />
          </div>
        </div>
      )}
       <h2
        className="text-2xl text-black font-bold my-8"
        aria-label="summary-heading"
      >
        Summary
      </h2>
      <div className="grid grid-cols-2 gap-5">
        {jobPanelData && (
          <div className="lg:col-span-1 col-span-2">
            <JobMetrics
              setSelectedRange={setJobMetricsSelectedRange as any}
              selectedRange={jobMetricsSelectedRange}
              jobPanelData={jobPanelData}
            />
          </div>
        )}
        <div className="lg:col-span-1 col-span-2">
          {recruiterPanelData && (
            <RevenueMetrics
              setSelectedRange={setRevenueMetricsSelectedRange as any}
              selectedRange={revenueMetricsSelectedRange}
              recruiterPanelData={recruiterPanelData}
            />
          )}
        </div>
      </div>
      {jobPanelData && (
        <div className="hidden lg:block">
          <WorkflowPerformance jobPanelData={jobPanelData} />
        </div>
      )}

      {jobPanelData && (
        <div className="visible lg:hidden flex justify-center">
          <WorkflowPerformanceMobile
            jobPanelData={jobPanelData}
          />
        </div>
      )}
      
      {recruiterPanelData && (
        <div className="hidden lg:block">
          <RecruiterPanel recruiterPanelData={recruiterPanelData} />
        </div>
      )}
      {recruiterPanelData && (
        <div className="visible lg:hidden flex justify-center">
          <RecruiterPanelMobile recruiterPanelData={recruiterPanelData} />
        </div>
      )}
    </div>
  );
};

export default JobCoverageComponent;
