import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//reducers
import {
  setParameterName,
  setQueryparameter,
  setHeaderName,
  setFilterTags,
} from "../../../../reducers/common-reducer";

// image
import emailImage from "../../../../assets/email.svg";
import { useState } from "react";

interface EmailSubSectionPropsDefinition {
  workflowData: any;
  fromDate: string | undefined;
  toDate: string | undefined;
}

const EmailSubSection: React.FC<EmailSubSectionPropsDefinition> = ({
  workflowData,
  fromDate,
  toDate,
}) => {
  const navigate = useNavigate();

  //stored state
  const dispatch = useDispatch();
  const [tags, setTags] = useState([] as any);

  const metricSelection = useSelector(
    (state: any) => state.metricsSelection.activityMetricSelection
  );

  const handleAnchorClick = (
    event: any,
    name: any,
    header: string,
    tagName: string,
    query: any
  ) => {
    tags.push(tagName, metricSelection);
    event.preventDefault();
    dispatch(setParameterName(name));
    dispatch(setQueryparameter(query));
    dispatch(setHeaderName(header));
    dispatch(setFilterTags(tags));
    navigate(`/workflows/ucd/candidate-drilldown`);
  };

  return (
    <div aria-label="email-sub-section">
      {/** First Email section */}
      <div className="block py-4 px-6 bg-white border-b" aria-label="first-email-section">
        <div className="grid grid-cols-7 gap-3">
          <div className="col-span-2 md:col-span-2 flex py-2 px-6 flex-col justify-center">
            <h2 className="font-medium text-sm">First email</h2>
          </div>
          <div className="col-span-1">
            <h2 className="font-bold flex justify-start items-start">
              <img
                src={emailImage}
                alt="Email Logo"
                className="w-8 mt-1 2xl:w-9"
              />
            </h2>
          </div>
          <div className="col-span-1">
            <div className="col-span-1 pr-3 flex flex-col justify-start items-start">
              {workflowData.email.first_email.sent > 0 ? (
                <a
                  href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND __filled__:workflow_statistics.ucd.first_email.was_sent`}
                  className="text-blue-500 font-medium text-sm cursor-pointer"
                  onClick={(event) =>
                    handleAnchorClick(
                      event,
                      "name",
                      "Candidates sent the first email",
                      workflowData.email.first_email.sent > 1
                        ? `${workflowData.email.first_email.sent} candidates`
                        : `${workflowData.email.first_email.sent} candidate`,
                      `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AAND __filled__:workflow_statistics.ucd.first_email.was_sent`
                    )
                  }
                >
                  {workflowData.email.first_email.sent}
                </a>
              ) : (
                <p className="font-medium text-sm">{workflowData.email.first_email.sent}</p>
              )}

              <p className="text-xs font-medium">{workflowData.email.first_email.sent === 0 || workflowData.email.first_email.sent > 1 ? "Candidates" : "Candidate"}</p>
            </div>
          </div>
          <div className="col-span-4 md:col-span-3">
            <div className="grid grid-cols-3">
              <div className="col-span-1 flex flex-col justify-start items-start">
                {workflowData.email.first_email.clicks > 0 ? (
                  <a
                    href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_clicked OR __filled__:workflow_statistics.ucd.first_email.has_confirmed)`}
                    className="text-blue-500 font-medium text-sm cursor-pointer"
                    onClick={(event) =>
                      handleAnchorClick(
                        event,
                        "name",
                        "Candidates engaged with first email",
                        workflowData.email.first_email.clicks > 1
                          ? `${workflowData.email.first_email.clicks} candidates`
                          : `${workflowData.email.first_email.clicks} candidate`,
                        `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_clicked OR __filled__:workflow_statistics.ucd.first_email.has_confirmed)`
                      )
                    }
                  >
                    {workflowData.email.first_email.clicks}
                  </a>
                ) : (
                  <p className="font-medium text-sm">{workflowData.email.first_email.clicks}</p>
                )}

                <p className="text-xs font-medium">{workflowData.email.first_email.clicks === 0 || workflowData.email.first_email.clicks > 1 ? "Clicks" : "Click"}</p>
              </div>
              <div className="col-span-1 flex flex-col justify-start items-start">
                {workflowData.email.first_email.update_details > 0 ? (
                  <a
                    href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}  AND (__filled__:workflow_statistics.ucd.first_email.has_updated OR __filled__:workflow_statistics.ucd.first_email.has_confirmed)`}
                    className="text-blue-500 font-medium text-sm cursor-pointer"
                    onClick={(event) =>
                      handleAnchorClick(
                        event,
                        "name",
                        "Candidates updated via first email",
                        workflowData.email.first_email.update_details > 1
                          ? `${workflowData.email.first_email.update_details} candidates`
                          : `${workflowData.email.first_email.update_details} candidate`,
                        `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}  AND (__filled__:workflow_statistics.ucd.first_email.has_updated OR __filled__:workflow_statistics.ucd.first_email.has_confirmed)`
                      )
                    }
                  >
                    {workflowData.email.first_email.update_details}
                  </a>
                ) : (
                  <p className="font-medium text-sm">{workflowData.email.first_email.update_details}</p>
                )}

                <p className="text-xs font-medium text-left">
                  Update details
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Second email section */}
      <div className="block py-4 px-6 bg-white border-b" aria-label="second-email-section">
        <div className="grid grid-cols-7 gap-3">
          <div className="col-span-2 md:col-span-2 flex py-2 px-6 flex-col justify-center">
            <h2 className="font-medium text-sm">Second email</h2>
          </div>
          <div className="col-span-1">
            <h2 className="font-bold flex justify-start items-start">
              <img
                src={emailImage}
                alt="Email Logo"
                className="w-8 mt-1 2xl:w-9"
              />
            </h2>
          </div>
          <div className="col-span-1">
            <div className="col-span-1 pr-3 flex flex-col justify-start items-start">
              {workflowData.email.second_email.sent ? (
                <a
                  href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND __filled__:workflow_statistics.ucd.second_email.was_sent`}
                  className="text-blue-500 font-medium text-sm cursor-pointer"
                  onClick={(event) =>
                    handleAnchorClick(
                      event,
                      "name",
                      "Candidates sent the second email",
                      workflowData.email.second_email.sent > 1
                        ? `${workflowData.email.second_email.sent} candidates`
                        : `${workflowData.email.second_email.sent} candidate`,
                      `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND __filled__:workflow_statistics.ucd.second_email.was_sent`
                    )
                  }
                >
                  {workflowData.email.second_email.sent}
                </a>
              ) : (
                <p className="font-medium text-sm">{workflowData.email.second_email.sent}</p>
              )}

              <p className="text-xs font-medium">{workflowData.email.second_email.sent === 0 || workflowData.email.second_email.sent > 1 ? "Candidates" : "Candidate"}</p>
            </div>
          </div>
          <div className="col-span-4 md:col-span-3">
            <div className="grid grid-cols-3">
              <div className="col-span-1 flex flex-col justify-start items-start">
                {workflowData.email.second_email.clicks ? (
                  <a
                    href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.second_email.has_clicked OR __filled__:workflow_statistics.ucd.second_email.has_confirmed)`}
                    className="text-blue-500 font-medium text-sm cursor-pointer"
                    onClick={(event) =>
                      handleAnchorClick(
                        event,
                        "name",
                        "Candidates engaged with second email",
                        workflowData.email.second_email.clicks > 1
                          ? `${workflowData.email.second_email.clicks} candidates`
                          : `${workflowData.email.second_email.clicks} candidate`,
                        `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.second_email.has_clicked OR __filled__:workflow_statistics.ucd.second_email.has_confirmed)`
                      )
                    }
                  >
                    {workflowData.email.second_email.clicks}
                  </a>
                ) : (
                  <p className="font-medium text-sm">{workflowData.email.second_email.clicks}</p>
                )}

                <p className="text-xs font-medium">{workflowData.email.second_email.clicks === 0 || workflowData.email.second_email.clicks > 1 ? "Clicks": "Click"}</p>
              </div>
              <div className="col-span-1 flex flex-col justify-start items-start">
                {workflowData.email.second_email.update_details ? (
                  <a
                    href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.second_email.has_updated OR __filled__:workflow_statistics.ucd.second_email.has_confirmed)`}
                    className="text-blue-500 font-medium text-sm cursor-pointer"
                    onClick={(event) =>
                      handleAnchorClick(
                        event,
                        "name",
                        "Candidates updated via second email",
                        workflowData.email.second_email.update_details > 1
                          ? `${workflowData.email.second_email.update_details} candidates`
                          : `${workflowData.email.second_email.update_details} candidate`,
                        `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.second_email.has_updated OR __filled__:workflow_statistics.ucd.second_email.has_confirmed)`
                      )
                    }
                  >
                    {workflowData.email.second_email.update_details}
                  </a>
                ) : (
                  <p className="font-medium text-sm">{workflowData.email.second_email.update_details}</p>
                )}

                <p className="text-xs font-medium text-left">
                  Update details
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Third email section */}
      <div className="block py-4 px-6 bg-white border-b" aria-label="third-email-section">
        <div className="grid grid-cols-7 gap-3">
          <div className="col-span-2 md:col-span-2 flex py-2 px-6 flex-col justify-center">
            <h2 className="font-medium text-sm">Third email</h2>
          </div>
          <div className="col-span-1">
            <h2 className="font-bold flex justify-start items-start">
              <img
                src={emailImage}
                alt="Email Logo"
                className="w-8 mt-1 2xl:w-9"
              />
            </h2>
          </div>
          <div className="col-span-1">
            <div className="col-span-1 pr-3 flex flex-col justify-start items-start">
              {workflowData.email.third_email.sent ? (
                <a
                  href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND __filled__:workflow_statistics.ucd.third_email.was_sent`}
                  className="text-blue-500 font-medium text-sm cursor-pointer"
                  onClick={(event) =>
                    handleAnchorClick(
                      event,
                      "name",
                      "Candidates sent the third email",
                      workflowData.email.third_email.sent > 1
                        ? `${workflowData.email.third_email.sent} candidates`
                        : `${workflowData.email.third_email.sent} candidate`,
                      `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND __filled__:workflow_statistics.ucd.third_email.was_sent`
                    )
                  }
                >
                  {workflowData.email.third_email.sent}
                </a>
              ) : (
                <p className="font-medium text-sm">{workflowData.email.third_email.sent}</p>
              )}
              <p className="text-xs font-medium">{workflowData.email.third_email.sent === 0 || workflowData.email.third_email.sent > 1 ? "Candidates" : "Candidate"}</p>
            </div>
          </div>
          <div className="col-span-4 md:col-span-3">
            <div className="grid grid-cols-3">
              <div className="col-span-1 flex flex-col justify-start items-start">
                {workflowData.email.third_email.clicks > 0 ? (
                  <a
                    href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.third_email.has_clicked OR __filled__:workflow_statistics.ucd.third_email.has_confirmed)`}
                    className="text-blue-500 font-medium text-sm cursor-pointer"
                    onClick={(event) =>
                      handleAnchorClick(
                        event,
                        "name",
                        "Candidates engaged with third email",
                        workflowData.email.third_email.clicks > 1
                          ? `${workflowData.email.third_email.clicks} candidates`
                          : `${workflowData.email.third_email.clicks} candidate`,
                        `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.third_email.has_clicked OR __filled__:workflow_statistics.ucd.third_email.has_confirmed)`
                      )
                    }
                  >
                    {workflowData.email.third_email.clicks}
                  </a>
                ) : (
                  <p className="font-medium text-sm">{workflowData.email.third_email.clicks}</p>
                )}

                <p className="text-xs font-medium">{workflowData.email.third_email.clicks === 0 || workflowData.email.third_email.clicks > 1 ? "Clicks" : "Click"}</p>
              </div>
              <div className="col-span-1 flex flex-col justify-start items-start">
                {workflowData.email.third_email.update_details > 0 ? (
                  <a
                    href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.third_email.has_updated OR __filled__:workflow_statistics.ucd.third_email.has_confirmed)`}
                    className="text-blue-500 font-medium text-sm cursor-pointer"
                    onClick={(event) =>
                      handleAnchorClick(
                        event,
                        "name",
                        "Candidates updated via third email",
                        workflowData.email.third_email.update_details > 1
                          ? `${workflowData.email.third_email.update_details} candidates`
                          : `${workflowData.email.third_email.update_details} candidate`,
                        `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.third_email.has_updated OR __filled__:workflow_statistics.ucd.third_email.has_confirmed)`
                      )
                    }
                  >
                    {workflowData.email.third_email.update_details}
                  </a>
                ) : (
                  <p className="font-medium text-sm">{workflowData.email.third_email.update_details}</p>
                )}
                <p className="text-xs font-medium text-left">
                  Update details
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSubSection;
