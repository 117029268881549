import * as React from "react";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";

interface ChartContentPropsDefinition {
  livingDatabase: any;
  excluded: any;
  uncontactable: any;
}

const ChartContent: React.FC<ChartContentPropsDefinition> = ({
  livingDatabase,
  excluded,
  uncontactable,
}) => {
    const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      renderTo: "container",
      marginTop: -100,
      marginLeft: 120,
    },
    colors: ["#1A44DA", "#279AF1", "#CECECE"],
    title: {
      text: undefined,
    },
    accessibility: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: "pie",
        size: 50,
        innerSize: 40,
      },
      pie: {
        size: 80,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
      },
    },
    responsive: {
      // setting dynamic height based on media screen
      rules: [
        {
          condition: {
            maxWidth: 200,
          },
          // Make the labels less space demanding on mobile
          chartOptions: {
            chart: {
              height: 200,
            },
          },
        },
        {
          condition: {
            minWidth: 200,
          },
          chartOptions: {
            chart: {
              height: 200,
            },
          },
        },
      ],
    },
    series: [
      {
        name: "Total",
        innerSize: "80%",
        groupPadding: 0,
        pointPadding: 0,
        data: [
          {
            name: "Living Database",
            y: livingDatabase,
          },
          {
            name: "Excluded from flow",
            y: excluded,
          },
          {
            name: "Uncontactable",
            y: uncontactable,
          },
        ],
      },
    ],
  });

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default ChartContent;
