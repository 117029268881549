import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from 'lodash.debounce';

//reducers
import { setSelectedRevenueMetrics } from "../../../../reducers/metrics-reducer";

const RevenueMetricsRadioButton = () => {
  //stored state
  const dispatch = useDispatch();
  const revenueMetricSelection = useSelector(
    (state: any) => state.metricsSelection.revenueMetricSelection
  );
  const [selectedMetric, setSelectedMetric] = useState(revenueMetricSelection);

  const onOptionChange = (e: any) => {
    setSelectedMetric(e.target.value)
    dispatch(setSelectedRevenueMetrics(e.target.value));
  }

  const debouncedChangeHandler = useMemo(
    () => debounce(onOptionChange, 1000)
  , []);
  
  return (
    <div className="revenue-metric" aria-label="revenue-metric">
      {/** Radio button selection */}
      <div className="flex flex-col md:flex-row -mt-2">
        <div className="flex mr-2 justify-start items-start">
          <input
            type="radio"
            id="Last 30 days"
            value="Last 30 days"
            className="form-radio h-5 w-5 mb-2 md:mb-0 checked:bg-black checked:text-black mt-0.5 checked:ring-black focus:ring-black ring-black"
            checked={selectedMetric === "Last 30 days"}
            onChange={debouncedChangeHandler}
            aria-label="revenue-metrics-last-days-input"
          />
          <label
            htmlFor="Last 30 days"
            className="ml-2 mb-2 md:mb-0 text-base 2xl:text-base font-medium text-gray-900"
            aria-label="revenue-metrics-last-days"
          >
            Last 30 days
          </label>
        </div>
        <div className="flex mr-2 justify-start items-start">
          <input
            type="radio"
            id="All time"
            value="All time"
            className="form-radio h-5 w-5 mb-2 md:mb-0 checked:bg-black checked:text-black mt-0.5 checked:ring-black focus:ring-black ring-black"
            checked={selectedMetric === "All time"}
            onChange={debouncedChangeHandler}
            aria-label="revenue-metrics-all-time-input"
          />
          <label
            htmlFor="All time"
            className="ml-2 mb-2 md:mb-0 text-base 2xl:text-base font-medium text-gray-900"
            aria-label="revenue-metrics-all-time"
          >
            All time
          </label>
        </div>
        <div className="flex mr-2 justify-start items-start">
          <input
            type="radio"
            id="Custom"
            value="Custom"
            className="form-radio h-5 w-5 mb-2 md:mb-0 checked:bg-black checked:text-black mt-0.5 checked:ring-black focus:ring-black ring-black"
            checked={selectedMetric === "Custom"}
            onChange={debouncedChangeHandler}
            aria-label="revenue-metrics-custom-input"
          />
          <label
            htmlFor="Custom"
            className="ml-2 mb-2 md:mb-0 text-base 2xl:text-base font-medium text-gray-900"
            aria-label="revenue-metrics-custom"
          >
            Custom
          </label>
        </div>
      </div>
    </div>
  );
};

export default RevenueMetricsRadioButton;
