import React, { useState, useMemo, useEffect } from "react";
import { ClickableButton } from "@autometry/ui-components/";
import { CandidateInfo } from "./hooks/useCandidateProfile";
import { useSelector } from "react-redux";
import { format, isValid, parseISO } from "date-fns";

import actionIcon from "../assets/action-icon.svg";
import DrillDownTable from "./common/DrillDownTable";

const storageCol = [
  {
    Header: "Candidate Name",
    accessor: "name",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.name && (
            <div className="text-sm">{props.cell.row.original.name}</div>
          )}
        </>
      );
    },
  },
  {
    Header: "Email",
    accessor: "email",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.email && (
            <div className="text-sm">{props.cell.row.original.email}</div>
          )}
        </>
      );
    },
  },
  {
    Header: "Job Title",
    accessor: "job_title",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.job_title && (
            <div className="text-sm">{props.cell.row.original.job_title}</div>
          )}
        </>
      );
    },
  },
  {
    Header: "Current Employer",
    accessor: "current_employer",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.current_employer && (
            <div className="text-sm">
              {props.cell.row.original.current_employer}
            </div>
          )}
        </>
      );
    },
  },
  {
    Header: "Job Type ",
    accessor: "job_type",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.job_type && (
            <div className="text-sm">{props.cell.row.original.job_type}</div>
          )}
        </>
      );
    },
  },
  {
    Header: "Highest Stage",
    accessor: "hhighest_stage",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      if (props.cell.row.original.highest_stage === "Available") {
        return (
          <div className="flex justify-center items-center rounded-md bg-success text-successText p-2 font-semibold">
            {props.cell.row.original.highest_stage}
          </div>
        );
      } else if (props.cell.row.original.highest_stage === "Sent") {
        return (
          <div className="flex justify-center items-center rounded-md bg-info text-infoText p-2 font-semibold">
            {props.cell.row.original.highest_stage}
          </div>
        );
      } else {
        return (
          <div className="flex justify-center items-center rounded-md bg-default text-defaultText p-2 font-semibold">
            {props.cell.row.original.highest_stage}
          </div>
        );
      }
    },
  },
  {
    Header: "Prior Activity",
    accessor: "prior_activity_date",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      if (
        props.cell.row.original.prior_activity_date.length === 0 ||
        props.cell.row.original.prior_activity_date === ""
      ) {
        return null;
      } else {
        let date = format(
          parseISO(props.cell.row.original.prior_activity_date),
          "dd/MM/y"
        );
        let time = format(
          new Date(parseISO(props.cell.row.original.prior_activity_date)),
          "HH:mm a"
        );
        return (
          <div className="flex flex-col text-sm">
            <span>{date as any}</span>
            <span className="text-darkGrey">{time as any}</span>
          </div>
        );
      }
    },
  },
  {
    Header: "Contacted",
    accessor: "contacted_date",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      if (props.cell.row.original.contacted_date === "") {
        return null;
      } else {
        let date = format(
          parseISO(props.cell.row.original.contacted_date),
          "dd/MM/y"
        );
        let time = format(
          new Date(parseISO(props.cell.row.original.contacted_date)),
          "HH:mm a"
        );
        return (
          <div className="flex flex-col text-sm">
            <span>{date as any}</span>
            <span className="text-darkGrey">{time as any}</span>
          </div>
        );
      }
    },
  },
  {
    Header: "Engaged",
    accessor: "engaged_date",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      if (props.cell.row.original.engaged_date === "") {
        return null;
      } else {
        let date = format(
          parseISO(props.cell.row.original.engaged_date),
          "dd/MM/y"
        );
        let time = format(
          new Date(parseISO(props.cell.row.original.engaged_date)),
          "HH:mm a"
        );
        return (
          <div className="flex flex-col text-sm">
            <span>{date as any}</span>
            <span className="text-darkGrey">{time as any}</span>
          </div>
        );
      }
    },
  },
  {
    Header: "Updated",
    accessor: "updated_date",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      if (props.cell.row.original.updated_date === "") {
        return null;
      } else {
        let date = format(
          parseISO(props.cell.row.original.updated_date),
          "dd/MM/y"
        );
        let time = format(
          new Date(parseISO(props.cell.row.original.updated_date)),
          "HH:mm a"
        );
        return (
          <div className="flex flex-col text-sm">
            <span>{date as any}</span>
            <span className="text-darkGrey">{time as any}</span>
          </div>
        );
      }
    },
  },
  {
    Header: "Candidate Owner",
    accessor: "candidate_owner",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.candidate_owner && (
            <div className="text-sm">
              {props.cell.row.original.candidate_owner}
            </div>
          )}
        </>
      );
    },
  },
  {
    Header: "Action",
    accessor: "ats_link",
    width: "10%",
    Cell: (row: any) => {
      return (
        <>
          {row.value && (
            <div className="group relative">
              <ClickableButton
                onClick={() => window.open(row.value, "_blank")}
                buttonProps={{
                  color: "transparent",
                  size: "small",
                  dynamicClassProperty:
                    "rounded-2xl border-successText hover:bg-success !py-2",
                }}
              >
                <img src={actionIcon} alt="action-icon" />
              </ClickableButton>

              <span className="!absolute -top-6 -left-32 scale-0 danger-arrow-right bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                View Candidate
              </span>
            </div>
          )}
        </>
      );
    },
  },
];

interface CandidateDrillDownTableProps {}

const CandidateDrillDownTable: React.FC<CandidateDrillDownTableProps> = () => {
  let entity_name = useSelector((state: any) => state.selection.parameterName);
  let query_param = useSelector((state: any) => state.selection.queryParameter);
  const storageColumns = useMemo(() => storageCol, [storageCol]);

  const [param, setParam] = useState({
    q: query_param,
    offset: 0,
    limit: 50,
    sort_field: entity_name,
    sort_by: 1,
  });

  const {
    data: candidateData,
    isFetching: candidatesLoading,
    isError: candidateDataError,
  } = CandidateInfo(param);

  const [tableData, setTableData] = useState([] as any);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageNo, setPageNo] = useState<number>(0);
  const [searchFilterText, setSearchFilterText] = useState<string>("" as any);

  useEffect(() => {
    if (candidateData !== undefined) {
      if (candidateData.total >= 0)
        setPageCount(Math.ceil(candidateData.total / rowsPerPage));
      if (candidateData.total === 0) {
        setPageCount(1);
        setPageNo(0);
      }
    }
  }, [candidateData, candidatesLoading, rowsPerPage]);

  useEffect(() => {
    if (!candidatesLoading && candidateData) {
      setTableData(candidateData.data);
    }
  }, [candidateData, candidatesLoading]);

  useEffect(() => {
    if (candidateDataError) {
      setTableData([]);
    }
  }, [candidateDataError]);
  
  useEffect(() => {
    let updatedQuery = `${query_param} AND ${searchFilterText}`;
    if(searchFilterText !== "") {
      const handler = setTimeout(() => {
        setParam((prevState) => ({
          ...prevState,
          q: updatedQuery
        }));
      }, 2000);
  
      return () => {
        clearTimeout(handler);
      };
    } else {
      setParam((prevState) => ({
        ...prevState,
        q: query_param
      }));
    }
  }, [searchFilterText]);

  return (
    <div aria-label="candidate-drilldown-table" className="w-full">
      <DrillDownTable
        tableData={tableData}
        loading={candidatesLoading}
        pageCount={pageCount}
        pageNo={pageNo}
        rowsPerPage={rowsPerPage}
        setPageNo={setPageNo}
        setRowsPerPage={setRowsPerPage}
        columns={storageColumns}
        setParam={setParam}
        setSearchFilterText={setSearchFilterText}
        searchFilterText={searchFilterText}
      />
    </div>
  );
};

export default CandidateDrillDownTable;
