import { format, startOfMonth, endOfDay } from "date-fns";

export const formattedStartDate = (value: any) => {
  return format(startOfMonth(value), "y-MM-dd'T'HH:mm:ss");
};

export const formattedDate = (value: any, pattern: string) => {
  return format(value, pattern);
};

export const computedStartDate = (selectedRange: any) => {
  let date;
  if (selectedRange === undefined) {
    date = formattedDate(startOfMonth(new Date()), "y-MM-dd'T'HH:mm:ss");
  } else if (
    selectedRange.from === undefined &&
    selectedRange.to === undefined
  ) {
    date = formattedDate(startOfMonth(new Date()), "y-MM-dd'T'HH:mm:ss");
  } else {
    if (selectedRange.from !== undefined) {
      date = formattedDate(new Date(selectedRange.from), "y-MM-dd'T'HH:mm:ss");
    }
  }
  return date;
};

export const computedEndDate = (selectedRange: any) => {
  let date;
  if (selectedRange === undefined) {
    date = formattedDate(endOfDay(new Date()), "y-MM-dd'T'HH:mm:ss");
  } else if (
    selectedRange.from === undefined &&
    selectedRange.to === undefined
  ) {
    date = formattedDate(endOfDay(new Date()), "y-MM-dd'T'HH:mm:ss");
  } else {
    if (selectedRange.to !== undefined) {
      date = formattedDate(endOfDay(new Date(selectedRange.to)), "y-MM-dd'T'HH:mm:ss");
    }
  }
  return date;
};
