import { useDispatch, useSelector } from "react-redux";
import RevenueMetricsRadioButton from "./RevenueMetricsRadio.component";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import RevenueMetricsDatePicker from "./RevenueMetricsDatePicker";

import {
  computedEndDate,
  computedStartDate,
} from "../../../../helpers/date.helpers";

//reducers
import {
  setParameterName,
  setQueryparameter,
  setHeaderName,
  setFilterTags,
} from "../../../../reducers/common-reducer";
import { useNavigate } from "react-router-dom";

import { numberWithCommas } from "../../../../helpers/common.helpers";
import { setItem } from "../../../utils/persistanceStorage";

import { format, parseISO } from "date-fns";
import RevenueMetricsSection from "./RevenueMetricsSection.component";

interface RevenueMetricsPropsDefinition {
  setSelectedRange: Dispatch<SetStateAction<DateRange | undefined>>;
  selectedRange: DateRange | any;
  recruiterPanelData: any;
}

const RevenueMetrics: React.FC<RevenueMetricsPropsDefinition> = ({
  setSelectedRange,
  selectedRange,
  recruiterPanelData,
}) => {
  const navigate = useNavigate();

  //stored state
  const dispatch = useDispatch();
  const dateRange = useSelector(
    (state: any) => state.rangeSelection.revenueMetricsRange
  );

  const [showRevenueDatePicker, setShowRevenueDatePicker] = useState(false);
  const [tags, setTags] = useState([] as any);

  let placementPercentage =
    (recruiterPanelData.summary.placements /
      recruiterPanelData.summary.interviews) *
    100;

  let fromDate = computedStartDate(dateRange);
  let toDate = computedEndDate(dateRange);

  // state variables
  const revenueMetricSelection = useSelector(
    (state: any) => state.metricsSelection.revenueMetricSelection
  );

  useEffect(() => {
    setItem(
      "ucd_dashbord_revenue_applications",
      recruiterPanelData.applications
    );

    if (revenueMetricSelection === "Custom") {
      setShowRevenueDatePicker(true);
    } else {
      setShowRevenueDatePicker(false);
    }
  }, [revenueMetricSelection]);

  const handleAnchorClick = (
    event: any,
    name: any,
    header: string,
    query: any
  ) => {
    let total_interviews, placements, revenue, dateRange;

    if (revenueMetricSelection === "Custom") {
      dateRange = `${format(parseISO(fromDate as any), "y-MM-dd")} - ${format(
        parseISO(toDate as any),
        "y-MM-dd"
      )}`;
    } else {
      dateRange = revenueMetricSelection;
    }

    total_interviews =
      recruiterPanelData.summary.interviews > 1
        ? `${recruiterPanelData.summary.interviews} interviews`
        : `${recruiterPanelData.summary.interviews} interview`;
    placements =
      recruiterPanelData.summary.placements > 1
        ? `${recruiterPanelData.summary.placements} placements`
        : `${recruiterPanelData.summary.placements} placement`;
    revenue = `£${numberWithCommas(
      recruiterPanelData.summary.revenue_generated
    )} revenue`;



    event.preventDefault();
    tags.push(total_interviews, placements, revenue, dateRange);
    dispatch(setParameterName(name));
    dispatch(setQueryparameter(query));
    dispatch(setHeaderName(header));
    dispatch(setFilterTags(tags));
    navigate(`/workflows/ucd/interview-drilldown`);
  };
  return (
    <div
      className="bg-white rounded-2xl px-3 py-8 pb-5 border border-grey lg:mt-0"
      aria-label="revenue-metrics-panel"
    >
      <div className="grid grid-cols-6 gap-4 ml-3">
        <div className="col-span-3 xl:col-span-2">
          <h2 className="font-medium text-sm">Revenue Metrics</h2>
        </div>
        <div className="col-span-3 xl:col-span-4 flex justify-end items-end">
          <RevenueMetricsRadioButton />
        </div>
      </div>
      {showRevenueDatePicker ? (
        <RevenueMetricsDatePicker
          setSelectedRange={setSelectedRange as any}
          selectedRange={selectedRange}
          pathName={window.location.pathname}
        />
      ) : null}
      <h3
        className={
          showRevenueDatePicker
            ? "font-bold text-xs lg:mt-3 xl:mt-0 text-metricsGreen ml-3"
            : "font-bold text-xs md:mt-[67px] lg:mt-[64px] xl:mt-[64px] 2xl:w-[66px] text-metricsGreen ml-3"
        }
      >
        Recruiter
      </h3>
      <div className="flex flex-col xl:grid xl:grid-cols-3 mt-3 xl:mt-0 xl:gap-4 ml-3">
        <div className="flex flex-row grid grid-cols-2 mt-1.75 xl:mt-2 gap-4 xl:col-span-2">
          {recruiterPanelData.summary.interviews > 0 ? (
            <>
              <div className="hidden lg:block border border-metricsGreen bg-white hover:bg-lightGreen rounded-xl">
                <a
                  href={`/workflows/ucd/interview-drilldown?q=interview_1_stage_associated_date:>=${fromDate} AND interview_1_stage_associated_date:<${toDate}`}
                  onClick={(event) =>
                    handleAnchorClick(
                      event,
                      "name",
                      "Revenue metrics",
                      `interview_1_stage_associated_date:>=${fromDate} AND interview_1_stage_associated_date:<${toDate}`
                    )
                  }
                >
                  <RevenueMetricsSection
                    heading={" Interviews"}
                    value={recruiterPanelData.summary.interviews}
                    toolTipText=" Total interviews with re-engaged candidates within six
                    months of starting in this workflow."
                    ariaLabel={"recruiter-interviews"}
                    calculationPercent={undefined}
                  />
                </a>
              </div>
              <div className="block lg:hidden border border-metricsGreen bg-white hover:bg-lightGreen rounded-xl">
                <RevenueMetricsSection
                  heading={" Interviews"}
                  value={recruiterPanelData.summary.interviews}
                  toolTipText=" Total interviews with re-engaged candidates within six
                    months of starting in this workflow."
                  ariaLabel={"recruiter-interviews"}
                  calculationPercent={undefined}
                />
              </div>
            </>
          ) : (
            <div className="border border-metricsGreen bg-white hover:bg-lightGreen rounded-xl">
              <RevenueMetricsSection
                heading={" Interviews"}
                value={recruiterPanelData.summary.interviews}
                toolTipText=" Total interviews with re-engaged candidates within six
                    months of starting in this workflow."
                ariaLabel={"recruiter-interviews"}
                calculationPercent={undefined}
              />
            </div>
          )}

          {recruiterPanelData.summary.placements > 0 ? (
            <>
              <div className="hidden lg:block border border-metricsGreen bg-white hover:bg-lightGreen rounded-xl">
                <a
                  href={`/workflows/ucd/interview-drilldown?q=interview_1_stage_associated_date:>=${fromDate} AND interview_1_stage_associated_date:<${toDate}`}
                  onClick={(event) =>
                    handleAnchorClick(
                      event,
                      "name",
                      "Revenue metrics",
                      `interview_1_stage_associated_date:>=${fromDate} AND interview_1_stage_associated_date:<${toDate}`
                    )
                  }
                >
                  <RevenueMetricsSection
                    heading={"Placements"}
                    value={recruiterPanelData.summary.placements}
                    toolTipText=" Total placements with re-engaged candidates within six
                    months of starting in this workflow."
                    ariaLabel={"recruiter-placements"}
                    calculationPercent={placementPercentage}
                  />
                </a>
              </div>
              <div className="block lg:hidden border border-metricsGreen bg-white hover:bg-lightGreen rounded-xl">
                <RevenueMetricsSection
                  heading={"Placements"}
                  value={recruiterPanelData.summary.placements}
                  toolTipText=" Total placements with re-engaged candidates within six
                  months of starting in this workflow."
                  ariaLabel={"recruiter-placements"}
                  calculationPercent={placementPercentage}
                />
              </div>
            </>
          ) : (
            <div className="border border-metricsGreen bg-white hover:bg-lightGreen rounded-xl">
              <RevenueMetricsSection
                heading={"Placements"}
                value={recruiterPanelData.summary.placements}
                toolTipText=" Total placements with re-engaged candidates within six
              months of starting in this workflow."
                ariaLabel={"recruiter-placements"}
                calculationPercent={undefined}
              />
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 mt-4 sm:mt-2">
          {recruiterPanelData.summary.revenue_generated > 0 ? (
            <>
              <div className="hidden lg:block border border-metricsGreen bg-white hover:bg-lightGreen rounded-xl">
                <a
                  href={`/workflows/ucd/interview-drilldown?q=interview_1_stage_associated_date:>=${fromDate} AND interview_1_stage_associated_date:<${toDate}`}
                  onClick={(event) =>
                    handleAnchorClick(
                      event,
                      "name",
                      "Revenue metrics",
                      `interview_1_stage_associated_date:>=${fromDate} AND interview_1_stage_associated_date:<${toDate}`
                    )
                  }
                >
                  <RevenueMetricsSection
                    heading={"Revenue Generated"}
                    value={`£${numberWithCommas(
                      recruiterPanelData.summary.revenue_generated
                    )}`}
                    toolTipText="Total placement profit generated from reactivated
                    candidates within six months of starting in this workflow."
                    ariaLabel={"recruiter-revenue-generated"}
                    calculationPercent={undefined}
                  />
                </a>
              </div>
              <div className="block lg:hidden border border-metricsGreen bg-white hover:bg-lightGreen rounded-xl">
                <RevenueMetricsSection
                  heading={"Revenue Generated"}
                  value={`£${numberWithCommas(
                    recruiterPanelData.summary.revenue_generated
                  )}`}
                  toolTipText="Total placement profit generated from reactivated
                  candidates within six months of starting in this workflow."
                  ariaLabel={"recruiter-revenue-generated"}
                  calculationPercent={undefined}
                />
              </div>
            </>
          ) : (
            <div className="border border-metricsGreen bg-white hover:bg-lightGreen rounded-xl">
              <RevenueMetricsSection
                heading={"Revenue Generated"}
                value={`£${numberWithCommas(
                  recruiterPanelData.summary.revenue_generated
                )}`}
                toolTipText="Total placement profit generated from reactivated
              candidates within six months of starting in this workflow."
                ariaLabel={"recruiter-revenue-generated"}
                calculationPercent={undefined}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RevenueMetrics;
