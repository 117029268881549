import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { ThreeDots } from "react-loader-spinner";

//components
import { Button, ClickableButton } from "@autometry/ui-components";

//Hooks
import { useMutateChangePassword } from "../../hooks/useMutateChangePassword";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .required("Password is required")
    .min(4, "Password length should be at least 4 characters")
    .max(12, "Password cannot exceed more than 12 characters"),
  cpassword: yup
    .string()
    .required("Confirm Password is required")
    .min(4, "Password length should be at least 4 characters")
    .max(12, "Password cannot exceed more than 12 characters")
    .oneOf([yup.ref("password")], "Passwords do not match"),
});

const PasswordResetForm = () => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const changePassword = useMutateChangePassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  function formSubmit(data: any) {
    let reset_code = window.location.pathname.split("/").pop();

    changePassword.mutate(
      {
        password: data.cpassword,
        code: reset_code,
        email: data.email,
      },
      {
        onSuccess: (res) => {
          if (res !== true) {
            setIsError(true);
          }
        },
        onError: () => {
          setIsError(true);
        },
      }
    );
  }

  if (changePassword.isSuccess)
    return (
      <div className="flex h-screen w-full justify-center items-center w-full flex-col">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#7a4ac8"
          ariaLabel="three-dots-loading"
          visible={true}
        />
        <h2 className="text-black font-normal text-2xl pb-8 text-center">
          Your password has been reset
        </h2>
        <div className="mb-8 flex justify-center">
          <ClickableButton
            children={"Go to Dashboard"}
            onClick={() => navigate("/")}
            buttonProps={{
              color: "black",
              isRounded: true,
              role: "button",
              size: "large",
            }}
          ></ClickableButton>
        </div>
      </div>
    );

  return (
    <div
      className="flex h-screen w-full justify-center items-center w-full flex-col"
      aria-label="change-password-form"
    >
      <h2 className="text-black font-bold text-3xl pb-8 text-center">
        Reset Your Password
      </h2>
      <div className="w-1/2 lg:w-1/2 xl:w-1/3 2xl:w-1/4 block">
        <form onSubmit={handleSubmit(formSubmit)}>
          {/** Email form field */}
          <div className="mb-8">
            <label
              htmlFor="email"
              className={`block font-normal text-sm mb-2 text-left ${
                errors.email ? "text-red-400" : "text-black"
              }`}
            >
              {errors.email ? "Error - Email address" : "Email address"}
            </label>
            <input
              type="text"
              {...register("email")}
              id="email"
              role="textbox"
              className={`block w-full bg-transparent border-b py-2 px-4 rounded placeholder-grey focus:border-black focus:outline-none focus:ring-transparent ${
                errors.email
                  ? "text-red-300 border-red-400 focus:border-red-400"
                  : "text-black border-lightgrey"
              }`}
            />
          </div>

          {/** Password form field */}
          <div className="mb-8">
            <label
              htmlFor="password"
              className={`block font-normal text-sm mb-2 text-left ${
                errors.password ? "text-red-400" : "text-black"
              }`}
            >
              {errors.password ? "Error - New Password" : "New Password"}
            </label>
            <input
              type="password"
              {...register("password")}
              id="password"
              role="textbox"
              className={`block w-full bg-transparent border-b py-2 px-4 rounded placeholder-grey focus:border-black focus:outline-none focus:ring-transparent ${
                errors.password
                  ? "text-red-300 border-red-400 focus:border-red-400"
                  : "text-black border-lightgrey"
              }`}
            />
            <p
              className="text-red-400 border-red-400 focus:border-red-400"
              role="alert"
            >
              {errors?.password?.message}
            </p>
          </div>

          {/** Confirm Password form field */}
          <div className="mb-8">
            <label
              htmlFor="cpassword"
              className={`block font-normal text-sm mb-2 text-left ${
                errors.cpassword ? "text-red-400" : "text-black"
              }`}
            >
              {errors.cpassword
                ? "Error - Confirm New Password"
                : "Confirm New Password"}
            </label>
            <input
              type="password"
              {...register("cpassword")}
              id="cpassword"
              role="textbox"
              className={`block w-full bg-transparent border-b py-2 px-4 rounded placeholder-grey focus:border-black focus:outline-none focus:ring-transparent ${
                errors.cpassword
                  ? "text-red-300 border-red-400 focus:border-red-400"
                  : "text-black border-lightgrey"
              }`}
            />
            <p
              className="text-red-400 border-red-400 focus:border-red-400"
              role="alert"
            >
              {errors?.cpassword?.message}
            </p>
          </div>
          <div className="mb-8 flex justify-center">
            <Button
              children="Confirm Reset"
              size="large"
              color="black"
              role="button"
              isRounded={true}
              dynamicClassProperty="w-full"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetForm;
