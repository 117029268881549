import { createSlice } from "@reduxjs/toolkit";

export const monthSelection = createSlice({
  name: "jobSelection",
  initialState: {
    monthSelection: "current_month",
  },
  reducers: {
    setSelectedMonthType: (state, action) => {
      state.monthSelection = action.payload;
    },
  },
});

export const { setSelectedMonthType } = monthSelection.actions;

export default monthSelection.reducer;
