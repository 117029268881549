import React from "react"
import sprite from "../../assets/icons/sprite.svg";

type IconProps = React.SVGProps<SVGSVGElement> & {
	id: string
};

const Icon: React.FC<IconProps> = ({ id, ...props }) => (
	<svg {...props}>
		<use href={`${sprite}#${id}`}  />
	</svg>
)

export default Icon