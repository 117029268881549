import { AuthenticateRequest, SessionRequest } from "../types/authentication";
import axios from "../utils/axiosHandler";

export const Authentication = (
  authenticateRequest: AuthenticateRequest
) => {

  const data = {
    email_address: authenticateRequest.email,
    user_secret: authenticateRequest.password,
    requested_period: 36000,
  };

  return axios
    .post('/user/auth', data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
};

export const validateSession = (
  param: SessionRequest
) => {

  const data = {
    token: param.token,
    organisation: param.organisation,
  };

  return axios
    .post('/validate', data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
};