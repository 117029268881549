import * as React from "react";
import { Component, useEffect, useState } from "react";

interface ToastProps {
  displayToast: boolean;
  text: string;
}

const Toast: React.FC<ToastProps> = ({ displayToast, text }) => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (displayToast) {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 4500);
    }
  }, [displayToast]);

  if (!showToast) return null;

  return (
    <div
      className="flex flex-col justify-center items-center bg-blue-lightest absolute lg:top-5 top-24"
      data-testid="toast"
    >
      <div
        className="bg-grey-lightest p-2 px-5 rounded shadow-lg flex items-center justify-between mb-6 border-l-4 border-red-500"
        role="alert"
      >
        <div className="py-1">
          <svg
            className="fill-current h-6 w-6 text-red-500 mr-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </svg>
        </div>

        <div className="sm:text-left text-center sm:mb-0 mb-3 w-128" aria-label="alert-box">
          <p
            className="font-normal mb-1 text-lg"
            role="paragraph"
          >
            { text }
          </p>
        </div>
      </div>
    </div>
  );
};

export default Toast;
