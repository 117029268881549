import axios from "../utils/axiosDataHandler";

export const newCandidateDetails = (entity: string, fromDate: any, toDate: any, type: string) => {
  const responseHeaders = {
    headers: {
      correlation: "Candidates Data values",
    },
  };
  return axios
    .get(
      `/dashboard/statistics/${entity}?date_from=${fromDate}&date_to=${toDate}&candidate_type=${type}`,
      responseHeaders
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
};

export const addedJobDetails = (entity: string, fromDate: any, toDate: any, type: string) => {
    const responseHeaders = {
      headers: {
        correlation: "Candidates Data values",
      },
    };
    return axios
      .get(
        `/dashboard/statistics/${entity}?date_from=${fromDate}&date_to=${toDate}&candidate_type=${type}`,
        responseHeaders
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (typeof error === "string") {
          throw new Error(error);
        } else console.error(error);
      });
  };

  export const candidatesSentDetails = (entity: string, fromDate: any, toDate: any, type: string) => {
    const responseHeaders = {
      headers: {
        correlation: "Candidates Data values",
      },
    };
    return axios
      .get(
        `/dashboard/statistics/${entity}?date_from=${fromDate}&date_to=${toDate}&candidate_type=${type}`,
        responseHeaders
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (typeof error === "string") {
          throw new Error(error);
        } else console.error(error);
      });
  };

  export const liveJobDetails = (entity: string, fromDate: any, toDate: any, type: string) => {
    const responseHeaders = {
      headers: {
        correlation: "Candidates Data values",
      },
    };
    return axios
      .get(
        `/dashboard/statistics/${entity}?date_from=${fromDate}&date_to=${toDate}&candidate_type=${type}`,
        responseHeaders
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (typeof error === "string") {
          throw new Error(error);
        } else console.error(error);
      });
  };

  export const candidateDataDetails = (entity: string, fromDate: any, toDate: any, type: string) => {
    const responseHeaders = {
      headers: {
        correlation: "Candidates Data values",
      },
    };
    return axios
      .get(
        `/dashboard/statistics/${entity}?date_from=${fromDate}&date_to=${toDate}&candidate_type=${type}`,
        responseHeaders
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (typeof error === "string") {
          throw new Error(error);
        } else console.error(error);
      });
  };

  export const clientDataDetails = (entity: string, fromDate: any, toDate: any, type: string) => {
    const responseHeaders = {
      headers: {
        correlation: "Candidates Data values",
      },
    };
    return axios
      .get(
        `/dashboard/statistics/${entity}?date_from=${fromDate}&date_to=${toDate}&candidate_type=${type}`,
        responseHeaders
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (typeof error === "string") {
          throw new Error(error);
        } else console.error(error);
      });
  };