import { Component, useMemo } from "react";
import { Column, useSortBy, useTable } from "react-table";

interface tableProps {
  columnDef: Column[];
  dataArray: Record<string, any>[];
}

export default function Table({ columnDef, dataArray }: tableProps) {
  const columns = useMemo(() => columnDef, [columnDef]) as any;
  const data = useMemo(() => dataArray, [dataArray]) as any;

  // Use the useTable Hook to send the columns and data to build the table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy // This plugin Hook will help to sort our table columns
    );

  return (
    <div className="border border-grey rounded-md overflow-hidden" aria-label="modal-table">
      <table {...getTableProps()} className="min-w-full border-collapse">
        <thead className="bg-sidebar">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  scope="col"
                  className="text-center p-3 border-b border-grey font-semibold"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className="flex items-center">
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="ml-2 text-[10px]">▼</span>
                        ) : (
                          <span className="ml-2 text-[10px]">▲</span>
                        )
                      ) : (
                        <span
                          className="flex flex-col text-[10px] ml-2"
                          aria-label="Unsorted"
                        >
                          <span
                            aria-label="up arrow"
                            className="-mb-2"
                            role="img"
                          >
                            &#9650;
                          </span>
                          <span aria-label="down arrow" role="img">
                            &#9660;
                          </span>
                        </span>
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-grey">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="p-3">
                      {cell.render("Cell")}{" "}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {dataArray.length === 0 && (
        <div className="flex justify-center my-12 text-lg font-bold">
          <p>No results found</p>
        </div>
      )}
    </div>
  );
}
