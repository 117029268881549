import { createSlice } from "@reduxjs/toolkit";

export const jobSelection = createSlice({
  name: "jobSelection",
  initialState: {
    jobSelection: "ALL",
  },
  reducers: {
    setSelectedJobType: (state, action) => {
      state.jobSelection = action.payload;
    },
  },
});

export const { setSelectedJobType } = jobSelection.actions;

export default jobSelection.reducer;
