// q=%20&offset=0&limit=10&sort_field=ats_last_updated&sort_by=-1'

import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/react-query/constants"
import { fetchRecruiters } from "../services/workflowsDetails";
import { CandidateRequest } from "../types/candidate"

export const RecruiterInfo = (param: CandidateRequest) => {
  const notEmptyPage = param !== undefined
	const queryResult = useQuery([queryKeys.storageQuery, param], () => fetchRecruiters(param), {
		enabled: notEmptyPage,
    refetchOnWindowFocus: false,
	})
  return queryResult
}
