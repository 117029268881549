export const jobData = {
    "jobs": 45,
    "covered": 27,
    "cvs_per_job": 5.1,
    "location": 32,
    "salary": 28,
    "no_valid_details": 6,
    "matches": {
        "matches": 9020,
        "per_job": 200,
        "per_owner": 312
    },
    "email": {
        "total_sent": 5863,
        "engaged": 1020,
        "applied": 606,
        "unsubscribed":30,
    },
    "sms": {
        "sent": 4660,
        "engaged": 1398,
        "applied": 326,
        "unsubscribed": 40
    }
}  