import React, { useState, useMemo, useEffect, useCallback } from "react";
import { ClickableButton, Table, Button } from "@autometry/ui-components/";
import { SortingRule } from "react-table";
import { RecruiterInfo } from "./hooks/useRecruiterProfile";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CSVDownload, CSVLink } from "react-csv";

import {
  MagnifyingGlassIcon,
  ArrowLeftIcon,
  EllipsisHorizontalCircleIcon,
} from "@heroicons/react/24/solid";

import desktopImage from "../assets/desktop.svg";
import RecruiterModal from "./RecruiterModal.component";
import csvIcon from "../assets/csv.svg";
import LandingPageLoader from "./Loading/LandingPageLoader";

interface RecruiterDrillDownTableProps {}

const RecruiterDrillDownTable: React.FC<RecruiterDrillDownTableProps> = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [rowData, setRowData] = useState([] as any);

  let entity_name = useSelector((state: any) => state.selection.parameterName);
  let query_param = useSelector((state: any) => state.selection.queryParameter);
  let header_name = useSelector((state: any) => state.selection.headerName);
  let tagName = useSelector((state: any) => state.selection.filterTags);
  let adminUser = localStorage.getItem("admin") as any;

  const storageCol = [
    {
      Header: "Name",
      accessor: "owner",
      width: "25%",
      Cell: (props: { cell: any; value: any }) => {
        if (props.cell.row.original.is_active) {
          return (
            <div className="text-black">{props.cell.row.original.owner}</div>
          );
        } else {
          return (
            <div className="text-red-400">{props.cell.row.original.owner}</div>
          );
        }
      },
    },
    {
      Header: "Candidate Leads",
      accessor: "total_candidates",
      width: "25%",
      Cell: (props: { cell: any; value: any }) => {
        let total_candidates = props.cell.row.original.total_candidates;
        return total_candidates;
      }
    },
    {
      Header: "Candidates Viewed",
      accessor: "leads_viewed",
      width: "25%",
      Cell: (props: { cell: any; value: any }) => {
        return props.cell.row.original.leads_viewed;
      },
    },
    {
      Header: "Candidates Viewed %",
      accessor: "leads_viewed_percentage",
      width: "25%",
      Cell: (props: { cell: any; value: any }) => {
        return props.cell.row.original.leads_viewed_percentage;
      },
    },
    {
      Header: "Action Link",
      accessor: "-",
      width: "25%",
      Cell: (props: { cell: any; value: any }) => {
        return (
          <>
            {props.cell.row.original.candidates && (
              <div className="group relative">
                <ClickableButton
                  onClick={() => {
                    openModal(props.cell.row.original.candidates);
                  }}
                  buttonProps={{
                    color: "transparent",
                    size: "small",
                    dynamicClassProperty: "rounded-2xl border-none !p-0 !px-5",
                  }}
                >
                  <EllipsisHorizontalCircleIcon className="w-6 h-6" />
                </ClickableButton>
                <span className="!absolute -top-6 -left-40 scale-0 danger-arrow-right bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                  View Candidate Details
                </span>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const openModal = (param: any) => {
    setShowModal(true);
    setRowData(param);
  };

  const [param, setParam] = useState({
    q: query_param,
    offset: 0,
    limit: 50,
    sort_field: entity_name,
    sort_by: 1,
  });

  const storageColumns = useMemo(() => storageCol, [storageCol]);

  const {
    data: recruiterData,
    isFetching: recruiterLoading,
    isError: recruiterDataError,
  } = RecruiterInfo(param);

  const [tableData, setTableData] = useState([] as any);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageNo, setPageNo] = useState<number>(0);
  const [sortTableBy, setSortTableBy] = useState<
    SortingRule<Record<string, unknown>>[] | undefined
  >([]);
  const [searchFilterText, setSearchFilterText] = useState<string>("" as any);
  useEffect(() => {
    if (recruiterData !== undefined) {
      if (recruiterData.total >= 0)
        setPageCount(Math.ceil(recruiterData.total / rowsPerPage));
      if (recruiterData.total === 0) {
        setPageCount(1);
        setPageNo(0);
      }
    }
  }, [recruiterData, recruiterLoading, rowsPerPage]);

  useEffect(() => {
    if (!recruiterLoading && recruiterData) {
      setTableData(recruiterData.data);
    }
  }, [recruiterData, recruiterLoading]);

  useEffect(() => {
    if (recruiterDataError) {
      setTableData([]);
    }
  }, [recruiterDataError]);

  useEffect(() => {
    let updatedQuery = `${searchFilterText}`;
    if (searchFilterText !== "") {
      const handler = setTimeout(() => {
        setParam((prevState) => ({
          ...prevState,
          q: updatedQuery,
        }));
      }, 2000);

      return () => {
        clearTimeout(handler);
      };
    } else {
      setParam((prevState) => ({
        ...prevState,
        q: query_param,
      }));
    }
  }, [searchFilterText]);

  const handleSearch = (event: any) => {
    let searchValue = event.target.value;

    // Set the state to trigger a re-rendering
    setSearchFilterText(searchValue);
  };

  const handleFetchData = useCallback(
    ({ sortBy, pageIndex, pageSize }: any) => {
      if (sortBy?.length) {
        let currentPageNo: number;
        if (sortBy !== sortTableBy) {
          currentPageNo = 0;
        } else {
          currentPageNo = pageIndex;
        }
        setSortTableBy(sortBy);
        setPageNo(currentPageNo);
        setRowsPerPage(pageSize);
        const sortReq = sortBy?.map((item: any) => {
          return { field: item.id, order: item.desc ? -1 : 1 };
        });

        setParam((prevState) => ({
          ...prevState,
          offset: currentPageNo * pageSize,
          limit: pageSize,
          sort_field: sortReq[0].field,
          sort_by: sortReq[0].order,
        }));
      } else {
        if (
          pageIndex !== pageNo ||
          pageSize !== rowsPerPage ||
          sortBy !== sortTableBy
        ) {
          setSortTableBy(sortBy);
          setPageNo(pageIndex);
          setRowsPerPage(pageSize);
          setParam((prevState) => ({
            ...prevState,
            offset: pageIndex * pageSize,
            limit: pageSize,
          }));
        }
      }
    },
    [sortTableBy, pageNo, rowsPerPage]
  );

  return (
    <div aria-label="recruiter-drilldown-table" className="w-full">
      {/** restricting view on small devices */}
      <div className="block lg:hidden w-full">
        <div className="flex flex-col justify-center items-center h-screen">
          <img src={desktopImage} alt="desktop-image" />
          <div className="mt-5 text-center">
            <h1 className="font-bold text-3xl"> View on desktop</h1>
            <p className="font-normal text-lg mt-3 m-auto max-w-[65%]">
              This page is not available on mobile. Please visit the desktop
              site to access this page.
            </p>
          </div>
        </div>
      </div>
      {/** table display on big screens */}
      <div className="hidden lg:block w-full block p-10 mt-5 h-screen">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <div className="flex flex-row xl:mt-0 mt-8">
              <ClickableButton
                onClick={() => navigate(`/workflows/ucd`)}
                buttonProps={{
                  color: "transparent",
                  size: "small",
                  dynamicClassProperty: "rounded-2xl hover:bg-grey !py-1",
                }}
              >
                <ArrowLeftIcon className="w-6 h-6" />
              </ClickableButton>

              <p className="font-bold text-2xl pl-3">{header_name}</p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="flex items-end justify-end mb-5 mt-8 xl:mt-0">
              <div className="flex flex-row relative">
                <input
                  type="text"
                  value={searchFilterText}
                  className="px-4 w-80 rounded-full border border-black py-4 focus:ring-0 focus:border-grey focus:outline-none"
                  placeholder="Search"
                  onChange={handleSearch}
                />
                <span className="flex items-center justify-center absolute right-8 top-5">
                  <MagnifyingGlassIcon className="w-6 h-6" />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/** Filter tags and export CSV button */}
        <div className="grid grid-cols-2">
          <div className="col-span-1">
            <div className="flex flex-row mb-3">
              {tagName.map((item: any, index: any) => (
                <div key={index} className="bg-sidebar p-3 rounded-md mr-2">
                  <span key={index}>{item}</span>
                </div>
              ))}
            </div>
          </div>
          {adminUser === "true" && (
            <div className="col-span-1">
              <div className="flex justify-end items-end mb-3">
                {recruiterData !== undefined ? (
                  <Button
                    size="small"
                    color="black"
                    role="button"
                    isRounded={true}
                    dynamicClassProperty="!py-3"
                  >
                    <CSVLink data={recruiterData.data} filename="export">
                      <span className="flex flex-row items-center justify-center font-bold">
                        <img src={csvIcon} className="w-6 h-6 mr-2" />
                        Export data
                      </span>
                    </CSVLink>
                  </Button>
                ) : null}
              </div>
            </div>
          )}
        </div>

        {recruiterData !== undefined ? (
          <Table
            columnDef={storageColumns as any}
            dataArray={recruiterData.data}
            isLoading={recruiterLoading}
            pageCount={pageCount}
            currentPageNo={pageNo}
            fetchData={handleFetchData}
            perPage={rowsPerPage as any}
            sort={sortTableBy}
            handleSearchTerm={setSearchFilterText}
            debounceTime={275}
          />
        ) : (
          <LandingPageLoader showLoader={true} />
        )}
      </div>
      {showModal ? (
        <>
          <RecruiterModal rowData={rowData} setShowModal={setShowModal} showModal={showModal}/>
          <div className="opacity-25 w-full fixed inset-0 z-[120] bg-black hidden lg:block"></div>
        </>
      ) : null}
    </div>
  );
};

export default RecruiterDrillDownTable;
