import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";

import "./App.css";
import SignInPage from "./components/forms/SignIn/SignInPage";
import Dashboard from "./components/Dashboard.component";
import ChangePassword from "./components/forms/ChangePassword/ChangePassword";
import Workflow from "./components/Workflow.component";
import CandidateDrilldown from "./components/CandidateDrilldown.component";
import RecruiterDrilldown from "./components/RecruiterDrillDown.component";
import RecruiterInterviewDrillDown from "./components/RecruiterInterviewDrillDown.component";
import SuccessPage from "./components/forms/Sucess";
import ErrorPage from "./components/forms/Error";
import NotFoundPage from "./components/forms/404NotFound";

function App() {
  let dashboardPermissions = localStorage.getItem("dashboard") as any;
  return (
    <div className="wrapper">
      <Routes>
        <Route path="/" element={<SignInPage />}/>
        <Route path="/success" element={<SuccessPage/>}/>
        <Route path="/error" element={<ErrorPage/>}/>
        <Route path="/404-not-found" element={<NotFoundPage/>}/>
        <Route path="/callback/password-reset-verify/:id" element={<ChangePassword />}/>
        <Route path='*' element={<Navigate to='/' />} />
        { dashboardPermissions==="true" && (
        <Route path="/dashboard" element={<Dashboard />}/>
        ) }
        <Route path="/workflows/:id" element={<Workflow />}/>
        <Route path="/workflows/ucd/candidate-drilldown" element={<CandidateDrilldown/>}/>
        <Route path="/workflows/ucd/recruiter-drilldown" element={<RecruiterDrilldown/>}/>
        <Route path="/workflows/ucd/interview-drilldown" element={<RecruiterInterviewDrillDown/>}/>
      </Routes>
    </div>
  );
}

export default App;
