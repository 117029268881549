import * as React from "react";
import ChartContent from "./chart";

// components
import DataHealthInfo from "../../common/DataHealthInfo.component";

// helpers
import { numberWithCommas } from "../../../helpers/common.helpers";
interface WorkflowPropsDefinition {
  workflowInfoData: any;
}

const DataHealth: React.FC<WorkflowPropsDefinition> = ({
  workflowInfoData,
}) => {
  return (
    <div
      className="bg-sidebar rounded-2xl py-6 md:py-8 px-6 md:pb-5 border border-grey xl:mt-6 lg:mt-6 w-full 3xl:w-2/3"
      aria-label="data-health-panel"
    >
      <DataHealthInfo databaseSize={workflowInfoData.total_database_size} livingDatabase={workflowInfoData.living_database_size} excludedFromFlow={workflowInfoData.total_excluded_from_flow} uncontactable={workflowInfoData.total_uncontactable} id={"ucd"}/>
    </div>
  );
};

export default DataHealth;
