import { createSlice } from "@reduxjs/toolkit";

export const dateSelection = createSlice({
  name: "rangeSelection",
  initialState: {
    selectedRange: { from: undefined, to: undefined },
    activityMetricsRange: { from: undefined, to: undefined },
    revenueMetricsRange: { from: undefined, to: undefined },
    jobMetricsRange: { from: undefined, to: undefined },
  },
  reducers: {
    setDateRangeSelection: (state, action) => {
      state.selectedRange = action.payload;
    },
    setActivityMetricsRangeSelection:  (state, action) => {
      state.activityMetricsRange = action.payload;
    },
    setRevenueMetricsRangeSelection:  (state, action) => {
      state.revenueMetricsRange = action.payload;
    },
    setJobMetricsRangeSelection:  (state, action) => {
      state.jobMetricsRange = action.payload;
    },
  },
});

export const { setDateRangeSelection, setActivityMetricsRangeSelection, setRevenueMetricsRangeSelection, setJobMetricsRangeSelection } = dateSelection.actions;

export default dateSelection.reducer;
