import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  computedEndDate,
  computedStartDate,
} from "../../../../helpers/date.helpers";

//reducers
import {
  setParameterName,
  setQueryparameter,
  setHeaderName,
  setFilterTags,
} from "../../../../reducers/common-reducer";

import emailImage from "../../../../assets/email.svg";
import { format, parseISO } from "date-fns";

interface RecruiterPropsDefinition {
  recruiterPanelData: any;
}

const RecruiterPanel: React.FC<RecruiterPropsDefinition> = ({
  recruiterPanelData,
}) => {
  const navigate = useNavigate();

  //stored state
  const dispatch = useDispatch();
  const dateRange = useSelector(
    (state: any) => state.rangeSelection.revenueMetricsRange
  );

  const [tags, setTags] = useState([] as any);

  const revenueMetricSelection = useSelector(
    (state: any) => state.metricsSelection.revenueMetricSelection
  );

  let fromDate = computedStartDate(dateRange);
  let toDate = computedEndDate(dateRange);

  let viewed =
    (recruiterPanelData.candidates_viewed /
      recruiterPanelData.candidate_leads) *
    100;

  const handleAnchorClick = (
    event: any,
    name: any,
    header: string,
    query: any
  ) => {
    // Plurasing the filter tags
    let recruiters, leads;
    recruiters =
      recruiterPanelData.total_recruiters > 1
        ? `${recruiterPanelData.total_recruiters} recruiters`
        : `${recruiterPanelData.total_recruiters} recruiter`;
    leads =
      recruiterPanelData.candidate_leads > 1
        ? `${recruiterPanelData.candidate_leads} candidate leads`
        : `${recruiterPanelData.candidate_leads} candidate lead`;

    let dateRange;
    if (revenueMetricSelection === "Custom") {
      dateRange = `${format(parseISO(fromDate as any), "y-MM-dd")} - ${format(
        parseISO(toDate as any),
        "y-MM-dd"
      )}`;
    } else {
      dateRange = revenueMetricSelection;
    }

    event.preventDefault();
    tags.push(recruiters, leads, dateRange);
    dispatch(setParameterName(name));
    dispatch(setQueryparameter(query));
    dispatch(setHeaderName(header));
    dispatch(setFilterTags(tags));
    navigate(`/workflows/ucd/recruiter-drilldown`);
  };

  return (
    <div aria-label="recruiter-panel">
      <h2
        className="text-lg text-black font-semibold mb-4 mt-10"
        aria-label="main-heading"
      >
        Recruiter Performance
      </h2>
      <div className="border border-grey rounded-2xl">
        <div className="block py-4 bg-sidebar rounded-t-2xl px-6 border-b">
          <div className="grid grid-cols-7 gap-3">
            <div className="col-span-2">
              <h2 className="font-semibold text-sm">Event</h2>
            </div>
            <div className="col-span-1">
              <h2 className="font-semibold text-sm flex justify-start items-start">
                Tools
              </h2>
            </div>
            <div className="col-span-1">
              <h2 className="font-semibold text-sm flex justify-start items-start">
                Targets
              </h2>
            </div>
            <div className="col-span-3">
              <div className="grid grid-cols-3">
                <h2 className="font-semibold text-sm flex justify-start">
                  Results
                </h2>
              </div>
            </div>
          </div>
        </div>
          <div className="recruiter-metrics-panel border-l-2 border-l-metricsGreen mb-3">
            <div
              className="block py-3 px-6 bg-white"
              aria-label="recruiter-section"
            >
              <div className="grid grid-cols-7 gap-3">
                <div className="col-span-2 md:col-span-2 flex flex-col justify-center 2xl:mt-0 max-w-min 2xl:max-w-fit">
                  <h2 className="font-medium text-sm">Recruiters</h2>
                </div>
                <div className="col-span-1">
                  <h2 className="flex justify-start items-start">
                    <img
                      src={emailImage}
                      alt="Vincere Logo"
                      className="w-8 mt-4 2xl:w-9"
                    />
                  </h2>
                </div>
                <div className="col-span-1">
                  <div
                    className="flex flex-col justify-start items-start mt-0 2xl:mt-0"
                    aria-label="total-recruiters"
                  >
                    {recruiterPanelData.total_recruiters > 0 ? (
                      <a
                        href={`/workflows/ucd/recruiter-drilldown?q=&from_date=${fromDate}&to_date=${toDate}`}
                        className="text-blue-500 font-medium text-sm cursor-pointer"
                        onClick={(event) =>
                          handleAnchorClick(
                            event,
                            "owner",
                            "Recruiter activity",
                            `&from_date=${fromDate}&to_date=${toDate}`
                          )
                        }
                      >
                        {recruiterPanelData.total_recruiters}
                      </a>
                    ) : (
                      <p className="font-medium text-sm">{recruiterPanelData.total_recruiters}</p>
                    )}

                    <p className="text-xs font-medium">
                      Recruiters
                    </p>
                  </div>
                </div>
                <div className="col-span-4 md:col-span-3">
                  <div className="grid grid-cols-3">
                    <div
                      className="col-span-1 flex flex-col justify-start items-start"
                      aria-label="candidates-leads"
                    >
                      {recruiterPanelData.candidate_leads > 0 ? (
                        <a
                          href={`/workflows/ucd/recruiter-drilldown?q=&from_date=${fromDate}&to_date=${toDate}`}
                          className="text-blue-500 font-medium text-sm cursor-pointer"
                          onClick={(event) =>
                            handleAnchorClick(
                              event,
                              "owner",
                              "Recruiters activity",
                              `&from_date=${fromDate}&to_date=${toDate}`
                            )
                          }
                        >
                          {recruiterPanelData.candidate_leads}
                        </a>
                      ) : (
                        <p className="font-medium text-sm">{recruiterPanelData.candidate_leads}</p>
                      )}

                      <p className="text-xs font-medium text-left max-w-min 2xl:max-w-fit 3xl:max-w-fit">
                        Candidates leads
                      </p>
                    </div>
                    <div
                      className="col-span-1 flex flex-col justify-start items-start"
                      aria-label="candidates-viewed"
                    >
                      {recruiterPanelData.candidates_viewed > 0 ? (
                        <a
                          href={`/workflows/ucd/recruiter-drilldown?q=from_date=&${fromDate}&to_date=${toDate}`}
                          className="text-blue-500 font-medium text-sm cursor-pointer"
                          onClick={(event) =>
                            handleAnchorClick(
                              event,
                              "owner",
                              "Recruiters activity",
                              `&from_date=${fromDate}&to_date=${toDate}`
                            )
                          }
                        >
                          {recruiterPanelData.candidates_viewed}
                        </a>
                      ) : (
                        <p className="font-medium text-sm">{recruiterPanelData.candidates_viewed}</p>
                      )}

                      <p className="text-xs font-medium text-left max-w-min 2xl:max-w-fit 3xl:max-w-fit">
                        Candidates viewed
                      </p>
                    </div>
                    <div className="col-span-1 flex flex-col justify-start items-start">
                      <p className="font-medium text-sm">
                        {!Number.isNaN(viewed)
                          ? Math.round(viewed) + "%"
                          : "0%"}
                      </p>
                      <p className="text-xs font-medium text-left max-w-min 2xl:max-w-fit 3xl:max-w-fit">
                        Candidates viewed
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default RecruiterPanel;
