import { useDispatch, useSelector } from "react-redux";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DateRange } from "react-day-picker";

// helpers
import {
  computedEndDate,
  computedStartDate,
} from "../../../../helpers/date.helpers";

// components
import ActiviyMetricsDatePicker from "./ActivityMetricsDatePicker";
import ActivityMetricsRadioButton from "./ActivityMetricsRadio.component";
import ActivityMetricsSection from "./ActivityMetricsSection.component";

//reducers
import {
  setParameterName,
  setQueryparameter,
  setHeaderName,
  setFilterTags,
} from "../../../../reducers/common-reducer";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";

interface ActivityMetricsPropsDefinition {
  setSelectedRange: Dispatch<SetStateAction<DateRange | undefined>>;
  selectedRange: DateRange | any;
  activityPanelData: any;
}

const ActivityMetrics: React.FC<ActivityMetricsPropsDefinition> = ({
  setSelectedRange,
  selectedRange,
  activityPanelData,
}) => {
  const navigate = useNavigate();

  //stored state
  const dispatch = useDispatch();
  const dateRange = useSelector(
    (state: any) => state.rangeSelection.activityMetricsRange
  );

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [tags, setTags] = useState([] as any);

  // redux state values
  const metricSelection = useSelector(
    (state: any) => state.metricsSelection.activityMetricSelection
  );

  let engagedPercentage =
    (activityPanelData.summary.engaged /
      activityPanelData.summary.total_candidates) *
    100;
  let updatedPercentage =
    (activityPanelData.summary.updated_details /
      activityPanelData.summary.total_candidates) *
    100;

  let fromDate = computedStartDate(dateRange);
  let toDate = computedEndDate(dateRange);

  useEffect(() => {
    if (metricSelection === "Custom") {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
    }
  }, [metricSelection]);

  const handleAnchorClick = (
    event: any,
    name: any,
    header: string,
    query: any
  ) => {
    let dateRange;
    if (metricSelection === "Custom") {
      dateRange = `${format(parseISO(fromDate as any), "y-MM-dd")} - ${format(
        parseISO(toDate as any),
        "y-MM-dd"
      )}`;
    } else {
      dateRange = metricSelection;
    }

    tags.push(
      `${activityPanelData.summary.total_candidates} candidates`,
      `${activityPanelData.summary.engaged} engaged`,
      `${activityPanelData.summary.updated_details} updated details`,
      dateRange
    );
    event.preventDefault();
    dispatch(setParameterName(name));
    dispatch(setQueryparameter(query));
    dispatch(setHeaderName(header));
    dispatch(setFilterTags(tags));
    navigate(`/workflows/ucd/candidate-drilldown?q=${query}`);
  };

  return (
    <div
      className="bg-white rounded-2xl px-3 py-8 pb-5 border border-grey"
      aria-label="activity-metrics-panel"
    >
      <div className="grid grid-cols-6 gap-4 ml-3">
        <div className="col-span-3 xl:col-span-2">
          <h2 className="font-medium text-sm">
            Activity Metrics
          </h2>
        </div>
        <div className="col-span-3 xl:col-span-4 flex justify-end items-end">
          <ActivityMetricsRadioButton />
        </div>
      </div>
      {showDatePicker ? (
        <ActiviyMetricsDatePicker
          setSelectedRange={setSelectedRange as any}
          selectedRange={selectedRange}
          pathName={window.location.pathname}
        />
      ) : null}
      <h3
        className={
          showDatePicker
            ? "font-bold text-xs lg:mt-3 xl:mt-0 text-purple ml-3"
            : "font-bold text-xs md:mt-[67px] lg:mt-[64px] xl:mt-[64px] 2xl:w-[66px] text-purple ml-3"
        }
      >
        Candidate
      </h3>
      <div className="flex flex-col xl:grid xl:grid-cols-3 mt-3 xl:mt-0 xl:gap-4 ml-3">
        <div className="flex flex-row grid grid-cols-2 mt-0 xl:mt-2 gap-4 xl:col-span-2">
          {activityPanelData.summary.total_candidates > 0 ? (
            <>
              <div className="hidden lg:block rounded-xl border border-purple bg-white hover:bg-lightPurple">
                <a
                  href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}`}
                  onClick={(event) =>
                    handleAnchorClick(
                      event,
                      "name",
                      activityPanelData.summary.total_candidates > 1
                        ? "Activity metrics"
                        : "Activity metrics",
                      `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}`
                    )
                  }
                >
                  <ActivityMetricsSection
                    heading={"Candidates"}
                    value={activityPanelData.summary.total_candidates}
                    toolTipText="Total number of dormant candidates who started in the workflow within the selected time period."
                    ariaLabel={"candidate-info-details"}
                    calculationPercent={undefined}
                  />
                </a>
              </div>
              <div className="block lg:hidden rounded-xl border border-purple bg-white hover:bg-lightPurple">
                <ActivityMetricsSection
                  heading={"Candidates"}
                  value={activityPanelData.summary.total_candidates}
                  toolTipText="Total number of dormant candidates who started in the workflow within the selected time period."
                  ariaLabel={"candidate-info-details"}
                  calculationPercent={undefined}
                />
              </div>
            </>
          ) : (
            <div className="rounded-xl border border-purple bg-white hover:bg-lightPurple">
            <ActivityMetricsSection
              heading={"Candidates"}
              value={activityPanelData.summary.total_candidates}
              toolTipText="Total number of dormant candidates who started in the workflow within the selected time period."
              ariaLabel={"candidate-info-details"}
              calculationPercent={undefined}
            />
            </div>
          )}

          {activityPanelData.summary.engaged > 0 ? (
            <>
              <div className="hidden lg:block rounded-xl border border-purple bg-white hover:bg-lightPurple">
                <a
                  href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}`}
                  onClick={(event) =>
                    handleAnchorClick(
                      event,
                      "name",
                      activityPanelData.summary.engaged > 1
                        ? "Activity metrics"
                        : "Activity metrics",
                      `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}`
                    )
                  }
                >
                  <ActivityMetricsSection
                    heading={"Engaged"}
                    value={activityPanelData.summary.engaged}
                    toolTipText="Total number of dormant candidates who re-engaged with your brand within the selected time period."
                    ariaLabel={"candidate-engaged-details"}
                    calculationPercent={engagedPercentage}
                  />
                </a>
              </div>
              <div className="block lg:hidden rounded-xl border border-purple bg-white hover:bg-lightPurple">
                <ActivityMetricsSection
                  heading={"Engaged"}
                  value={activityPanelData.summary.engaged}
                  toolTipText="Total number of dormant candidates who re-engaged with your brand within the selected time period."
                  ariaLabel={"candidate-engaged-details"}
                  calculationPercent={engagedPercentage}
                />
              </div>
            </>
          ) : (
            <div className="rounded-xl border border-purple bg-white hover:bg-lightPurple">
            <ActivityMetricsSection
              heading={"Engaged"}
              value={activityPanelData.summary.engaged}
              toolTipText="Total number of dormant candidates who re-engaged with your brand within the selected time period."
              ariaLabel={"candidate-engaged-details"}
              calculationPercent={undefined}
            />
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 mt-4 sm:mt-2">
          {activityPanelData.summary.updated_details > 0 ? (
            <>
              <div className="hidden lg:block rounded-xl border border-purple bg-white hover:bg-lightPurple">
                <a
                  href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}`}
                  onClick={(event) =>
                    handleAnchorClick(
                      event,
                      "name",
                      activityPanelData.summary.updated_details > 1
                        ? "Activity metrics"
                        : "Activity metrics",
                      `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}`
                    )
                  }
                >
                  <ActivityMetricsSection
                    heading={"Updated Details"}
                    value={activityPanelData.summary.updated_details}
                    toolTipText="Total number of dormant candidates who have updated their contact details within the selected time period."
                    ariaLabel="candidate-updated-details"
                    calculationPercent={updatedPercentage}
                  />
                </a>
              </div>
              <div className="block lg:hidden">
                <div className="xl:max-w-sm rounded-xl border border-purple bg-white hover:bg-lightPurple">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="group relative mt-1 ml-2 max-w-fit">
                      <svg
                        className="inline-block lg:w-[12px] lg:h-[12px] 2xl:w-[14px] 2xl:h-[14px] 3xl:w-[16px] 3xl:h-[16px] text-xs text-tooltip"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fa"
                        data-icon="info-circle"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                        ></path>
                      </svg>
                      <span className="w-60 z-50 text-xs !absolute -left-9 -top-24 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                        Total number of dormant candidates who have updated
                        their contact details within the selected time period.
                      </span>
                    </div>
                    <div className="mt-1 mr-2 flex justify-end items-end">
                      <p className="bg-black rounded-full text-white font-semibold p-1 px-2.5 w-auto flex justify-center items-center text-[10px] cursor-default">
                        {Number(updatedPercentage).toFixed(1)}%
                      </p>
                    </div>
                  </div>

                  <div
                    className={updatedPercentage !== undefined ? "px-5 my-8": "px-5 my-8"}
                    aria-label="candidate-updated-details"
                  >
                    <div className="w-fit">
                      <div className="flex flex-col hover:underline">
                        <p className="font-medium text-2xl">
                          {activityPanelData.summary.updated_details}
                        </p>
                        <p className="font-normal text-xs">
                          Updated Details
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="xl:max-w-sm rounded-xl border border-purple bg-white hover:bg-lightPurple">
              <div className="group relative mt-1 ml-2 max-w-fit">
                <svg
                  className="inline-block lg:w-[12px] lg:h-[12px] 2xl:w-[14px] 2xl:h-[14px] 3xl:w-[16px] 3xl:h-[16px] text-xs text-tooltip"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fa"
                  data-icon="info-circle"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                  ></path>
                </svg>
                <span className="w-60 z-50 text-xs !absolute -left-9 -top-28 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                  Total number of dormant candidates who have updated their
                  contact details within the selected time period.
                </span>
              </div>
              <div
                className="px-5 my-8"
                aria-label="candidate-updated-details"
              >
                <div className="w-fit">
                  <div className="flex flex-col hover:underline">
                    <p className="font-medium text-2xl">
                      {activityPanelData.summary.updated_details}
                    </p>
                    <p className="font-normal text-xs">
                      Updated Details
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityMetrics;
