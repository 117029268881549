import * as React from "react";

// components
import DataHealthInfo from "../../common/DataHealthInfo.component";

interface WorkflowPropsDefinition {
  workflowInfoData: any;
}

const DataHealth: React.FC<WorkflowPropsDefinition> = ({
  workflowInfoData,
}) => {
  return (
    <div
      className="bg-sidebar rounded-2xl p-8 py-12 px-6 border border-grey xl:mt-6 lg:mt-20"
      aria-label="data-health-panel"
    >
      <DataHealthInfo databaseSize={7220} livingDatabase={5000} excludedFromFlow={2000} uncontactable={200} id={"job-coverage"}/>
    </div>
  );
};

export default DataHealth;
