import logo from "../../assets/autometry-logo.svg";

const ErrorPage = () => {
  return (
    <div
      className="lg:grid lg:grid-cols-6 lg:gap4 flex flex-col h-screen lg:h-auto"
      aria-label="error-page"
    >
      <div className="lg:col-span-2 lg:border-r border-b p-4 border-grey">
        <div className="flex lg:h-full w-full justify-center items-center">
          <img
            src={logo}
            alt="autometry-logo"
            className="w-3/12 lg:w-1/2 md:w-3/12 sm:w-3/12 xl:w-1/2"
          />
        </div>
      </div>
      <div className="error-form col-span-4 flex justify-center items-center h-screen">
        <div className="flex h-screen w-full justify-center items-center w-full flex-col">
          <h2 className="text-black font-bold text-3xl pb-5 text-center">
            There's been a problem
          </h2>
          <div className="text-black text-center pb-8">
            The API authorisation was not successful. Please contact Autometry.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
