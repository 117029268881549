import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//reducers
import {
  setParameterName,
  setQueryparameter,
  setHeaderName,
  setFilterTags,
} from "../../../../reducers/common-reducer";

// helpers
import {
  computedEndDate,
  computedStartDate,
} from "../../../../helpers/date.helpers";

// components
import EmailSubSection from "./EmailSubSection";

// images
import smsImage from "../../../../assets/SMS_icon.png";
import vincereImage from "../../../../assets/vincere.svg";
import emailImage from "../../../../assets/email.svg";

// Icons
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { format, parseISO } from "date-fns";

interface WorkflowPropsDefinition {
  activityPanelData: any;
}

const WorkflowPerformance: React.FC<WorkflowPropsDefinition> = ({
  activityPanelData,
}) => {
  const navigate = useNavigate();

  //stored state
  const dispatch = useDispatch();
  const dateRange = useSelector(
    (state: any) => state.rangeSelection.activityMetricsRange
  );

  const [showDropdown, setShowDropDown] = useState(false);
  const [tags, setTags] = useState([] as any);

  let fromDate = computedStartDate(dateRange);
  let toDate = computedEndDate(dateRange);

  const metricSelection = useSelector(
    (state: any) => state.metricsSelection.activityMetricSelection
  );

  let emailEngagedUsers =
    (activityPanelData.email.total_clicks / activityPanelData.email.total_sent) * 100;
  let smsEngagesUsers = (activityPanelData.sms.clicks / activityPanelData.sms.sent) * 100;

  let candidatesUpdated =
    activityPanelData.sms.update_details + activityPanelData.email.total_updated_details;
  let updatedViaEmail = activityPanelData.email.total_updated_details;
  let updatedViaSMS = activityPanelData.sms.update_details;

  let candidateUnsubscribed =
    activityPanelData.sms.unsubscribed + activityPanelData.email.total_unsubscribed;
  let unsubscribedViaEmail = activityPanelData.email.total_unsubscribed;
  let unsubscribedViaSMS = activityPanelData.sms.unsubscribed;

  const openDropDownMenu = () => {
    setShowDropDown(!showDropdown);
  };

  const handleAnchorClick = (
    event: any,
    name: any,
    header: string,
    tagName: string,
    query: any
  ) => {
    let dateRange;
    if (metricSelection === "Custom") {
      dateRange = `${format(parseISO(fromDate as any), "y-MM-dd")} - ${format(
        parseISO(toDate as any),
        "y-MM-dd"
      )}`;
    } else {
      dateRange = metricSelection;
    }
    tags.push(tagName, dateRange);
    event.preventDefault();
    dispatch(setParameterName(name));
    dispatch(setQueryparameter(query));
    dispatch(setHeaderName(header));
    dispatch(setFilterTags(tags));
    navigate(`/workflows/ucd/candidate-drilldown?q=${query}`);
  };

  return (
    <div aria-label="workflow-performance">
      <h2
        className="text-lg text-black font-semibold mb-4 mt-10"
        aria-label="main-heading"
      >
        Workflow Performance
      </h2>
      <div className="border border-grey rounded-2xl">
        <div className="block py-4 bg-sidebar rounded-t-2xl px-6 border-b">
          <div className="grid grid-cols-7 gap-3">
            <div className="col-span-2">
              <h2 className="font-semibold text-sm">Event</h2>
            </div>
            <div className="col-span-1">
              <h2 className="font-semibold text-sm flex justify-start items-start">
                Tools
              </h2>
            </div>
            <div className="col-span-1">
              <h2 className="font-semibold text-sm flex justify-start items-start">
                Targets
              </h2>
            </div>
            <div className="col-span-3">
              <div className="grid grid-cols-3">
                <h2 className="font-semibold text-sm flex justify-start">
                  Results
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/** Candidates section */}
        <div className="activity-metrics-panel border-l-2 border-l-purple mb-3">
          <div
            className="block py-4 px-6 bg-white border-b"
            aria-label="candidates-section"
          >
            <div className="grid grid-cols-7 gap-3">
              <div className="col-span-2 md:col-span-2 flex py-2 flex-col justify-center">
                <h2 className="font-medium text-sm">Candidates</h2>
              </div>
              <div className="col-span-1">
                <h2 className="flex justify-start items-start">
                  <img
                    src={vincereImage}
                    alt="Vincere Logo"
                    className="lg:w-8 mt-1 2xl:w-9"
                  />
                </h2>
              </div>
              <div className="col-span-1">
                <div className="flex flex-col justify-start items-start mt-0 xl:mt-0">
                  {activityPanelData.summary.total_candidates > 0 ? (
                    <a
                      href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}`}
                      className="text-blue-500 font-medium text-sm cursor-pointer"
                      onClick={(event) =>
                        handleAnchorClick(
                          event,
                          "name",
                          "Candidates in the workflow",
                          activityPanelData.summary.total_candidates > 1
                            ? `${activityPanelData.summary.total_candidates} candidates`
                            : `${activityPanelData.summary.total_candidates} candidate`,
                          `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}`
                        )
                      }
                    >
                      {activityPanelData.summary.total_candidates}
                    </a>
                  ) : (
                    <p className="font-medium text-sm">{activityPanelData.summary.total_candidates}</p>
                  )}
                  <p className="text-xs font-medium">
                    {activityPanelData.summary.total_candidates === 0 ||
                    activityPanelData.summary.total_candidates > 1
                      ? "Candidates"
                      : "Candidate"}
                  </p>
                </div>
              </div>
              <div className="col-span-4 md:col-span-3">
                <div className="grid grid-cols-3">
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    {activityPanelData.summary.candidates_with_email > 0 ? (
                      <a
                        href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND data_quality.has_valid_email_address:true`}
                        className="text-blue-500 font-medium text-sm cursor-pointer"
                        onClick={(event) =>
                          handleAnchorClick(
                            event,
                            "name",
                            "Candidates with valid email",
                            activityPanelData.summary.candidates_with_email > 1
                              ? `${activityPanelData.summary.candidates_with_email} candidates`
                              : `${activityPanelData.summary.candidates_with_email} candidate`,
                            `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND data_quality.has_valid_email_address:true`
                          )
                        }
                      >
                        {activityPanelData.summary.candidates_with_email}
                      </a>
                    ) : (
                      <p className="font-medium text-sm">{activityPanelData.summary.candidates_with_email}</p>
                    )}

                    <p className="text-xs font-medium">Email</p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    {activityPanelData.summary.candidates_with_phone > 0 ? (
                      <a
                        href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND data_quality.has_valid_sms_address:true`}
                        className="text-blue-500 font-medium text-sm cursor-pointer"
                        onClick={(event) =>
                          handleAnchorClick(
                            event,
                            "name",
                            "Candidates with valid phone number",
                            activityPanelData.summary.candidates_with_phone > 1
                              ? `${activityPanelData.summary.candidates_with_phone} candidates`
                              : `${activityPanelData.summary.candidates_with_phone} candidate`,
                            `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND data_quality.has_valid_sms_address:true`
                          )
                        }
                      >
                        {activityPanelData.summary.candidates_with_phone}
                      </a>
                    ) : (
                      <p className="font-medium text-sm">{activityPanelData.summary.candidates_with_phone}</p>
                    )}

                    <p className="text-xs font-medium">Phone</p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    {activityPanelData.summary
                      .candidates_with_no_valid_contact_details > 0 ? (
                      <a
                        href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND NOT (data_quality.has_valid_sms_address:true OR data_quality.has_valid_email_address:true)`}
                        className="text-blue-500 font-medium text-sm cursor-pointer"
                        onClick={(event) =>
                          handleAnchorClick(
                            event,
                            "name",
                            "Candidates with no valid details",
                            activityPanelData.summary
                              .candidates_with_no_valid_contact_details > 1
                              ? `${activityPanelData.summary.candidates_with_no_valid_contact_details} candidates`
                              : `${activityPanelData.summary.candidates_with_no_valid_contact_details} candidate`,
                            `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND NOT (data_quality.has_valid_sms_address:true OR data_quality.has_valid_email_address:true)`
                          )
                        }
                      >
                        {
                          activityPanelData.summary
                            .candidates_with_no_valid_contact_details
                        }
                      </a>
                    ) : (
                      <p className="font-medium text-sm">
                        {
                          activityPanelData.summary
                            .candidates_with_no_valid_contact_details
                        }
                      </p>
                    )}
                    <p className="text-xs font-medium text-left">
                      Bounced Emails
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/** Email section */}
          <div
            className="block py-4 px-6 bg-white border-b"
            aria-label="email-section"
          >
            <div className="grid grid-cols-7 gap-3">
              <div className="col-span-2 md:col-span-2 flex">
                <div className="col-span-1 flex flex-col">
                  <div className="flex flex-row">
                    <h2 className="font-medium text-sm">Email</h2>
                    <button onClick={openDropDownMenu}>
                      {showDropdown ? (
                        <ChevronUpIcon className="w-6 h-6 ml-7" />
                      ) : (
                        <ChevronDownIcon className="w-6 h-6 ml-7" />
                      )}
                    </button>
                  </div>
                  <p className="text-xs font-medium mt-1">
                    {activityPanelData.email.total_sent} emails sent
                  </p>
                </div>
              </div>
              <div className="col-span-1">
                <h2 className="flex justify-start items-start">
                  <img src={emailImage} alt="Email Logo" className="lg:w-8 mt-1 2xl:w-9" />
                </h2>
              </div>
              <div className="col-span-1"></div>
              <div className="col-span-4 md:col-span-3">
                <div className="grid grid-cols-3">
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    {activityPanelData.email.total_clicks > 0 ? (
                      <a
                        href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_clicked OR __filled__:workflow_statistics.ucd.second_email.has_clicked OR __filled__:workflow_statistics.ucd.third_email.has_clicked OR __filled__:workflow_statistics.ucd.first_email.has_confirmed OR __filled__:workflow_statistics.ucd.second_email.has_confirmed OR __filled__:workflow_statistics.ucd.third_email.has_confirmed)`}
                        className="text-blue-500 font-medium text-sm cursor-pointer"
                        onClick={(event) =>
                          handleAnchorClick(
                            event,
                            "name",
                            "Candidates engaged with email",
                            activityPanelData.email.total_clicks > 1
                              ? `${activityPanelData.email.total_clicks} candidates`
                              : `${activityPanelData.email.total_clicks} candidate`,
                            `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_clicked OR __filled__:workflow_statistics.ucd.second_email.has_clicked OR __filled__:workflow_statistics.ucd.third_email.has_clicked OR __filled__:workflow_statistics.ucd.first_email.has_confirmed OR __filled__:workflow_statistics.ucd.second_email.has_confirmed OR __filled__:workflow_statistics.ucd.third_email.has_confirmed)`
                          )
                        }
                      >
                        {activityPanelData.email.total_clicks}
                      </a>
                    ) : (
                      <p className="font-medium text-sm">{activityPanelData.email.total_clicks}</p>
                    )}

                    <p className="text-xs font-medium">
                      {activityPanelData.email.total_clicks === 0 ||
                      activityPanelData.email.total_clicks > 1
                        ? "Clicks"
                        : "Click"}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    {activityPanelData.email.total_updated_details > 0 ? (
                      <a
                        href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_updated OR __filled__:workflow_statistics.ucd.second_email.has_updated OR __filled__:workflow_statistics.ucd.third_email.has_updated OR __filled__:workflow_statistics.ucd.first_email.has_confirmed OR __filled__:workflow_statistics.ucd.second_email.has_confirmed OR __filled__:workflow_statistics.ucd.third_email.has_confirmed)`}
                        className="text-blue-500 font-medium text-sm cursor-pointer"
                        onClick={(event) =>
                          handleAnchorClick(
                            event,
                            "name",
                            "Candidates updated by email",
                            activityPanelData.email.total_updated_details > 1
                              ? `${activityPanelData.email.total_updated_details} candidates`
                              : `${activityPanelData.email.total_updated_details} candidate`,
                            `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_updated OR __filled__:workflow_statistics.ucd.second_email.has_updated OR __filled__:workflow_statistics.ucd.third_email.has_updated OR __filled__:workflow_statistics.ucd.first_email.has_confirmed OR __filled__:workflow_statistics.ucd.second_email.has_confirmed OR __filled__:workflow_statistics.ucd.third_email.has_confirmed)`
                          )
                        }
                      >
                        {activityPanelData.email.total_updated_details}
                      </a>
                    ) : (
                      <p className="font-medium text-sm">{activityPanelData.email.total_updated_details}</p>
                    )}

                    <p className="text-xs font-medium text-left">
                      Update details
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-sm">
                      {!Number.isNaN(emailEngagedUsers)
                        ? Math.round(emailEngagedUsers) + "%"
                        : "0%"}
                    </p>
                    <p className="text-xs font-medium">
                      Engagement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/** Sub section */}
          {showDropdown ? (
            <EmailSubSection
              workflowData={activityPanelData}
              fromDate={fromDate}
              toDate={toDate}
            />
          ) : null}
          {/** SMS section */}
          <div
            className="block py-4 rounded-t-lg px-6 bg-white border-b"
            aria-label="sms-section"
          >
            <div className="grid grid-cols-7 gap-3">
              <div className="col-span-2 md:col-span-2 flex xl:mt-0">
                <div className="col-span-1 flex flex-col">
                  <div className="flex flex-row">
                    <h2 className="font-medium text-sm">SMS</h2>
                  </div>
                  <p className="text-xs font-medium mt-1">
                    {activityPanelData.sms.sent} SMS sent
                  </p>
                </div>
              </div>
              <div className="col-span-1">
                <h2 className="flex justify-start items-start">
                  <img src={smsImage} alt="SMS Logo" className="lg:w-10 mt-1 2xl:w-9" />
                </h2>
              </div>
              <div className="col-span-1">
                <div className="flex flex-col justify-start items-start">
                  {activityPanelData.sms.sent > 0 ? (
                    <a
                      href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND  __filled__:workflow_statistics.ucd.first_sms.was_sent`}
                      className="text-blue-500 font-medium text-sm cursor-pointer"
                      onClick={(event) =>
                        handleAnchorClick(
                          event,
                          "name",
                          "Candidates sent an SMS",
                          activityPanelData.sms.sent > 1
                            ? `${activityPanelData.sms.sent} candidates`
                            : `${activityPanelData.sms.sent} candidate`,
                          `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND  __filled__:workflow_statistics.ucd.first_sms.was_sent`
                        )
                      }
                    >
                      {activityPanelData.sms.sent}
                    </a>
                  ) : (
                    <p className="font-medium text-sm">{activityPanelData.sms.sent}</p>
                  )}

                  <p className="text-xs font-medium">
                    {activityPanelData.sms.sent === 0 || activityPanelData.sms.sent > 1
                      ? "Candidates"
                      : "Candidate"}
                  </p>
                </div>
              </div>
              <div className="col-span-4 md:col-span-3">
                <div className="grid grid-cols-3">
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    {activityPanelData.sms.clicks > 0 ? (
                      <a
                        href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}  AND (__filled__:workflow_statistics.ucd.first_sms.has_clicked OR __filled__:workflow_statistics.ucd.first_sms.has_confirmed)`}
                        className="text-blue-500 font-medium text-sm cursor-pointer"
                        onClick={(event) =>
                          handleAnchorClick(
                            event,
                            "name",
                            "Candidates engaged with SMS",
                            activityPanelData.sms.clicks > 1
                              ? `${activityPanelData.sms.clicks} candidates`
                              : `${activityPanelData.sms.clicks} candidate`,
                            `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}  AND (__filled__:workflow_statistics.ucd.first_sms.has_clicked OR __filled__:workflow_statistics.ucd.first_sms.has_confirmed)`
                          )
                        }
                      >
                        {activityPanelData.sms.clicks}
                      </a>
                    ) : (
                      <p className="font-medium text-sm">{activityPanelData.sms.clicks}</p>
                    )}

                    <p className="text-xs font-medium">
                      {activityPanelData.sms.clicks === 0 ||
                      activityPanelData.sms.clicks > 1
                        ? "Clicks"
                        : "Click"}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    {activityPanelData.sms.update_details > 0 ? (
                      <a
                        href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_sms.has_updated OR __filled__:workflow_statistics.ucd.first_sms.has_confirmed)`}
                        className="text-blue-500 font-medium text-sm cursor-pointer"
                        onClick={(event) =>
                          handleAnchorClick(
                            event,
                            "name",
                            "Candidates updated by SMS",
                            activityPanelData.sms.update_details > 1
                              ? `${activityPanelData.sms.update_details} candidates`
                              : `${activityPanelData.sms.update_details} candidate`,
                            `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_sms.has_updated OR __filled__:workflow_statistics.ucd.first_sms.has_confirmed)`
                          )
                        }
                      >
                        {activityPanelData.sms.update_details}
                      </a>
                    ) : (
                      <p className="font-medium text-sm">{activityPanelData.sms.update_details}</p>
                    )}

                    <p className="text-xs font-medium text-left">
                      Update details
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-sm">
                      {!Number.isNaN(smsEngagesUsers)
                        ? Math.round(smsEngagesUsers) + "%"
                        : "0%"}
                    </p>
                    <p className="text-xs font-medium">
                      Engagement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/** Updated Details section */}
          <div
            className="block py-4 rounded-t-lg px-6 bg-white border-b"
            aria-label="updated-details-section"
          >
            <div className="grid grid-cols-7 gap-3">
              <div className="col-span-2 md:col-span-2 flex py-2 flex-col justify-center">
                <h2 className="font-medium text-sm">Update details</h2>
              </div>
              <div className="col-span-1">
                <h2 className="font-bold text-md flex justify-start items-start">
                  <img
                    src={vincereImage}
                    alt="Vincere update details logo"
                    className="pt-2 lg:w-8 2xl:w-9"
                  />
                </h2>
              </div>
              <div className="col-span-1">
                <div className="flex flex-col justify-start items-start">
                  {candidatesUpdated > 0 ? (
                    <a
                      href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_updated OR __filled__:workflow_statistics.ucd.second_email.has_updated OR __filled__:workflow_statistics.ucd.third_email.has_updated OR __filled__:workflow_statistics.ucd.first_sms.has_updated OR __filled__:workflow_statistics.ucd.first_email.has_confirmed OR __filled__:workflow_statistics.ucd.second_email.has_confirmed OR __filled__:workflow_statistics.ucd.third_email.has_confirmed OR __filled__:workflow_statistics.ucd.first_sms.has_confirmed)`}
                      className="text-blue-500 font-medium text-sm cursor-pointer"
                      onClick={(event) =>
                        handleAnchorClick(
                          event,
                          "name",
                          "Candidates updated",
                          candidatesUpdated > 1
                            ? `${candidatesUpdated} candidates`
                            : `${candidatesUpdated} candidate`,
                          `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_updated OR __filled__:workflow_statistics.ucd.second_email.has_updated OR __filled__:workflow_statistics.ucd.third_email.has_updated OR __filled__:workflow_statistics.ucd.first_sms.has_updated OR __filled__:workflow_statistics.ucd.first_email.has_confirmed OR __filled__:workflow_statistics.ucd.second_email.has_confirmed OR __filled__:workflow_statistics.ucd.third_email.has_confirmed OR __filled__:workflow_statistics.ucd.first_sms.has_confirmed)`
                        )
                      }
                    >
                      {candidatesUpdated}
                    </a>
                  ) : (
                    <p className="font-medium text-sm">{candidatesUpdated}</p>
                  )}

                  <p className="text-xs font-medium">
                    {candidatesUpdated === 0 || candidatesUpdated > 1
                      ? "Candidates"
                      : "Candidate"}
                  </p>
                </div>
              </div>
              <div className="col-span-4 md:col-span-3">
                <div className="grid grid-cols-3">
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    {updatedViaEmail > 0 ? (
                      <a
                        href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_updated OR __filled__:workflow_statistics.ucd.second_email.has_updated OR __filled__:workflow_statistics.ucd.third_email.has_updated OR __filled__:workflow_statistics.ucd.first_email.has_confirmed OR __filled__:workflow_statistics.ucd.second_email.has_confirmed OR __filled__:workflow_statistics.ucd.third_email.has_confirmed)`}
                        className="text-blue-500 font-medium text-sm cursor-pointer"
                        onClick={(event) =>
                          handleAnchorClick(
                            event,
                            "name",
                            "Candidates updated by email",
                            updatedViaEmail > 1
                              ? `${updatedViaEmail} candidates`
                              : `${updatedViaEmail} candidate`,
                            `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_updated OR __filled__:workflow_statistics.ucd.second_email.has_updated OR __filled__:workflow_statistics.ucd.third_email.has_updated OR __filled__:workflow_statistics.ucd.first_email.has_confirmed OR __filled__:workflow_statistics.ucd.second_email.has_confirmed OR __filled__:workflow_statistics.ucd.third_email.has_confirmed)`
                          )
                        }
                      >
                        {updatedViaEmail}
                      </a>
                    ) : (
                      <p className="font-medium text-sm">{updatedViaEmail}</p>
                    )}

                    <p className="text-xs font-medium">
                      Via email
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    {updatedViaSMS > 0 ? (
                      <a
                        href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}  AND (__filled__:workflow_statistics.ucd.first_sms.has_updated OR __filled__:workflow_statistics.ucd.first_sms.has_confirmed)`}
                        className="text-blue-500 font-medium text-sm cursor-pointer"
                        onClick={(event) =>
                          handleAnchorClick(
                            event,
                            "name",
                            "Candidates updated by SMS",
                            updatedViaSMS > 1
                              ? `${updatedViaSMS} candidates`
                              : `${updatedViaSMS} candidate`,
                            `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}  AND (__filled__:workflow_statistics.ucd.first_sms.has_updated OR __filled__:workflow_statistics.ucd.first_sms.has_confirmed)`
                          )
                        }
                      >
                        {updatedViaSMS}
                      </a>
                    ) : (
                      <p className="font-medium text-sm">{updatedViaSMS}</p>
                    )}

                    <p className="text-xs font-medium">Via SMS</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/** Unsubscribed section */}
          <div
            className="block py-3 rounded-t-lg px-6 bg-white"
            aria-label="unsubscribed-section"
          >
            <div className="grid grid-cols-7 gap-3">
              <div className="col-span-2 md:col-span-2 flex py-2 flex-col justify-center">
                <h2 className="font-medium text-sm">Unsubscribed</h2>
              </div>
              <div className="col-span-1">
                <h2 className="flex justify-start items-start">
                  <img
                    src={vincereImage}
                    alt="Vincere update details logo"
                    className="lg:w-8 pt-2 2xl:w-9"
                  />
                </h2>
              </div>
              <div className="col-span-1">
                <div className="flex flex-col justify-start items-start">
                  {candidateUnsubscribed > 0 ? (
                    <a
                      href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_unsubscribed OR __filled__:workflow_statistics.ucd.second_email.has_unsubscribed OR __filled__:workflow_statistics.ucd.third_email.has_unsubscribed OR __filled__:workflow_statistics.ucd.first_sms.has_unsubscribed)`}
                      className="text-blue-500 font-medium text-sm cursor-pointer"
                      onClick={(event) =>
                        handleAnchorClick(
                          event,
                          "name",
                          "Candidates unsubscribed",
                          candidateUnsubscribed > 1
                            ? `${candidateUnsubscribed} candidates`
                            : `${candidateUnsubscribed} candidate`,
                          `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_unsubscribed OR __filled__:workflow_statistics.ucd.second_email.has_unsubscribed OR __filled__:workflow_statistics.ucd.third_email.has_unsubscribed OR __filled__:workflow_statistics.ucd.first_sms.has_unsubscribed)`
                        )
                      }
                    >
                      {candidateUnsubscribed}
                    </a>
                  ) : (
                    <p className="font-medium text-sm">{candidateUnsubscribed}</p>
                  )}

                  <p className="text-xs font-medium">
                    {candidateUnsubscribed === 0 || candidateUnsubscribed > 1
                      ? "Candidates"
                      : "Candidate"}
                  </p>
                </div>
              </div>
              <div className="col-span-4 md:col-span-3">
                <div className="grid grid-cols-3">
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    {unsubscribedViaEmail > 0 ? (
                      <a
                        href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_unsubscribed OR __filled__:workflow_statistics.ucd.second_email.has_unsubscribed OR __filled__:workflow_statistics.ucd.third_email.has_unsubscribed)`}
                        className="text-blue-500 font-medium text-sm cursor-pointer"
                        onClick={(event) =>
                          handleAnchorClick(
                            event,
                            "name",
                            "Candidates unsubscribed by email",
                            unsubscribedViaEmail > 1
                              ? `${unsubscribedViaEmail} candidates`
                              : `${unsubscribedViaEmail} candidate`,
                            `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (__filled__:workflow_statistics.ucd.first_email.has_unsubscribed OR __filled__:workflow_statistics.ucd.second_email.has_unsubscribed OR __filled__:workflow_statistics.ucd.third_email.has_unsubscribed)`
                          )
                        }
                      >
                        {unsubscribedViaEmail}
                      </a>
                    ) : (
                      <p className="font-medium text-sm">{unsubscribedViaEmail}</p>
                    )}

                    <p className="text-xs font-medium">
                      Via email
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    {unsubscribedViaSMS > 0 ? (
                      <a
                        href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND __filled__:workflow_statistics.ucd.first_sms.has_unsubscribed`}
                        className="text-blue-500 font-medium text-sm cursor-pointer"
                        onClick={(event) =>
                          handleAnchorClick(
                            event,
                            "name",
                            "Candidates unsubscribed by SMS",
                            unsubscribedViaSMS > 1
                              ? `${unsubscribedViaSMS} candidates`
                              : `${unsubscribedViaSMS} candidate`,
                            `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND __filled__:workflow_statistics.ucd.first_sms.has_unsubscribed`
                          )
                        }
                      >
                        {unsubscribedViaSMS}
                      </a>
                    ) : (
                      <p className="font-medium text-sm">{unsubscribedViaSMS}</p>
                    )}

                    <p className="text-xs font-medium">Via SMS</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowPerformance;
