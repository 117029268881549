import { DateRange } from "react-day-picker";

// images
import smsImage from "../../../../assets/sms.svg";
import vincereImage from "../../../../assets/vincere.svg";
import emailImage from "../../../../assets/email.svg";

interface WorkflowPerformanceMobilePropsDefinition {
  activityPanelData: any;
}

const WorkflowPerformanceMobile: React.FC<
  WorkflowPerformanceMobilePropsDefinition
> = ({ activityPanelData }) => {

  let emailEngagedUsers =
    (activityPanelData.email.total_clicks / activityPanelData.email.total_sent) * 100;
  let smsEngagesUsers = (activityPanelData.sms.clicks / activityPanelData.sms.sent) * 100;

  let candidatesUpdated =
    activityPanelData.sms.update_details + activityPanelData.email.total_updated_details;
  let updatedViaEmail = activityPanelData.email.total_updated_details;
  let updatedViaSMS = activityPanelData.sms.update_details;

  let candidateUnsubscribed =
    activityPanelData.sms.unsubscribed + activityPanelData.email.total_unsubscribed;
  let unsubscribedViaEmail = activityPanelData.email.total_unsubscribed;
  let unsubscribedViaSMS = activityPanelData.sms.unsubscribed;

  return (
    <div aria-label="workflow-performance-mobile" className="w-[375px]">
      <h2
        className="text-xl text-black font-semibold mb-8 mt-10"
        aria-label="main-heading"
      >
        Workflow performance
      </h2>
      {/** Candidates section */}
      <div className="border border-grey rounded-t-xl">
        <div className="block py-5 bg-sidebar rounded-t-lg px-6 border-b">
          <h2 className="text-lg font-medium pl-3">Candidates</h2>
        </div>
        <div className="flex flex-row border-l-2 border-purple">
          <div className="heading-section border-r border-r-grey py-5 px-6 pr-16">
            <div className="flex flex-col">
              <p className="font-semibold text-sm mt-2 mb-3 pl-2">Tools</p>
              <p className="font-semibold text-sm mb-3 pl-2">Targets</p>
              <p className="font-semibold text-sm mb-4 pl-2">Results</p>
            </div>
          </div>
          <div className="content-section py-5 px-7">
            <div className="flex flex-col">
              <img
                src={vincereImage}
                alt="Vincere Logo"
                className="w-1/4 mb-2"
              />
              <div className="flex flex-row mb-3">
                <p className="font-semibold textsm">
                  {activityPanelData.summary.total_candidates}
                </p>
                <p className="text-sm font-normal pl-2">
                  {activityPanelData.summary.total_candidates === 0 ||
                  activityPanelData.summary.total_candidates > 1
                    ? "Candidates"
                    : "Candidate"}
                </p>
              </div>
              <div className="flex flex-col mb-2">
                <div className="flex flex-row mb-2">
                  <p className="font-semibold text-sm">
                    {activityPanelData.summary.candidates_with_email}
                  </p>
                  <p className="text-sm font-normal pl-2">Email</p>
                </div>
                <div className="flex flex-row mb-2">
                  <p className="font-semibold text-sm">
                    {activityPanelData.summary.candidates_with_phone}
                  </p>
                  <p className="text-sm font-normal pl-2">Phone</p>
                </div>
                <div className="flex flex-row">
                  <p className="font-semibold text-sm">
                    {
                      activityPanelData.summary
                        .candidates_with_no_valid_contact_details
                    }
                  </p>
                  <p className="text-sm font-normal pl-2">No Valid Details</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Email section */}
      <div className="border border-grey border-t-0">
        <div className="flex flex-row justify-between bg-sidebar border-b">
          <div className="block py-5 px-6">
            <h2 className="text-lg font-medium pl-3">Email</h2>
          </div>
          <p className="block py-4 px-6 text-sm font-medium">
            {" "}
            {activityPanelData.email.total_sent > 1
              ? `${activityPanelData.email.total_sent} emails sent`
              : `${activityPanelData.email.total_sent} email sent`}
          </p>
        </div>
        <div className="flex flex-row border-l-2 border-purple">
          <div className="heading-section border-r border-r-grey py-5 px-6 pr-16">
            <div className="flex flex-col">
              <p className="font-semibold text-sm mt-2 mb-3 pl-2">Tools</p>
              <p className="font-semibold text-sm mb-3 pl-2">Targets</p>
              <p className="font-semibold text-sm mb-4 pl-2">Results</p>
            </div>
          </div>
          <div className="content-section py-3 px-7">
            <div className="flex flex-col">
              <img
                src={emailImage}
                alt="Email Logo"
                className="w-1/5 mb-2 mt-4"
              />
              <div className="flex flex-row mb-3 mt-1">
                <p className="font-semibold text-sm">
                  {activityPanelData.email.total_clicks}
                </p>
                <p className="text-sm font-normal pl-2">
                  {activityPanelData.email.total_clicks === 0 ||
                  activityPanelData.email.total_clicks > 1
                    ? "Clicks"
                    : "Click"}
                </p>
              </div>
              <div className="flex flex-col mb-2">
                <div className="flex flex-row mb-2">
                  <p className="font-semibold text-sm">
                    {activityPanelData.email.total_updated_details}
                  </p>
                  <p className="text-sm font-normal pl-2">Update Details</p>
                </div>
                <div className="flex flex-row mb-2">
                  <p className="font-semibold text-sm">
                    {!Number.isNaN(emailEngagedUsers)
                      ? Math.round(emailEngagedUsers) + "%"
                      : "0%"}
                  </p>
                  <p className="text-sm font-normal pl-2">Engagement</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** SMS section */}
      <div className="border border-grey border-t-0">
        <div className="flex flex-row justify-between bg-sidebar border-b">
          <div className="block py-5 px-6">
            <h2 className="font-medium text-sm pl-3">SMS</h2>
          </div>
          <p className="block py-4 px-6 text-md font-normal">
            {" "}
            {activityPanelData.sms.sent} SMS sent{" "}
          </p>
        </div>
        <div className="flex flex-row border-l-2 border-purple">
          <div className="heading-section border-r border-r-grey py-5 px-6 pr-16">
            <div className="flex flex-col">
              <p className="font-semibold text-sm mt-2 mb-3 pl-2">Tools</p>
              <p className="font-semibold text-sm mb-3 pl-2">Targets</p>
              <p className="font-semibold text-sm mb-4 pl-2">Results</p>
            </div>
          </div>
          <div className="content-section py-3 px-7">
            <div className="flex flex-col">
              <img src={smsImage} alt="SMS Logo" className="w-1/5 mb-2 mt-4" />
              <div className="flex flex-row mb-2 mt-1">
                {/** Value will change later */}
                <p className="font-semibold text-sm">{activityPanelData.sms.sent}</p>
                <p className="text-sm font-normal pl-2">
                  {activityPanelData.sms.sent === 0 || activityPanelData.sms.sent > 1
                    ? "Candidates"
                    : "Candidate"}
                </p>
              </div>
              <div className="flex flex-col mb-3">
                <div className="flex flex-row mb-2">
                  <p className="font-semibold text-sm">{activityPanelData.sms.clicks}</p>
                  <p className="text-sm font-normal pl-2">
                    {activityPanelData.sms.clicks === 0 ||
                    activityPanelData.sms.clicks > 1
                      ? "Clicks"
                      : "Click"}
                  </p>
                </div>
                <div className="flex flex-row mb-2">
                  <p className="font-semibold text-sm">
                    {activityPanelData.sms.update_details}
                  </p>
                  <p className="text-sm font-normal pl-2">Update Details</p>
                </div>
                <div className="flex flex-row">
                  <p className="font-semibold text-sm">
                    {!Number.isNaN(smsEngagesUsers)
                      ? Math.round(smsEngagesUsers) + "%"
                      : "0%"}
                  </p>
                  <p className="text-sm font-normal pl-2">Engagement</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Update Details section */}
      <div className="border border-grey border-t-0">
        <div className="block py-5 bg-sidebar px-6 border-b">
          <h2 className="text-lg font-medium pl-3">Update Details</h2>
        </div>
        <div className="flex flex-row border-l-2 border-purple">
          <div className="heading-section border-r border-r-grey py-5 px-6 pr-16">
            <div className="flex flex-col">
              <p className="font-semibold text-sm mt-2 mb-3 pl-2">Tools</p>
              <p className="font-semibold text-sm mb-3 pl-2">Targets</p>
              <p className="font-semibold text-sm mb-4 pl-2">Results</p>
            </div>
          </div>
          <div className="content-section py-3 px-7">
            <div className="flex flex-col">
              <img
                src={vincereImage}
                alt="Vincere Logo"
                className="w-1/4 mb-3 mt-3"
              />
              <div className="flex flex-row mb-3">
                <p className="font-semibold text-sm">{candidatesUpdated}</p>
                <p className="text-sm font-normal pl-2">
                  {candidatesUpdated === 0 || candidatesUpdated > 1
                    ? "Candidates"
                    : "Candidate"}
                </p>
              </div>
              <div className="flex flex-col mb-3">
                <div className="flex flex-row mb-2">
                  <p className="font-semibold text-sm">{updatedViaEmail}</p>
                  <p className="text-sm font-normal pl-2">Via Email</p>
                </div>
                <div className="flex flex-row">
                  <p className="font-semibold text-sm">{updatedViaSMS}</p>
                  <p className="text-sm font-normal pl-2">Via SMS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Unsubscribed section */}
      <div className="border border-grey border-t-0">
        <div className="block py-5 bg-sidebar px-6">
          <h2 className="text-lg font-medium pl-3">Unsubscribed</h2>
        </div>
        <div className="flex flex-row border-l-2 border-purple">
          <div className="heading-section border-r border-r-grey py-5 px-6 pr-16">
            <div className="flex flex-col">
              <p className="font-semibold text-sm mt-2 mb-3 pl-2">Tools</p>
              <p className="font-semibold text-sm mb-3 pl-2">Targets</p>
              <p className="font-semibold text-sm mb-4 pl-2">Results</p>
            </div>
          </div>
          <div className="content-section py-3 px-7">
            <div className="flex flex-col">
              <img
                src={vincereImage}
                alt="Vincere Logo"
                className="w-1/4 mb-2 mt-4"
              />
              <div className="flex flex-row mb-3">
                <p className="font-semibold text-sm">{candidateUnsubscribed}</p>
                <p className="text-sm font-normal pl-2">
                  {candidateUnsubscribed === 0 || candidateUnsubscribed > 1
                    ? "Candidates"
                    : "Candidate"}
                </p>
              </div>
              <div className="flex flex-col mb-2">
                <div className="flex flex-row mb-2">
                  <p className="font-semibold text-sm">{unsubscribedViaEmail}</p>
                  <p className="text-sm font-normal pl-2">Via Email</p>
                </div>
                <div className="flex flex-row mb-2">
                  <p className="font-semibold text-sm">{unsubscribedViaSMS}</p>
                  <p className="text-sm font-normal pl-2">Via SMS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowPerformanceMobile;
