import * as React from "react";
import { Dispatch, SetStateAction, useMemo, useRef, useEffect } from "react";
import ModalTable from "./ModalTable";
import { ClickableButton } from "@autometry/ui-components";

import actionIcon from "../assets/action-icon.svg";
import { useNavigate } from "react-router-dom";

interface RecruiterModalProps {
  rowData: any;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: any;
}

const RecruiterModal: React.FC<RecruiterModalProps> = ({ rowData, setShowModal, showModal }) => {
  const navigate = useNavigate();
  const ref = useRef(null as any);

  const storageCol = [
    {
      Header: "Candidate Name",
      accessor: "name",
      width: "20%",
    },
    {
      Header: "Job Title",
      accessor: "job_title",
      width: "20%",
      Cell: (row: any) => {
        return <div className="text-left">{row.value}</div>
      }
    },
    {
      Header: "Job Type",
      accessor: "job_type",
      width: "20%",
      Cell: (row: any) => {
        return <div className="text-left">{row.value}</div>
      }
    },
    {
      Header: "Current Employer",
      accessor: "current_employer",
      width: "20%",
      Cell: (row: any) => {
        return <div className="text-left">{row.value}</div>
      }
    },
    {
      Header: "Viewed",
      accessor: "viewed",
      width: "20%",
      Cell: (row: any) => {
        if (row.value === false) {
          return "No"
        } else {
          return "Yes"
        }
      }
    },
    {
      Header: "Action",
      accessor: "ats_link",
      width: "20%",
      Cell: (row: any) => {
        return (
          <>
            <div className="group relative">
              <ClickableButton
                onClick={()=> window.open(row.value, "_blank")}
                buttonProps={{
                  color: "transparent",
                  size: "small",
                  dynamicClassProperty:
                    "rounded-2xl border-successText hover:bg-success !py-2",
                }}
              >
                <img src={actionIcon} alt="action-icon" />
              </ClickableButton>
  
              <span className="!absolute -top-6 -left-36 scale-0 danger-arrow-right bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                View Candidate
              </span>
            </div>
          </>
        );
      },
    },
  ];
  
  const storageColumns = useMemo(() => storageCol, [storageCol]);
  
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showModal && ref.current && !ref.current.contains(e.target)) {
        setShowModal(!showModal);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal]);

  return (
    <div className="hidden lg:justify-center lg:items-center lg:flex lg:overflow-x-hidden lg:overflow-y-auto lg:fixed lg:inset-0 lg:z-[130] lg:outline-none lg:focus:outline-none " aria-label="recruiter-modal">
      <div className={rowData.length > 6 ? "relative w-auto my-6 mx-auto max-w-3xl lg:h-[500px] 2xl:h-[800px]": "relative w-auto my-6 mx-auto max-w-3xl"} ref={ref as any}>
        {/*content*/}
        <div className={rowData.length > 6 ? "border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none lg:h-[500px] 2xl:h-[800px] overflow-auto" : "border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"}>
          <div className="relative p-6 flex-auto">
          <ModalTable
            columnDef={storageColumns as any}
            dataArray={rowData}
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterModal;
