import React, {
    ChangeEventHandler,
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
  } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { format, startOfMonth, subMonths } from "date-fns";
  import FocusTrap from "focus-trap-react";
  import 'react-day-picker/dist/style.css';
  import {
    DateRange,
    DayPicker,
    SelectRangeEventHandler,
  } from "react-day-picker";
  import { usePopper } from "react-popper";
  import { setActivityMetricsRangeSelection }  from "../../../../reducers/date-selection.reducer";
  import { setActivityMetricsRange }  from "../../../../reducers/common-reducer";

  const css = `
    .my-today { 
      font-weight: normal;
      font-size: 140%; 
      color: black;
      background-color: #98E79B;
    }
    .my-selected:not([disabled]) { 
      font-weight: bold; 
      color: black;
      background-color: #F9F9F9;
    }
    .my-selected:hover:not([disabled]) { 
      border-color: black;
      color: black;
    }
  `;
  
  interface PropsDefinition {
    setSelectedRange: Dispatch<SetStateAction<DateRange | undefined>>;
    selectedRange: DateRange | any;
    pathName: string;
  }
  
  export default function ActiviyMetricsDatePicker({
    setSelectedRange,
    selectedRange,
    pathName
  }: PropsDefinition) {
      //stored state
    const dispatch = useDispatch();
    const dateRange = useSelector((state: any) => state.rangeSelection.activityMetricsRange);

    const [fromValue, setFromValue] = useState<string>("");
    const [toValue, setToValue] = useState<string>("");
    const [isPopperOpen, setIsPopperOpen] = useState(false);
  
    const popperRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
      null
    );
  
    useEffect(() => {
      dispatch(setActivityMetricsRangeSelection(dateRange));
    }, [isPopperOpen])
  
    const popper = usePopper(popperRef.current, popperElement, {
      placement: "bottom",
    });
  
    const closePopper = () => {
      setIsPopperOpen(false);
      buttonRef?.current?.focus();
    };
  
    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      setIsPopperOpen(true);
    };
  
    const handleRangeSelect: SelectRangeEventHandler = (
      range: DateRange | undefined
    ) => {
      if (range !== undefined) {
        setSelectedRange(range);
        dispatch(setActivityMetricsRangeSelection(dateRange));
        if (range?.from && range?.to) {
          setFromValue(format(range.from, "y-MM-dd"));
          setToValue(format(range.to, "y-MM-dd"));
          dispatch(setActivityMetricsRangeSelection(range));
          dispatch(setActivityMetricsRange(true));
          closePopper();
        } else {
          setFromValue("");
          setToValue("");
        }
      } else {
        setSelectedRange({ from: undefined, to: undefined });
        dispatch(setActivityMetricsRangeSelection(dateRange));
        dispatch(setActivityMetricsRange(false));
        setFromValue("");
        setToValue("");
      }
    };
  
    useEffect(() => {
      if(selectedRange.from !== undefined && selectedRange.to !== undefined) {
        setFromValue(format(selectedRange.from, "y-MM-dd"));
        setToValue(format(selectedRange.to, "y-MM-dd"));
      }
    }, [selectedRange])
  
    return (
      <div className={pathName?.match("/workflows/") ? "flex justify-end mt-4 mb-3" : "flex justify-end pr-16 mt-4 mb-3"} aria-label="date-picker">
        <div ref={popperRef}>
          <form className="ma2">
            <input
              placeholder="From Date"
              value={fromValue}
              onChange={handleInputChange}
              onClick={() => setIsPopperOpen(true)}
              className="focus:border-black focus:outline-none focus:ring-transparent rounded-lg border border-grey md:text-sm md:py-2 md:px-2.5 md:w-[130px] w-[130px] xl:w-auto"
            />
            {" – "}
            <input
              placeholder="To Date"
              value={toValue}
              onChange={handleInputChange}
              onClick={() => setIsPopperOpen(true)}
              className="focus:border-black focus:outline-none focus:ring-transparent rounded-lg border border-grey md:text-sm md:py-2 md:px-2.5 md:w-[130px] w-[130px] xl:w-auto"
            />
          </form>
        </div>
        {isPopperOpen && (
          <FocusTrap
            active
            focusTrapOptions={{
              initialFocus: false,
              allowOutsideClick: true,
              clickOutsideDeactivates: true,
              onDeactivate: closePopper,
              fallbackFocus: buttonRef.current as any,
            }}
          >
            <div
              tabIndex={-1}
              style={{
                ...popper.styles.popper,
                zIndex: 10000,
                border: "1px solid grey",
                background: "#fff",
                left: 48,
                inset: "1px auto auto 23px",
              }}
              className="dialog-sheet"
              {...popper.attributes.popper}
              ref={setPopperElement}
              role="dialog"
              aria-label="DayPicker calendar"
            >
              <style>{css}</style>
              <DayPicker
                mode="range"
                captionLayout="dropdown-buttons"
                toDate={new Date()}
                defaultMonth={selectedRange.from}
                selected={selectedRange}
                onSelect={handleRangeSelect}
                fromMonth={startOfMonth(subMonths(new Date(), 12))}
                modifiersClassNames={{
                  today: "my-today",
                  selected: "my-selected",
                }}
              />
            </div>
          </FocusTrap>
        )}
      </div>
    );
  }
  