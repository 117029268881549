import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/react-query/constants"
import { fetchRevenue } from "../services/revenueDetails";
import { CandidateRequest } from "../types/candidate"

export const RevenueInfo = (param: CandidateRequest) => {
  const notEmptyPage = param !== undefined
	const queryResult = useQuery([queryKeys.storageQuery, param], () => fetchRevenue(param), {
		enabled: notEmptyPage,
        refetchOnWindowFocus: false,
	})
  return queryResult
}
