import vincereImage from "../../assets/Vincere_icon.png";
import emailImage from "../../assets/Email_icon.png";
import smsImage from "../../assets/SMS_icon.png";
import adaIcon from "../../assets/Ada_icon.png";
import { format, parseISO } from "date-fns";

interface WorkflowPropsDefinition {
  name: any;
  description: any;
  status: any;
  frequency: any;
  activityStatus: any;
  id: string;
}

const WorkflowData: React.FC<WorkflowPropsDefinition> = ({
  id,
  name,
  description,
  status,
  frequency,
  activityStatus,
}) => {
  return (
    <div
      className={
        id === "job-coverage"
          ? "flex flex-col xl:mt-6 mt-20 relative min-h-[182px] 2xl:min-h-[185px]"
          : "flex flex-col lg:mt-6 relative md:min-h-[200px] md:h-[200px] xl:min-h-[160px] xl:h-[195px] 2xl:min-h-[162px] 2xl:h-[162px]"
      }
      aria-label={`${id}-workflow-panel`}
    >
      <h2 className="text-2xl font-semibold mb-1 md:w-10/12 xl:w-auto">{name}</h2>
      <p className="mb-5 text-xs font-medium mt-4 md:w-[335px] lg:w-10/12 xl:w-auto 2xl:max-w-screen-sm 3xl:max-w-max">{description}</p>
      <div className="w-full">
        <div className="flex flex-row justify-between justify-content-start md:mt-[5px] xl:mt-14 2xl:mt-3 3xl:mt-6">
          <div className="flex flex-row justify-start mt-1 w-[400px] md:w-auto flex-wrap md:flex-nowrap">
            <div className="flex mr-10 md:mr-5 lg:mr-8 2xl:mr-16" aria-label="status">
              <div className="flex flex-col">
                <h5 className="text-xs">Status</h5>
                <p className="font-medium text-black text-sm text-green">
                  {status}
                </p>
              </div>
            </div>
            <div className="flex mr-10 md:mr-5 lg:mr-8 2xl:mr-16" aria-label="frequency">
              <div className="flex flex-col">
                <h5 className="text-xs">Frequency</h5>
                <p className="font-medium text-black text-sm">{frequency}</p>
              </div>
            </div>
            <div
              className="flex mr-10 md:mr-5 lg:mr-8 2xl:mr-16"
              aria-label="activity-info"
            >
              <div className="flex flex-col">
                <h5 className="text-xs">Latest Activity</h5>
                <p className="font-medium text-black text-sm">
                  {id === "job-coverage"
                    ? activityStatus
                    : activityStatus !== ""
                    ? format(parseISO(activityStatus), "MMM,dd y")
                    : " - "}
                </p>
              </div>
            </div>
            <div className="flex lg:ml-1 2xl:ml-10 mt-5 md:mt-0">
              <div className="flex flex-row justify-end">
                <img
                  src={vincereImage}
                  alt="Vincere Logo"
                  className="p-1.5 w-[40px] h-[40px] md:w-10 md:h-10 lg:w-10 lg:h-10 2xl:w-[45px] 2xl:h-[45px] border-l border-b border-t rounded-l-lg"
                />
                <img
                  src={emailImage}
                  alt="Email Logo"
                  className="p-1.5 w-[40px] h-[40px] md:w-10 md:h-10 lg:w-10 lg:h-10 2xl:w-[45px] 2xl:h-[45px] border-b border-t pt-[9.3px]"
                />
                <img
                  src={smsImage}
                  alt="SMS Logo"
                  className={
                    id === "job-coverage"
                      ? "p-1.5 w-[40px] h-[40px] md:w-10 md:h-10 lg:w-10 lg:h-10 2xl:w-[45px] 2xl:h-[45px] border-b border-t pt-[9.3px]"
                      : "p-1.5 w-[40px] h-[40px] md:w-10 md:h-10 lg:w-10 lg:h-10 2xl:w-[45px] 2xl:h-[45px] border-r border-b border-t rounded-r-lg"
                  }
                />
                {id === "job-coverage" && (
                  <img
                    src={adaIcon}
                    alt="Ada Icon"
                    className="p-1.5 w-[40px] h-[40px] md:w-10 md:h-10 lg:w-10 lg:h-10 2xl:w-[45px] 2xl:h-[45px] border-r border-b border-t rounded-r-lg"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowData;
