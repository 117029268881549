import { useEffect, useState } from "react";
import { candidatesSentDetails } from "../../services/landingPageDetails";

// components
import {
  InputField,
  Card,
  ProgressBar,
  DividerField,
} from "@autometry/ui-components";

import LandingPageLoader from "../../Loading/LandingPageLoader";
import { useSelector } from "react-redux";
import { DateRange } from "react-day-picker";
import {
  computedEndDate,
  computedStartDate,
  formattedStartDate,
} from "../../../helpers/date.helpers";
import { addDays } from "date-fns";

const CandidateSent = () => {
  const [isLoading, setLoading] = useState(true);
  const [candidatesSent, setCandidatesSent] = useState(undefined as any);

  // redux state
  const jobType = useSelector((state: any) => state.jobType.jobSelection);
  const monthType = useSelector((state: any) => state.monthType.monthSelection);
  const range = useSelector((state: any) => state.rangeSelection.selectedRange);
  const dateSelected = useSelector(
    (state: any) => state.selection.dateSelected
  );

  useEffect(() => {
    setLoading(false);
    if (monthType === "custom") {
      if (dateSelected) setLoading(true);
      else setLoading(false);
    } else {
      setLoading(true);
    }

    let currentDate = new Date();

    // fetching values
    let fromDate, toDate;
    if (monthType === "current_month") {
      fromDate = formattedStartDate(currentDate);
      toDate = computedEndDate(currentDate);
    } else {
      fromDate = computedStartDate(range);
      toDate = computedEndDate(range);
    }

    // candidates send details
    if (fromDate !== undefined && toDate !== undefined) {
      candidatesSentDetails("candidates_sent", fromDate, toDate, jobType)
        .then((data) => {
          if (data) {
            setCandidatesSent(data);
            setLoading(false);
          } else {
            console.log("no data found");
          }
        })
        .catch(function (error) {
          console.log("error : ", error);
        });
    }
  }, [jobType, range, monthType, dateSelected]);

  if (isLoading) {
    return <LandingPageLoader showLoader={true} />;
  }

  return (
    <>
      {candidatesSent !== undefined ? (
        <Card
          dataTestId="candidates-sent"
          header={{
            dataTestId: "candidates-sent",
            color: "purple",
            title: "Candidates sent",
            dynamicClassProperty: "!font-medium text-lg",
          }}
        >
          <div className="flex lg:flex-col xl:flex-row xl:justify-between xl:justify-items-start sm:flex-row sm:justify-between sm:justify-items-start">
            <div className="block mt-4 lg:mt-4 xl:mt-4 w-full xl:w-7/12 2xl:w-[310px] 3xl:w-[350px]">
              {/** screen resolution 1024px and portrait mode devices*/}
              <div className="md:hidden lg:flex md:hidden lg:flex-row flex flex-row xl:hidden justify-between justify-items-start">
                <div className="py-0 block">
                  <InputField
                    dataTestId="total-candidates-added"
                    width="auto"
                    dynamicClassProperty="!py-4"
                  >
                    <div className="flex flex-col">
                      <h3 className="font-medium text-black flex items-center text-2xl">
                        {candidatesSent.total}
                      </h3>
                      <p className="font-medium text-black text-sm">
                        Total <br /> candidates
                      </p>
                    </div>
                  </InputField>
                </div>
                <div className="flex flex-col justify-center w-32">
                  <ProgressBar
                    dataTestId="candidates-sent"
                    color="purple"
                    childValue={candidatesSent.percentages.inner}
                    parentValue={candidatesSent.percentages.outer}
                    dynamicClassProperty={"flex"}
                  />
                  <div className="mt-1 text-center">
                    <p className="font-medium text-sm">
                      {candidatesSent.percentages.outer}%
                    </p>
                  </div>
                </div>
              </div>
              <div className="hidden lg:hidden xl:w-fit xl:block md:w-fit md:block">
                <InputField dataTestId="total-candidates-added" width={"full"}>
                  <div className="col-span-1 flex justify-start">
                    <h3 className="font-medium text-black flex items-center text-2xl">
                      {candidatesSent.total}
                    </h3>
                  </div>
                  <div className="col-span-3 flex justify-end p-2">
                    <p className="font-medium text-black pl-2 text-xs">
                      Total candidates
                    </p>
                  </div>
                </InputField>
              </div>
              <div className="lg:mt-4 xl:mt-7 md:mt-7 mt-5">
                <div className="w-auto grid grid-cols-2 gap-4 content-start md:flex md:justify-between md:justify-content-start md:w-72 lg:w-auto lg:grid lg:grid-cols-2 lg:gap-4 lg:content-start mb-8">
                  <DividerField
                    dataTestId="candidates-floated"
                    color="black"
                    dynamicClassProperty="lg:h-12 xl:h-14 2xl:h-10"
                  >
                    <p
                      className="font-medium text-black pb-4 break-words overflow-hidden text-xs"
                      role="paragraph"
                    >
                      Candidates floated
                    </p>
                    <h3 className="font-medium text-black text-base">
                      {candidatesSent.metrics.floated > 0
                        ? candidatesSent.metrics.floated
                        : 0}
                    </h3>
                  </DividerField>
                </div>
                <div className="w-auto grid grid-cols-2 gap-4 content-start md:flex md:justify-between md:justify-content-start md:w-[260px] lg:w-auto lg:grid lg:grid-cols-2 lg:gap-4 lg:content-start">
                  <DividerField
                    dataTestId="sent-to-one-plus-jobs"
                    color="purple"
                    dynamicClassProperty="lg:h-12 xl:h-14 2xl:h-10"
                  >
                    <p
                      className="font-medium text-black pb-4 break-words overflow-hidden text-xs"
                      role="paragraph"
                    >
                      Sent to 1+ jobs
                    </p>
                    <h3 className="font-medium text-black text-base">
                      {candidatesSent.metrics.sent_to_1plus_jobs > 0
                        ? candidatesSent.metrics.sent_to_1plus_jobs
                        : 0}
                    </h3>
                  </DividerField>
                  <DividerField
                    dataTestId="sent-to-three-plus-jobs"
                    color="light-purple"
                    dynamicClassProperty="lg:h-12 xl:h-14 2xl:h-10"
                  >
                    <p
                      className="font-medium text-black pb-4 break-words overflow-hidden text-xs"
                      role="paragraph"
                    >
                      Sent to 3+ jobs
                    </p>
                    <h3 className="font-medium text-black text-base">
                      {candidatesSent.metrics.sent_to_3plus_jobs > 0
                        ? candidatesSent.metrics.sent_to_3plus_jobs
                        : 0}
                    </h3>
                  </DividerField>
                </div>
              </div>
            </div>
            <div className="lg:hidden xl:flex xl:justify-end md:flex md:justify-end hidden 2xl:mr-10 3xl:mr-20">
              <div className="flex flex-col justify-center w-2/3 lg:w-3/4 xl:w-[140px] 2xl:w-[180px] 3xl:w-3/5">
                <ProgressBar
                  dataTestId="candidates-sent"
                  color="purple"
                  childValue={candidatesSent.percentages.inner}
                  parentValue={candidatesSent.percentages.outer}
                  dynamicClassProperty={"mt-0 flex"}
                />
                <div className="md:mt-2 lg:mt-2 text-center">
                  <p className="font-medium text-sm">
                    {candidatesSent.percentages.outer}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ) : null}
    </>
  );
};

export default CandidateSent;
