import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from 'lodash.debounce';

//reducers
import { setSelectedJobMetricsType } from "../../../../reducers/metrics-reducer";


const JobMetricsRadioButton = () => {
  //stored state
  const dispatch = useDispatch();
  const metricSelection = useSelector(
    (state: any) => state.metricsSelection.jobMetricSelection
  );
    
  const [selectedMetric, setSelectedMetric] = useState(metricSelection)

  const onOptionChange = (e: any) => {
    setSelectedMetric(e.target.value)
    dispatch(setSelectedJobMetricsType(e.target.value));
  }

  const debouncedChangeHandler = useMemo(
    () => debounce(onOptionChange, 1000)
  , []);
  
  return (
    <div aria-label="job-metric" className="job-metric">
      {/** Radio button selection */}
      <div className="flex flex-col md:flex-row -mt-2">
        <div className="flex mr-2 justify-start items-start">
          <input
            type="radio"
            id="job_metrics_last_30_days"
            value="Last 30 days"
            className="form-radio h-5 w-5 mb-2 md:mb-0 checked:bg-black checked:text-black mt-0.5 checked:ring-black focus:ring-black ring-black"
            checked={selectedMetric === "Last 30 days"}
            onChange={debouncedChangeHandler}
            aria-label="job-metrics-last-days-input"
          />
          <label
            htmlFor="job_metrics_last_30_days"
            className="ml-2 mb-2 md:mb-0 text-base 2xl:text-base font-medium text-gray-900"
            aria-label="job-metrics-last-days"
          >
            Last 30 days
          </label>
        </div>
        <div className="flex mr-2 justify-start items-start">
          <input
            type="radio"
            id="job_metrics_last_30_days_all_time"
            value="All time"
            className="form-radio h-5 w-5 mb-2 md:mb-0 checked:bg-black checked:text-black mt-0.5 checked:ring-black focus:ring-black ring-black"
            checked={selectedMetric === "All time"}
            onChange={debouncedChangeHandler}
            aria-label="job-metrics-all-time-input"
          />
          <label
            htmlFor="job_metrics_last_30_days_all_time"
            className="ml-2 mb-2 sm:mb-0 text-base 2xl:text-base font-medium text-gray-900"
            aria-label="job-metrics-all-time"
          >
            All time
          </label>
        </div>
        <div className="flex mr-2 justify-start items-start">
          <input
            type="radio"
            id="job_metrics_last_30_days_custom"
            value="Custom"
            className="form-radio h-5 w-5 mb-2 md:mb-0 checked:bg-black checked:text-black mt-0.5 checked:ring-black focus:ring-black ring-black"
            checked={selectedMetric === "Custom"}
            onChange={debouncedChangeHandler}
            aria-label="job-metrics-custom-input"
          />
          <label
            htmlFor="job_metrics_last_30_days_custom"
            className="ml-2 mb-2 md:mb-0 text-base 2xl:text-base font-medium text-gray-900"
            aria-label="job-metrics-custom"
          >
            Custom
          </label>
        </div>
      </div>
    </div>
  );
};

export default JobMetricsRadioButton;
