import { createSlice } from "@reduxjs/toolkit";

export const jobMetricsSelection = createSlice({
  name: "jobMetricSelection",
  initialState: {
    jobMetricSelection: "Last 30 days",
  },
  reducers: {
    setSelectedJobMetricsType: (state, action) => {
      state.jobMetricSelection = action.payload;
    },
  },
});

export const { setSelectedJobMetricsType } = jobMetricsSelection.actions;

export default jobMetricsSelection.reducer;
