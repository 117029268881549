import SideBar from "./SideBar.component";
import LandingPage from "./landingPage/LandingPage.component";

const Dashboard = () => {
  let dashboardPermissions = localStorage.getItem("dashboard") as any;
  return (
    <div className="flex h-screen" aria-label="dashboard-panel">
      <div className="flex flex-1 overflow-hidden">
        <div className="flex bg-gray-100">
          <SideBar pathName={window.location.pathname} />
        </div>
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 overflow-y-auto">
            {dashboardPermissions === "true" && (
            <LandingPage />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
