import axios from "axios";

const {
  REACT_APP_BASE_AUTH_URL,
} = process.env;

const axiosAuth = axios.create(
  {
    baseURL: REACT_APP_BASE_AUTH_URL
  }
);

// Request interceptors for API calls
axiosAuth.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    config.headers["accept"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptors for API calls
axiosAuth.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    window.location.href = `/`;
  }
});

export default axiosAuth;