import * as React from "react";

interface ActivityMetricsSectionPropsDefinition {
  heading: string;
  value: any;
  toolTipText: string;
  ariaLabel: string;
  calculationPercent: any | number;
}

const ActivityMetricsSection: React.FC<
  ActivityMetricsSectionPropsDefinition
> = ({ heading, value, toolTipText, ariaLabel, calculationPercent }) => {
  return (
    <div className="max-w-sm">
      <div className="grid grid-cols-2 gap-4">
        <div className="group relative mt-1 ml-2 max-w-fit">
          <svg className="inline-block lg:w-[12px] lg:h-[12px] 2xl:w-[14px] 2xl:h-[14px] 3xl:w-[16px] 3xl:h-[16px] text-xs text-tooltip" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
          <span className="w-60 z-50 text-xs !absolute -left-9 -top-28 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
            {toolTipText}
          </span>
        </div>
        { calculationPercent !== undefined ? <div className="mt-1 2xl:mt-1 mr-2 flex justify-end items-end">
          <p className="bg-black rounded-full text-white font-semibold p-1 px-2.5 w-auto flex justify-center items-center text-[10px] cursor-default">{Number(calculationPercent).toFixed(1)}%</p></div>: null}
      </div>
      <div
        className={calculationPercent !== undefined ? "px-5 my-8": "px-5 my-8"}
        aria-label={ariaLabel}
      >
        <div className="w-full xl:w-min 2xl:w-fit">
          <div className="flex flex-col hover:underline">
            <p className="font-medium text-2xl">{value}</p>
            <p className="font-normal text-xs">{heading}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityMetricsSection;
