import { Component, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//reducers
import { setSelectedMonthType } from "../../reducers/month-selection.reducer";

interface PropsDefinition {
  setShowDatePicker: Dispatch<SetStateAction<boolean>>;
}

const RadioButton = ({ setShowDatePicker }: PropsDefinition) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("current_month");

  useEffect(() => {
    dispatch(setSelectedMonthType(selected));
  }, []);
  
  const handleChange = (e: any) => {
    const { id } = e.target;
    setSelected(id);
    dispatch(setSelectedMonthType(e.target.value));
    if (e.target.value === "custom") {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
    }
  };

  return (
    <>
      {/** Radio button selection */}
      <div onChange={handleChange} className="flex justify-start items-start">
        <div className="flex items-center mr-4 mt-1">
          <input
            type="radio"
            id="current_month"
            value="current_month"
            className="form-radio h-4 w-4 checked:bg-black checked:text-black p-3 checked:ring-black focus:ring-black ring-grey border-grey"
            name="radio"
            defaultChecked
            aria-label="current_month-radio-input"
          />
          <label
            htmlFor="current_month"
            className="ml-2 text-gray-900 text-xs font-medium"
            aria-label="current_month"
          >
            Current month
          </label>
        </div>
        <div className="flex items-center mt-1">
          <input
            type="radio"
            id="custom"
            value="custom"
            className="form-radio h-4 w-4 checked:bg-black checked:text-black p-3 checked:ring-black focus:ring-black ring-grey border-grey"
            name="radio"
            aria-label="custom-radio-input"
          />
          <label
            htmlFor="custom"
            className="ml-2 text-gray-900 text-xs font-medium"
            aria-label="custom"
          >
            Custom
          </label>
        </div>
      </div>
    </>
  );
};

export default RadioButton;
