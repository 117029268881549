import logo from "../../../assets/autometry-logo.svg";
import ChangePasswordForm from "./ChangePasswordForm";

const ChangePassword = () => {
  return (
    <div
      className="lg:grid lg:grid-cols-6 lg:gap4 flex flex-col"
      aria-label="sign-in-page"
      data-testid="sign-in"
    >
      <div className="lg:col-span-2 lg:border-r border-b p-4 border-grey">
        <div className="flex lg:h-screen w-full justify-center items-center">
          <img src={logo} alt="autometry-logo" className="lg:w-1/2" />
        </div>
      </div>
      <div className="login-form bg-blue col-span-4">
        <ChangePasswordForm />
      </div>
    </div>
  );
};

export default ChangePassword;
