import { useDispatch, useSelector } from "react-redux";
import RevenueMetricsRadioButton from "./RevenueMetricsRadio.component";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import RevenueMetricsDatePicker from "./RevenueMetricsDatePicker";

import {
  computedEndDate,
  computedStartDate,
} from "../../../../helpers/date.helpers";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

//reducers
import {
  setParameterName,
  setQueryparameter,
  setHeaderName,
  setFilterTags,
} from "../../../../reducers/common-reducer";
import { useNavigate } from "react-router-dom";

import { numberWithCommas } from "../../../../helpers/common.helpers";
import { format, parseISO } from "date-fns";

interface RevenueMetricsPropsDefinition {
  setSelectedRange: Dispatch<SetStateAction<DateRange | undefined>>;
  selectedRange: DateRange | any;
  recruiterPanelData: any;
}

const RevenueMetrics: React.FC<RevenueMetricsPropsDefinition> = ({
  setSelectedRange,
  selectedRange,
  recruiterPanelData,
}) => {
  const navigate = useNavigate();

  //stored state
  const dispatch = useDispatch();
  const dateRange = useSelector(
    (state: any) => state.rangeSelection.revenueMetricsRange
  );

  const [showRevenueDatePicker, setShowRevenueDatePicker] = useState(false);
  const [tags, setTags] = useState([] as any);

  let fromDate = computedStartDate(dateRange);
  let toDate = computedEndDate(dateRange);

  // state variables
  const revenueMetricSelection = useSelector(
    (state: any) => state.metricsSelection.revenueMetricSelection
  );

  useEffect(() => {
    if (revenueMetricSelection === "Custom") {
      setShowRevenueDatePicker(true);
    } else {
      setShowRevenueDatePicker(false);
    }
  }, [revenueMetricSelection]);

  const handleAnchorClick = (
    event: any,
    name: any,
    header: string,
    query: any
  ) => {
    let total_interviews, placements, revenue, dateRange;

    if (revenueMetricSelection === "Custom") {
      dateRange = `${format(parseISO(fromDate as any), "y-MM-dd")} - ${format(
        parseISO(toDate as any),
        "y-MM-dd"
      )}`;
    } else {
      dateRange = revenueMetricSelection;
    }

    total_interviews =
      recruiterPanelData.summary.interviews > 1
        ? `${recruiterPanelData.summary.interviews} interviews`
        : `${recruiterPanelData.summary.interviews} interview`;
    placements =
      recruiterPanelData.summary.placements > 1
        ? `${recruiterPanelData.summary.placements} placements`
        : `${recruiterPanelData.summary.placements} placement`;
    revenue = `£${numberWithCommas(
      recruiterPanelData.summary.revenue_generated
    )} revenue`;

    event.preventDefault();
    tags.push(total_interviews, placements, revenue, dateRange);
    dispatch(setParameterName(name));
    dispatch(setQueryparameter(query));
    dispatch(setHeaderName(header));
    dispatch(setFilterTags(tags));
    navigate(`/workflows/ucd/interview-drilldown`);
  };

  return (
    <div
      className="bg-sidebar rounded-2xl px-5 py-8 border border-grey mt-10 lg:mt-0"
      aria-label="revenue-metrics-panel"
    >
      <div className="grid grid-cols-6 gap-4 ml-3">
        <div className="col-span-3 md:col-span-2">
          <h2 className="font-medium text-md">Revenue Metrics</h2>
        </div>
        <div className="col-span-3 md:col-span-4 flex justify-end items-end">
          <RevenueMetricsRadioButton />
        </div>
      </div>
      {showRevenueDatePicker ? (
        <RevenueMetricsDatePicker
          setSelectedRange={setSelectedRange as any}
          selectedRange={selectedRange}
          pathName={window.location.pathname}
        />
      ) : null}
      <h3
        className={
          showRevenueDatePicker
            ? "font-bold text-sm md:mt-0 text-metricsGreen"
            : "font-bold text-sm md:mt-10 text-metricsGreen ml-3"
        }
      >
        Recruiter
      </h3>
      <div className="flex flex-col md:grid md:grid-cols-3 mt-3 md:mt-0 md:gap-6 ml-3">
        <div className="flex flex-row grid grid-cols-2 mt-3 gap-6 md:col-span-2">
          <div className="hidden lg:block">
            {recruiterPanelData.summary.interviews > 0 ? (
              <a
                href={`/workflows/ucd/interview-drilldown`}
                onClick={(event) =>
                  handleAnchorClick(event, "name", "Revenue metrics", ``)
                }
              >
                <div className="max-w-sm rounded-xl border border-metricsGreen bg-white hover:bg-lightGreen">
                  <div className="group relative mt-1 ml-2 max-w-fit">
                    <svg className="inline-block w-[12px] h-[12px] text-xs text-tooltip" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
                    <span className="w-60 z-50 text-xs !absolute -left-8 -top-24 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                      Total interviews with re-engaged candidates within six
                      months of starting in this workflow.
                    </span>
                  </div>
                  <div
                    className="px-3 3xl:py-20 2xl:py-16 xl:py-8 lg:py-8 py-16"
                    aria-label="recruiter-interviews"
                  >
                    <div className="w-fit">
                      <div className="flex flex-col group-hover:underline">
                        <p className="font-medium text-2xl">
                          {recruiterPanelData.summary.interviews}
                        </p>
                        <p className="font-normal text-xs 2xl:text-base">
                          Interviews
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            ) : (
              <div className="max-w-sm rounded-xl border border-metricsGreen bg-white hover:bg-lightGreen">
                <div className="group relative mt-1 ml-2 max-w-fit">
                  <svg className="inline-block w-[12px] h-[12px] text-xs text-tooltip" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
                  <span className="w-60 z-50 text-xs !absolute -left-8 -top-24 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                    Total interviews with re-engaged candidates within six
                    months of starting in this workflow.
                  </span>
                </div>
                <div
                  className="px-3 3xl:py-20 2xl:py-16 xl:py-8 lg:py-8 py-16"
                  aria-label="recruiter-interviews"
                >
                  <div className="w-fit">
                    <div className="flex flex-col group-hover:underline">
                      <p className="font-medium text-2xl">
                        {recruiterPanelData.summary.interviews}
                      </p>
                      <p className="font-normal text-xs 2xl:text-base">
                        Interviews
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="block lg:hidden">
            <div className="max-w-sm rounded-xl border border-metricsGreen bg-white hover:bg-lightGreen">
              <div className="group relative mt-1 ml-2 max-w-fit">
                <svg className="inline-block w-[12px] h-[12px] text-xs text-tooltip" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
                <span className="w-60 z-50 text-xs !absolute -left-8 -top-24 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                  Total interviews with re-engaged candidates within six months
                  of starting in this workflow.
                </span>
              </div>
              <div
                className="px-3 3xl:py-20 2xl:py-16 xl:py-8 lg:py-8 py-16"
                aria-label="recruiter-interviews"
              >
                <div className="w-fit">
                  <div className="flex flex-col group-hover:underline">
                    <p className="font-medium text-2xl">
                      {recruiterPanelData.summary.interviews}
                    </p>
                    <p className="font-normal text-sm 2xl:text-base">
                      Interviews
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block">
            {recruiterPanelData.summary.placements > 0 ? (
              <a
                href={`/workflows/ucd/interview-drilldown`}
                onClick={(event) =>
                  handleAnchorClick(event, "name", "Revenue metrics", ``)
                }
              >
                <div className="max-w-sm rounded-xl border border-metricsGreen bg-white cursor-pointer hover:bg-lightGreen">
                  <div className="group relative mt-1 ml-2 max-w-fit">
                    <svg className="inline-block w-[12px] h-[12px] text-xs text-tooltip" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
                    <span className="w-60 z-50 text-xs !absolute -left-8 -top-24 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                      Total placements with re-engaged candidates within six
                      months of starting in this workflow.
                    </span>
                  </div>
                  <div
                    className="px-3 3xl:py-20 2xl:py-16 xl:py-8 lg:py-8 py-16"
                    aria-label="recruiter-placements"
                  >
                    <div className="w-fit">
                      <div className="flex flex-col cursor-pointer group-hover:underline">
                        <p className="font-medium text-2xl">
                          {recruiterPanelData.summary.placements}
                        </p>
                        <p className="font-normal text-xs 2xl:text-base ">
                          Placements
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            ) : (
              <div className="max-w-sm rounded-xl border border-metricsGreen bg-white hover:bg-lightGreen">
                <div className="group relative mt-1 ml-2 max-w-fit">
                  <svg className="inline-block w-[12px] h-[12px] text-xs text-tooltip" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
                  <span className="w-60 z-50 text-xs !absolute -left-8 -top-24 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                    Total placements with re-engaged candidates within six
                    months of starting in this workflow.
                  </span>
                </div>
                <div
                  className="px-3 3xl:py-20 2xl:py-16 xl:py-8 lg:py-8 py-16"
                  aria-label="recruiter-placements"
                >
                  <div className="w-fit">
                    <div className="flex flex-col group-hover:underline">
                      <p className="font-medium text-2xl">
                        {recruiterPanelData.summary.placements}
                      </p>
                      <p className="font-normal text-xs 2xl:text-base ">
                        Placements
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="block lg:hidden">
            <div className="max-w-sm rounded-xl border border-metricsGreen bg-white hover:bg-lightGreen">
              <div className="group relative mt-1 ml-2 max-w-fit">
                <svg className="inline-block w-[12px] h-[12px] text-xs text-tooltip" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
                <span className="w-60 z-50 text-xs !absolute -left-8 -top-28 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                  Total placements with re-engaged candidates within six months
                  of starting in this workflow.
                </span>
              </div>
              <div
                className="px-3 3xl:py-20 2xl:py-16 xl:py-8 lg:py-8 py-16"
                aria-label="recruiter-placements"
              >
                <div className="w-fit">
                  <div className="flex flex-col group-hover:underline">
                    <p className="font-medium text-2xl">
                      {recruiterPanelData.summary.placements}
                    </p>
                    <p className="font-normal text-sm 2xl:text-base ">
                      Placements
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 mt-4 sm:mt-3">
          <div className="hidden lg:block">
            {recruiterPanelData.summary.revenue_generated > 0 ? (
              <a
                href={`/workflows/ucd/interview-drilldown`}
                onClick={(event) =>
                  handleAnchorClick(event, "name", "Revenue metrics", ``)
                }
              >
                <div className="md:max-w-sm rounded-xl border border-metricsGreen bg-white cursor-pointer hover:bg-lightGreen">
                  <div className="group relative mt-1 ml-2 max-w-fit">
                    <svg className="inline-block w-[12px] h-[12px] text-xs text-tooltip" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
                    <span className="w-60 z-50 text-xs !absolute -left-8 -top-28 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                      Total placement profit generated from reactivated
                      candidates within six months of starting in this workflow.
                    </span>
                  </div>
                  <div
                    className="px-3 3xl:py-20 2xl:py-16 xl:py-8 lg:py-8 py-16"
                    aria-label="recruiter-revenue-generated"
                  >
                    <div className="w-fit">
                      <div className="flex flex-col cursor-pointer group-hover:underline">
                        <p className="font-medium text-2xl">
                          £
                          {numberWithCommas(
                            recruiterPanelData.summary.revenue_generated
                          )}
                        </p>
                        <p className="font-normal text-xs 2xl:text-base">
                          Revenue Generated
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            ) : (
              <div className="md:max-w-sm rounded-xl border border-metricsGreen bg-white hover:bg-lightGreen">
                <div className="group relative mt-1 ml-2 max-w-fit">
                  <svg className="inline-block w-[12px] h-[12px] text-xs text-tooltip" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
                  <span className="w-60 z-50 text-xs !absolute -left-8 -top-28 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                    Total placement profit generated from reactivated candidates
                    within six months of starting in this workflow.
                  </span>
                </div>
                <div
                  className="px-3 3xl:py-20 2xl:py-16 xl:py-8 lg:py-8 py-16"
                  aria-label="recruiter-revenue-generated"
                >
                  <div className="w-fit">
                    <div className="flex flex-col group-hover:underline">
                      <p className="font-medium text-2xl">
                        £
                        {numberWithCommas(
                          recruiterPanelData.summary.revenue_generated
                        )}
                      </p>
                      <p className="font-normal text-xs 2xl:text-base">
                        Revenue Generated
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="block lg:hidden">
            <div className="md:max-w-sm rounded-xl border border-metricsGreen bg-white hover:bg-lightGreen">
              <div className="group relative mt-1 ml-2 max-w-fit">
                <svg className="inline-block w-[12px] h-[12px] text-xs text-tooltip" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
                <span className="w-60 z-50 text-xs !absolute -left-8 -top-28 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                  Total placement profit generated from reactivated candidates
                  within six months of starting in this workflow.
                </span>
              </div>
              <div
                className="px-3 3xl:py-20 2xl:py-16 xl:py-8 lg:py-8 py-16"
                aria-label="recruiter-revenue-generated"
              >
                <div className="w-fit">
                  <div className="flex flex-col group-hover:underline">
                    <p className="font-medium text-2xl">
                      £
                      {numberWithCommas(
                        recruiterPanelData.summary.revenue_generated
                      )}
                    </p>
                    <p className="font-normal text-sm 2xl:text-base">
                      Revenue Generated
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueMetrics;
