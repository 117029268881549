import vincereImage from "../../../assets/vincere.svg";
import emailImage from "../../../assets/email.svg";
import smsImage from "../../../assets/sms.svg";
import { format, parseISO } from "date-fns";
import WorkflowInfo from "../../common/WorkflowInfo.component";

interface WorkflowPropsDefinition {
  workflowInfoData: any;
}

const Workflow: React.FC<WorkflowPropsDefinition> = ({ workflowInfoData }) => {
  return (
    <div aria-label="workflow-information-panel">
      <WorkflowInfo id={"ucd"} name={workflowInfoData.name} description={workflowInfoData.description} status={workflowInfoData.status} frequency={workflowInfoData.frequency} activityStatus={workflowInfoData.latest_activity}/>
    </div>
    );
};

export default Workflow;
