import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from 'lodash.debounce';

//reducers
import { setSelectedActivityMetricsType } from "../../../../reducers/metrics-reducer";


const ActivityMetricsRadioButton = () => {
  //stored state
  const dispatch = useDispatch();
  const metricSelection = useSelector(
    (state: any) => state.metricsSelection.activityMetricSelection
  );
    
  const [selectedMetric, setSelectedMetric] = useState(metricSelection)

  const onOptionChange = (e: any) => {
    setSelectedMetric(e.target.value)
    dispatch(setSelectedActivityMetricsType(e.target.value));
  }

  const debouncedChangeHandler = useMemo(
    () => debounce(onOptionChange, 1000)
  , []);
  
  return (
    <div aria-label="activity-metric" className="activity-metric">
      {/** Radio button selection */}
      <div className="flex flex-col xl:flex-row">
        <div className="flex mr-2 mb-2 justify-start items-start">
          <input
            type="radio"
            id="last_30_days"
            value="Last 30 days"
            className="form-radio lg:h-4 lg:w-4 lg:mb-2 xl:mb-2 md:mb-0 checked:bg-black checked:text-black checked:ring-black focus:ring-black ring-black"
            checked={selectedMetric === "Last 30 days"}
            onChange={debouncedChangeHandler}
            aria-label="activity-metrics-last-days-input"
          />
          <label
            htmlFor="last_30_days"
            className="ml-2 lg:ml-1 2xl:ml-2 md:ml-2 2xl:ml-2 mb-2 md:mb-0 text-xs font-medium text-gray-900"
            aria-label="activity-metrics-last-days"
          >
            Last 30 days
          </label>
        </div>
        <div className="flex mr-2 mb-2 justify-start items-start">
          <input
            type="radio"
            id="all_time"
            value="All time"
            className="form-radio lg:h-4 lg:w-4 lg:mb-2 xl:mb-2 md:mb-0 checked:bg-black checked:text-black checked:ring-black focus:ring-black ring-black"
            checked={selectedMetric === "All time"}
            onChange={debouncedChangeHandler}
            aria-label="activity-metrics-all-time-input"
          />
          <label
            htmlFor="all_time"
            className="ml-2 lg:ml-1 2xl:ml-2 md:ml-2 2xl:ml-2 mb-2 md:mb-0 text-xs font-medium text-gray-900"
            aria-label="activity-metrics-all-time"
          >
            All time
          </label>
        </div>
        <div className="flex mr-2 justify-start items-start">
          <input
            type="radio"
            id="custom"
            value="Custom"
            className="form-radio lg:h-4 lg:w-4 md:mb-0 checked:bg-black checked:text-black checked:ring-black focus:ring-black ring-black"
            checked={selectedMetric === "Custom"}
            onChange={debouncedChangeHandler}
            aria-label="activity-metrics-custom-input"
          />
          <label
            htmlFor="custom"
            className="ml-2 lg:ml-1 2xl:ml-2 md:ml-2 2xl:ml-2 mb-2 md:mb-0 text-xs font-medium text-gray-900"
            aria-label="activity-metrics-custom"
          >
            Custom
          </label>
        </div>
      </div>
    </div>
  );
};

export default ActivityMetricsRadioButton;
