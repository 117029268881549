import * as React from "react";
import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";

interface LoaderProps {
  showLoader: boolean;
}

const LandingPageLoader: React.FC<LoaderProps> = ({ showLoader }) => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (showLoader) {
        setShowSpinner(true);
      setTimeout(() => setShowSpinner(false), 10000);
    }
  }, [showLoader]);

  return (
    <div
      className="flex w-full justify-center items-center h-full flex-col h-[200px]"
      aria-label="landing-page-loader"
    >
      <Oval height="80" width="80" color="#7a4ac8" ariaLabel="oval-loader" />
    </div>
  );
};

export default LandingPageLoader;
