import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getItem } from "./persistanceStorage";

const {
  REACT_APP_BASE_API_URL,
} = process.env;

const axiosDataAuth = axios.create({
  baseURL: REACT_APP_BASE_API_URL,
});

// Request interceptors for API calls
axiosDataAuth.interceptors.request.use(
  (config) => {
    const token = getItem("token");
    const organisation = getItem("organisation");

    // Setting headers
    config.headers["Content-Type"] = "application/json";
    config.headers["token"] = token;
    config.headers["org"] = organisation;
    config.headers["subsystem"] = "thefrontend";
    config.headers["correlationid"] = uuidv4();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptors for API calls
axiosDataAuth.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    window.location.href = `/`;
  }
});

export default axiosDataAuth;
