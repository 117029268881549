import { useState } from "react";
import logo from "../../../assets/autometry-logo.svg";

// components
import SignInForm from "./SignInForm";
import PasswordResetForm from "../../forms/PasswordReset/PasswordResetForm";

const SignInPage = () => {
  const [currentStage, setCurrentStage] = useState(1);

  return (
    <div
      className="lg:grid lg:grid-cols-6 lg:gap4 flex flex-col h-screen lg:h-auto"
      aria-label="sign-in-page"
      data-testid="sign-in"
    >
      <div className="lg:col-span-2 lg:border-r border-b p-4 border-grey">
        <div className="flex lg:h-full w-full justify-center items-center">
          <img src={logo} alt="autometry-logo" className="w-3/12 lg:w-1/2 md:w-3/12 sm:w-3/12 xl:w-1/2"/>
        </div>
      </div>
      <div className="login-form col-span-4 flex justify-center items-center h-screen">
        {currentStage === 1 && <SignInForm setCurrentStage={setCurrentStage} />}
        {currentStage === 2 && (
          <PasswordResetForm setCurrentStage={setCurrentStage} />
        )}
      </div>
    </div>
  );
};

export default SignInPage;
