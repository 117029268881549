import { useEffect, useState } from "react";
import { clientDataDetails } from "../../services/landingPageDetails";

// components
import {
  InputField,
  Card,
  ProgressBar,
  DividerField,
} from "@autometry/ui-components";

import LandingPageLoader from "../../Loading/LandingPageLoader";
import { useSelector } from "react-redux";
import {
  computedEndDate,
  computedStartDate,
  formattedStartDate,
} from "../../../helpers/date.helpers";
import { DateRange } from "react-day-picker";
import { numberWithCommas } from "../../../helpers/common.helpers";

const ClientDatabase = () => {
  const [isLoading, setLoading] = useState(true);
  const [clientData, setClientData] = useState(undefined as any);

  // redux state
  const jobType = useSelector((state: any) => state.jobType.jobSelection);
  const monthType = useSelector((state: any) => state.monthType.monthSelection);
  const range = useSelector((state: any) => state.rangeSelection.selectedRange);
  const dateSelected = useSelector(
    (state: any) => state.selection.dateSelected
  );

  useEffect(() => {
    setLoading(false);
    if (monthType === "custom") {
      if (dateSelected) setLoading(true);
      else setLoading(false);
    } else {
      setLoading(true);
    }

    let currentDate = new Date();

    // fetching values
    let fromDate, toDate;
    if (monthType === "current_month") {
      fromDate = formattedStartDate(currentDate);
      toDate = computedEndDate(currentDate);
    } else {
      fromDate = computedStartDate(range);
      toDate = computedEndDate(range);
    }

    // fetching values
    if (fromDate !== undefined && toDate !== undefined) {
      clientDataDetails("client_database", fromDate, toDate, jobType)
        .then((data) => {
          if (data) {
            setClientData(data);
            setLoading(false);
          } else {
            console.log("no data found");
          }
        })
        .catch(function (error) {
          console.log("error : ", error);
        });
    }
  }, [jobType, range, monthType, dateSelected]);

  if (isLoading) {
    return <LandingPageLoader showLoader={true} />;
  }

  return (
    <>
      {clientData !== undefined ? (
        <Card
          dataTestId="client-database"
          header={{
            dataTestId: "client-database",
            color: "orange",
            title: "Client database",
            dynamicClassProperty: "!font-medium text-lg",
          }}
        >
          <div className="flex lg:flex-col xl:flex-row xl:justify-between xl:justify-items-start sm:flex-row sm:justify-between sm:justify-items-start">
            <div className="block mt-4 lg:mt-4 xl:mt-4 w-full xl:w-7/12 2xl:w-[310px] 3xl:w-[350px]">
              {/** screen resolution 1024px and portrait mode devices*/}
              <div className="md:hidden lg:flex md:hidden lg:flex-row flex flex-row xl:hidden justify-between justify-items-start">
                <div className="py-0 block">
                  <InputField
                    dataTestId="client-total-contact"
                    width="auto"
                    dynamicClassProperty="!py-4"
                  >
                    <div className="flex flex-col">
                      <h3 className="font-medium text-black flex items-center text-2xl">
                        {clientData.total}
                      </h3>
                      <p className="font-medium text-black text-sm">
                        Total <br /> contacts
                      </p>
                    </div>
                  </InputField>
                </div>
                <div className="flex flex-col justify-center w-32">
                  <ProgressBar
                    dataTestId="client-database"
                    color="orange"
                    childValue={clientData.percentages.inner}
                    parentValue={clientData.percentages.outer}
                    dynamicClassProperty={"flex"}
                  />
                  <div className="mt-1 text-center">
                    <p className="font-medium text-sm">
                      {clientData.percentages.outer}%
                    </p>
                  </div>
                </div>
              </div>
              <div className="hidden lg:hidden xl:w-fit xl:block md:w-fit md:block">
                <InputField dataTestId="client-total-contact" width={"full"}>
                  <div className="flex justify-start">
                    <h3 className="font-medium text-black flex items-center text-2xl">
                      {numberWithCommas(clientData.total)}
                    </h3>
                  </div>
                  <div className="flex justify-end p-2">
                    <p className="font-medium text-black pl-2 text-xs">
                      Total contacts
                    </p>
                  </div>
                </InputField>
              </div>
              <div className="lg:mt-4 xl:mt-7 md:mt-7 mt-5">
                <div className="w-auto grid grid-cols-2 gap-4 content-start md:flex md:justify-between md:justify-content-start md:w-72 lg:w-auto lg:grid lg:grid-cols-2 lg:gap-4 lg:content-start">
                  <DividerField
                    dataTestId="client-database-with-one-plus-job"
                    color="orange"
                    dynamicClassProperty="lg:h-12 xl:h-14 2xl:h-10"
                  >
                    <p
                      className="font-medium text-black pb-4 break-words overflow-hidden text-xs"
                      role="paragraph"
                    >
                      Listed 1+ jobs
                    </p>
                    <h3 className="font-medium text-black text-base">
                      {clientData.metrics.listed_1plus_jobs > 0
                        ? clientData.metrics.listed_1plus_jobs
                        : 0}
                    </h3>
                  </DividerField>
                  <DividerField
                    dataTestId="client-database-with-one-plus-placements"
                    color="light-orange"
                    dynamicClassProperty="lg:h-12 xl:h-14 2xl:h-10"
                  >
                    <p
                      className="font-medium text-black pb-4 break-words overflow-hidden text-xs"
                      role="paragraph"
                    >
                      Made 1+ placements
                    </p>
                    <h3 className="font-medium text-black text-base">
                      {clientData.metrics.made_1plus_placements > 0
                        ? clientData.metrics.made_1plus_placements
                        : 0}
                    </h3>
                  </DividerField>
                </div>
              </div>
            </div>
            <div className="lg:hidden xl:flex xl:justify-end md:flex md:justify-end hidden 2xl:mr-10 3xl:mr-20">
              <div className="flex flex-col justify-center w-2/3 lg:w-3/4 xl:w-[140px] 2xl:w-[180px] 3xl:w-3/5">
                <ProgressBar
                  dataTestId="client-database"
                  color="orange"
                  childValue={clientData.percentages.inner}
                  parentValue={clientData.percentages.outer}
                  dynamicClassProperty={"mt-0 flex"}
                />
                <div className="md:mt-2 lg:mt-2 text-center">
                  <p className="font-medium text-sm">
                    {clientData.percentages.outer}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ) : null}
    </>
  );
};

export default ClientDatabase;
