import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { useDispatch, useSelector } from "react-redux";

// components
import Workflow from "./Workflow.component";
import DataHealth from "./DataHealth.component";
import ActivityMetrics from "./ActivityMetrics/ActivityMetrics.component";
import RevenueMetrics from "./RevenueMetrics/RevenueMetrics.component";
import WorkflowPerformance from "./ActivityMetrics/WorkflowPerformanceTable";
import RecruiterPanel from "./RevenueMetrics/RecruiterPanel";

import {
  recruiterDetails,
  workflowInformation,
} from "../../services/workflowsDetails";
import { workflowPerformanceDetails } from "../../services/workflowsDetails";

// Date input functions
import { subDays } from "date-fns";
import LoadingComponent from "../../Loading/LoadingComponent";
import LandingPageLoader from "../../Loading/LandingPageLoader";
import WorkflowPerformanceMobile from "./ActivityMetrics/WorkflowPerformanceTableMobile";
import RecruiterPanelMobile from "./RevenueMetrics/RecruiterPanelMobile";
import {
  setActivityMetricsRangeSelection,
  setRevenueMetricsRangeSelection,
} from "../../../reducers/date-selection.reducer";
import {
  setActivityMetricsRange,
  setRevenueMetricsRange,
} from "../../../reducers/common-reducer";
import {
  computedEndDate,
  computedStartDate,
} from "../../../helpers/date.helpers";
import { useNavigate } from "react-router-dom";
import { getItem } from "../../utils/persistanceStorage";

const { REACT_APP_BASE_AUTH_URL } = process.env;

const BasicComponent = () => {
  //stored state
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);
  const [workflowInfoData, setWorkflowInfoData] = useState();
  const [activityPanelData, setActivityPanelData] = useState() as any;
  const [recruiterPanelData, setRecruiterPanelData] = useState() as any;

  const activityMetricsDateRange = useSelector(
    (state: any) => state.rangeSelection.activityMetricsRange
  );
  const revenueMetricsDateRange = useSelector(
    (state: any) => state.rangeSelection.revenueMetricsRange
  );

  // Metrics Custom Date Range
  const activityMetricsRange = useSelector(
    (state: any) => state.selection.activityMetrics
  );
  const revenueMetricsRange = useSelector(
    (state: any) => state.selection.revenueMetrics
  );

  const [activityMetricsSelectedRange, setActivityMetricsSelectedRange] =
    useState<DateRange>({
      from: subDays(new Date(), 30),
      to: new Date(),
    });

  const [revenueMetricsSelectedRange, setRevenueMetricsSelectedRange] =
    useState<DateRange>({
      from: subDays(new Date(), 30),
      to: new Date(),
    });

  const metricSelection = useSelector(
    (state: any) => state.metricsSelection.activityMetricSelection
  );

  const revenueMetricSelection = useSelector(
    (state: any) => state.metricsSelection.revenueMetricSelection
  );

  useEffect(() => {
    workflowInformation("ucd")
      .then((data) => {
        if (data) {
          setWorkflowInfoData(data);
          setLoading(false);
        } else {
          console.log("no data found");
        }
      })
      .catch(function (error) {
        console.log("error : ", error);
      });
  }, []);

  useEffect(() => {
    if (activityMetricsRange) setLoading(true);
  }, [activityMetricsRange]);

  useEffect(() => {
    if (revenueMetricsRange) setLoading(true);
  }, [revenueMetricsRange]);

  useEffect(() => {
    let fromDate = computedStartDate(activityMetricsDateRange);
    let toDate = computedEndDate(activityMetricsDateRange);

    if (
      activityMetricsSelectedRange.from !== undefined &&
      activityMetricsSelectedRange.to !== undefined
    ) {
      workflowPerformanceDetails("ucd", fromDate, toDate)
        .then((data) => {
          if (data) {
            setActivityPanelData(data);
            setLoading(false);
          } else {
            console.log("no data found");
          }
        })
        .catch(function (error) {
          console.log("error : ", error);
        });
    }
  }, [activityMetricsSelectedRange]);

  useEffect(() => {
    let fromDate = computedStartDate(revenueMetricsDateRange);
    let toDate = computedEndDate(revenueMetricsDateRange);

    if (
      revenueMetricsSelectedRange.from !== undefined &&
      revenueMetricsSelectedRange.to !== undefined
    ) {
      recruiterDetails("ucd", fromDate, toDate)
        .then((data) => {
          if (data) {
            setRecruiterPanelData(data);
            setLoading(false);
          } else {
            console.log("no data found");
          }
        })
        .catch(function (error) {
          console.log("error : ", error);
        });
    }
  }, [revenueMetricsSelectedRange]);

  useEffect(() => {
    if (metricSelection === "Last 30 days") {
      setLoading(true);
      dispatch(setActivityMetricsRange(false));
      setActivityMetricsSelectedRange({
        from: subDays(new Date(), 30),
        to: new Date(),
      });
      dispatch(
        setActivityMetricsRangeSelection({
          from: subDays(new Date(), 30),
          to: new Date(),
        })
      );
    } else if (metricSelection === "Custom") {
      setLoading(false);
      setActivityMetricsSelectedRange({ from: undefined, to: undefined });
      dispatch(setActivityMetricsRangeSelection(activityMetricsDateRange));
    } else {
      setLoading(true);
      dispatch(setActivityMetricsRange(false));
      setActivityMetricsSelectedRange({
        from: new Date("01-01-2000"),
        to: new Date(),
      });
      dispatch(
        setActivityMetricsRangeSelection({
          from: new Date("01-01-2000"),
          to: new Date(),
        })
      );
    }
  }, [metricSelection]);

  useEffect(() => {
    if (revenueMetricSelection === "Last 30 days") {
      setLoading(true);
      dispatch(setRevenueMetricsRange(false));
      setRevenueMetricsSelectedRange({
        from: subDays(new Date(), 30),
        to: new Date(),
      });
      dispatch(
        setRevenueMetricsRangeSelection({
          from: subDays(new Date(), 30),
          to: new Date(),
        })
      );
    } else if (revenueMetricSelection === "Custom") {
      setLoading(false);
      setRevenueMetricsSelectedRange({ from: undefined, to: undefined });
      dispatch(setRevenueMetricsRangeSelection(revenueMetricsDateRange));
    } else {
      setLoading(true);
      dispatch(setRevenueMetricsRange(false));
      setRevenueMetricsSelectedRange({
        from: new Date("01-01-2000"),
        to: new Date(),
      });
      dispatch(
        setRevenueMetricsRangeSelection({
          from: new Date("01-01-2000"),
          to: new Date(),
        })
      );
    }
  }, [revenueMetricSelection]);

  if (isLoading) {
    return <LoadingComponent showLoader={true} />;
  }

  return (
    <div
      className="flex flex-1 flex-col p-10 pt-4 overflow-y-auto mt-20 md:mt-20 lg:mt-0"
      aria-label="basic-panel"
    >
      {/** Workflow & Data health panel */}
      {workflowInfoData && (
        <div className="grid grid-cols-2 xl:gap-5 gap-5">
          <div className="md:col-span-1 col-span-2">
            <Workflow workflowInfoData={workflowInfoData} />
          </div>
          <div className="md:col-span-1 col-span-2 flex justify-end">
            <DataHealth workflowInfoData={workflowInfoData} />
          </div>
        </div>
      )}
      <h2
        className="text-lg text-black font-semibold my-4 md:my-8 md:mb-4"
        aria-label="summary-heading"
      >
        Summary
      </h2>

      {/** Metrics panel */}
      <div className="grid grid-cols-2 gap-5">
        <div className="md:col-span-1 col-span-2 w-full">
          {activityPanelData === undefined ? (
            <LandingPageLoader showLoader={true} />
          ) : (
            activityPanelData && (
              <ActivityMetrics
                setSelectedRange={setActivityMetricsSelectedRange as any}
                selectedRange={activityMetricsSelectedRange}
                activityPanelData={activityPanelData}
              />
            )
          )}
        </div>
        <div className="md:col-span-1 col-span-2 w-full">
          {recruiterPanelData === undefined ? (
            <LandingPageLoader showLoader={true} />
          ) : (
            recruiterPanelData && (
              <RevenueMetrics
                setSelectedRange={setRevenueMetricsSelectedRange as any}
                selectedRange={revenueMetricsSelectedRange}
                recruiterPanelData={recruiterPanelData}
              />
            )
          )}
        </div>
      </div>

      {/** Performance table */}
      {activityPanelData && (
        <div className="hidden lg:block">
          <WorkflowPerformance activityPanelData={activityPanelData} />
        </div>
      )}
      {activityPanelData && (
        <div className="visible lg:hidden flex justify-center">
          <WorkflowPerformanceMobile activityPanelData={activityPanelData} />
        </div>
      )}
      {recruiterPanelData && (
        <div className="hidden lg:block">
          <RecruiterPanel recruiterPanelData={recruiterPanelData} />
        </div>
      )}
      {recruiterPanelData && (
        <div className="visible lg:hidden flex justify-center">
          <RecruiterPanelMobile recruiterPanelData={recruiterPanelData} />
        </div>
      )}
    </div>
  );
};

export default BasicComponent;
