import { CandidateRequest } from "../types/candidate";
import axios from "../utils/axiosDataHandler";

export const fetchCandidates = (param: CandidateRequest) => {

  const responseHeaders = {
    headers: {
      correlation: "Candidates Data values",
    },
  };

  return axios
    .get(
      `/workflows/ucd/candidate-drilldown?q=${param.q}&offset=${param.offset}&limit=${param.limit}&sort_field=${param.sort_field}&sort_by=${param.sort_by}`,
      responseHeaders
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
};

export const fetchTotalUsers = (param: CandidateRequest, entity: string) => {
  const responseHeaders = {
    headers: {
      correlation: "Candidates Data values",
    },
  };

  return axios
    .get(
      `/${entity}?q=${param.q}&offset=${param.offset}&limit=${param.limit}&sort_field=${param.sort_field}&sort_by=${param.sort_by}`,
      responseHeaders
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
};

export const candidateDetails = (entity: string, date: any, type: string) => {
  const responseHeaders = {
    headers: {
      correlation: "Candidates Data values",
    },
  };
  return axios
    .get(
      `/statistics/${entity}?date_from=${date}&date_to=${date}&candidate_type=${type}`,
      responseHeaders
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
};
