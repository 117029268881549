import * as React from "react";
import { Component } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

interface JobMetricsSectionPropsDefinition {
  heading: string;
  value: any;
  toolTipText: string;
  ariaLabel: string;
}

const JobMetricsSection: React.FC<
JobMetricsSectionPropsDefinition
> = ({ heading, value, toolTipText, ariaLabel }) => {
  return (
    <div className="max-w-sm rounded-xl border border-jobCoverageBorder bg-white hover:bg-jobCoverageFill">
      <div className="group relative mt-1 ml-2 max-w-fit">
        <svg className="inline-block w-[12px] h-[12px] text-xs text-tooltip" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>             
        <span className="w-60 z-50 text-xs !absolute -left-8 -top-28 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
          {toolTipText}
        </span>
      </div>
      <div
        className="px-3 3xl:py-20 2xl:py-16 xl:py-8 lg:py-8 py-16"
        aria-label={ariaLabel}
      >
        <div className="w-fit">
          <div className="flex flex-col group-hover:underline">
            <p className="font-medium text-2xl">{value}</p>
            <p className="font-normal text-xs 2xl:text-base">{heading}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobMetricsSection;
