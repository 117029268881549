import { useDispatch, useSelector } from "react-redux";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

// helpers
import {
  computedEndDate,
  computedStartDate,
} from "../../../../helpers/date.helpers";

// components
import JobMetricsDatePicker from "./JobMetricsDatePicker";
import JobMetricsRadioButton from "./JobMetricsRadio.component";
import JobMetricsSelection from "./JobMetricsSelection.component";

//reducers
import {
  setParameterName,
  setQueryparameter,
  setHeaderName,
  setFilterTags,
} from "../../../../reducers/common-reducer";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";

interface JobMetricsPropsDefinition {
  setSelectedRange: Dispatch<SetStateAction<DateRange | undefined>>;
  selectedRange: DateRange | any;
  jobPanelData: any;
}

const JobMetrics: React.FC<JobMetricsPropsDefinition> = ({
  setSelectedRange,
  selectedRange,
  jobPanelData
}) => {
  
  const navigate = useNavigate();

  //stored state
  const dispatch = useDispatch();
  const dateRange = useSelector((state: any) => state.rangeSelection.activityMetricsRange);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [tags, setTags] = useState([] as any);

  // redux state values
  const metricSelection = useSelector(
    (state: any) => state.metricsSelection.jobMetricSelection
  );

  let fromDate = computedStartDate(dateRange);
  let toDate = computedEndDate(dateRange);

  useEffect(() => {
    if (metricSelection === "Custom"){
      setShowDatePicker(true);
    }
    else {
      setShowDatePicker(false);
    }
  }, [metricSelection]);

  const handleAnchorClick = (
    event: any,
    name: any,
    header: string,
    query: any
  ) => {
    let dateRange;
    if (metricSelection === "Custom") {
      dateRange = `${format(parseISO(fromDate as any), "y-MM-dd")} - ${format(
        parseISO(toDate as any),
        "y-MM-dd"
      )}`;
    } else {
      dateRange = metricSelection;
    }

    tags.push(
      `${jobPanelData.jobs} jobs`,
      `${jobPanelData.covered} covered`,
      `${jobPanelData.cvs_per_job} CVs per job`,
      dateRange
    );
    event.preventDefault();
    dispatch(setParameterName(name));
    dispatch(setQueryparameter(query));
    dispatch(setHeaderName(header));
    dispatch(setFilterTags(tags));
    navigate(`/workflows/ucd/candidate-drilldown?q=${query}`);
  };

  return (
    <div
      className="bg-sidebar rounded-2xl px-5 py-8 border border-grey"
      aria-label="activity-metrics-panel"
    >
      <div className="grid grid-cols-6 gap-4 ml-3">
        <div className="col-span-3 md:col-span-2">
          <h2 className="font-medium text-md">Activity Metrics</h2>
        </div>
        <div className="col-span-3 md:col-span-4 flex justify-end items-end">
          <JobMetricsRadioButton />
        </div>
      </div>
      {showDatePicker ? (
        <JobMetricsDatePicker
          setSelectedRange={setSelectedRange as any}
          selectedRange={selectedRange}
          pathName={window.location.pathname}
        />
      ) : null}
      <h3
        className={
          showDatePicker
            ? "font-bold text-sm md:mt-0 text-jobCoverageBorder"
            : "font-bold text-sm md:mt-10 text-jobCoverageBorder ml-3"
        }
      >
        Jobs
      </h3>
     <div className="flex flex-col md:grid md:grid-cols-3 mt-3 md:mt-0 md:gap-6 ml-3">
        <div className="flex flex-row grid grid-cols-2 mt-3 gap-6 md:col-span-2">
          {jobPanelData.jobs > 0 ? (
            <>
              <div className="hidden lg:block">
                <a
                  href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}`}
                  onClick={(event) =>
                    handleAnchorClick(
                      event,
                      "name",
                      jobPanelData.jobs > 1
                        ? "Activity metrics"
                        : "Activity metrics",
                      `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate}`
                    )
                  }
                >
                  <JobMetricsSelection
                    heading={"Jobs"}
                    value={jobPanelData.jobs}
                    toolTipText="Total number of dormant candidates who re-engaged with your brand
                  within the selected time period."
                    ariaLabel={"candidate-info-details"}
                  />
                </a>
              </div>
              <div className="block lg:hidden">
                <JobMetricsSelection
                  heading={"Jobs"}
                  value={jobPanelData.jobs}
                  toolTipText="Total number of dormant candidates who re-engaged with your brand
                  within the selected time period."
                  ariaLabel={"candidate-info-details"}
                />
              </div>
            </>
          ) : (
            <JobMetricsSelection
              heading={"Jobs"}
              value={jobPanelData.jobs}
              toolTipText="Total number of dormant candidates who re-engaged with your brand
                  within the selected time period."
              ariaLabel={"candidate-info-details"}
            />
          )}

          {jobPanelData.covered > 0 ? (
            <>
              <div className="hidden lg:block">
                <a
                  href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (data_quality.has_valid_sms_address:true OR data_quality.has_valid_email_address:true)`}
                  onClick={(event) =>
                    handleAnchorClick(
                      event,
                      "name",
                      jobPanelData.covered > 1
                        ? "Activity metrics"
                        : "Activity metrics",
                      `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (data_quality.has_valid_sms_address:true OR data_quality.has_valid_email_address:true)`
                    )
                  }
                >
                  <JobMetricsSelection
                    heading={"Covered"}
                    value={jobPanelData.covered}
                    toolTipText="Total number of dormant candidates who re-engaged with
              your brand within the selected time period."
                    ariaLabel={"candidate-engaged-details"}
                  />
                </a>
              </div>
              <div className="block lg:hidden">
                <JobMetricsSelection
                  heading={"Covered"}
                  value={jobPanelData.covered}
                  toolTipText="Total number of dormant candidates who re-engaged with
              your brand within the selected time period."
                  ariaLabel={"candidate-engaged-details"}
                />
              </div>
            </>
          ) : (
            <JobMetricsSelection
              heading={"Covered"}
              value={jobPanelData.covered}
              toolTipText="Total number of dormant candidates who re-engaged with
              your brand within the selected time period."
              ariaLabel={"candidate-engaged-details"}
            />
          )}
        </div>
        <div className="grid grid-cols-1 mt-4 sm:mt-3">
          {jobPanelData.cvs_per_job > 0 ? (
            <>
              <div className="hidden lg:block">
                <a
                  href={`/workflows/ucd/candidate-drilldown?q=workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (NOT __null__:workflow_statistics.ucd.first_email.has_updated OR NOT __null__:workflow_statistics.ucd.second_email.has_updated OR NOT __null__:workflow_statistics.ucd.third_email.has_updated OR NOT __null__:workflow_statistics.ucd.first_sms.has_updated OR NOT __null__:workflow_statistics.ucd.first_email.has_confirmed OR NOT __null__:workflow_statistics.ucd.second_email.has_confirmed OR NOT __null__:workflow_statistics.ucd.third_email.has_confirmed OR NOT __null__:workflow_statistics.ucd.first_sms.has_confirmed)`}
                  onClick={(event) =>
                    handleAnchorClick(
                      event,
                      "name",
                      jobPanelData.cvs_per_job > 1
                        ? "Activity metrics"
                        : "Activity metrics",
                      `workflow_statistics.ucd.last_included:>=${fromDate} AND workflow_statistics.ucd.last_included:<${toDate} AND (NOT __null__:workflow_statistics.ucd.first_email.has_updated OR NOT __null__:workflow_statistics.ucd.second_email.has_updated OR NOT __null__:workflow_statistics.ucd.third_email.has_updated OR NOT __null__:workflow_statistics.ucd.first_sms.has_updated OR NOT __null__:workflow_statistics.ucd.first_email.has_confirmed OR NOT __null__:workflow_statistics.ucd.second_email.has_confirmed OR NOT __null__:workflow_statistics.ucd.third_email.has_confirmed OR NOT __null__:workflow_statistics.ucd.first_sms.has_confirmed)`
                    )
                  }
                >
                  <JobMetricsSelection
                    heading={"CVs per job"}
                    value={jobPanelData.cvs_per_job}
                    toolTipText="Total number of dormant candidates who have updated their
              contact details within the selected time period."
                    ariaLabel="candidate-updated-details"
                  />
                </a>
              </div>
              <div className="block lg:hidden">
                <div className="md:max-w-sm rounded-xl border border-jobCoverageBorder bg-white hover:bg-jobCoverageFill">
                  <div className="group relative mt-1 ml-2 max-w-fit">
                    <InformationCircleIcon className="h-6 w-6" />
                    <span className="w-60 z-50 text-xs !absolute -left-8 -top-24 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                      Total number of dormant candidates who have updated their
                      contact details within the selected time period.
                    </span>
                  </div>
                  <div
                    className="px-3 3xl:py-20 2xl:py-16 xl:py-8 lg:py-8 py-16"
                    aria-label="candidate-updated-details"
                  >
                    <div className="w-fit">
                      <div className="flex flex-col group-hover:underline">
                        <p className="font-medium text-2xl">
                          {jobPanelData.cvs_per_job}
                        </p>
                        <p className="font-normal text-xs 2xl:text-base">
                          CVs per job
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="md:max-w-sm rounded-xl border border-jobCoverageBorder bg-white hover:bg-jobCoverageFill">
              <div className="group relative mt-1 ml-2 max-w-fit">
                <InformationCircleIcon className="h-6 w-6" />
                <span className="w-60 z-50 text-xs !absolute -left-8 -top-28 scale-0 danger-arrow-bottom bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                  Total number of dormant candidates who have updated their
                  contact details within the selected time period.
                </span>
              </div>
              <div
                className="px-3 3xl:py-20 2xl:py-16 xl:py-8 lg:py-8 py-16"
                aria-label="candidate-updated-details"
              >
                <div className="w-fit">
                  <div className="flex flex-col group-hover:underline">
                    <p className="font-medium text-2xl">
                      {jobPanelData.cvs_per_job}
                    </p>
                    <p className="font-normal text-xs 2xl:text-base">
                      CVs per job
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobMetrics;
