// images
import smsImage from "../../../../assets/sms.svg";
import vincereImage from "../../../../assets/vincere.svg";
import emailImage from "../../../../assets/email.svg";
import adaIcon from "../../../../assets/Ada_icon.png";

interface WorkflowPropsDefinition {
  jobPanelData: any;
}

const WorkflowPerformance: React.FC<WorkflowPropsDefinition> = ({
  jobPanelData,
}) => {
  let emailApplied =
    (jobPanelData.email.engaged / jobPanelData.email.applied) * 100;
  let smsApplied = (jobPanelData.sms.engaged / jobPanelData.sms.applied) * 100;
  let candidatesApplied = jobPanelData.email.applied + jobPanelData.sms.applied;
  let candidatesAppliedViaEmail = jobPanelData.email.applied;
  let candidatesAppliedViaSMS = jobPanelData.sms.applied;
  let candidatesUnsubscribed =
    jobPanelData.email.unsubscribed + jobPanelData.sms.unsubscribed;
  let candidatesUnsubscribedViaEmail = jobPanelData.email.unsubscribed;
  let candidatesUnsubscribedViaSMS = jobPanelData.sms.unsubscribed;

  return (
    <div aria-label="job-coverage-workflow-performance">
      <h2
        className="text-2xl text-black font-bold mb-8 mt-10"
        aria-label="main-heading"
      >
        Workflow Performance
      </h2>
      <div className="border border-grey rounded-2xl">
        <div className="block py-4 bg-sidebar rounded-t-2xl px-6 border-b">
          <div className="grid grid-cols-7 gap-3">
            <div className="col-span-2">
              <h2 className="font-bold text-md">Event</h2>
            </div>
            <div className="col-span-1">
              <h2 className="font-bold text-md flex justify-start items-start">
                Tools
              </h2>
            </div>
            <div className="col-span-1">
              <h2 className="font-bold text-md flex justify-start items-start">
                Targets
              </h2>
            </div>
            <div className="col-span-3">
              <div className="grid grid-cols-3">
                <h2 className="font-bold text-md flex justify-start">
                  Results
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/** Job details section */}
        <div className="job-metrics-panel border-l-2 border-l-jobCoverageBorder">
          <div
            className="block py-6 px-6 bg-white border-b"
            aria-label="job-details-section"
          >
            <div className="grid grid-cols-7 gap-3">
              <div className="col-span-2 md:col-span-2 flex py-2 flex-col justify-center">
                <h2 className="font-medium text-md">Jobs</h2>
              </div>
              <div className="col-span-1">
                <h2 className="font-bold text-md flex justify-start items-start">
                  <img
                    src={vincereImage}
                    alt="Vincere Logo"
                    className="w-8 mt-3"
                  />
                </h2>
              </div>
              <div className="col-span-1">
                <div className="flex flex-col justify-start items-start mt-0 xl:mt-0">
                  <p className="font-medium text-lg">{jobPanelData.jobs}</p>
                  <p className="text-sm 2xl:text-base font-normal">
                    {jobPanelData.jobs === 0 || jobPanelData.jobs > 1
                      ? "Jobs"
                      : "Job"}
                  </p>
                </div>
              </div>
              <div className="col-span-4 md:col-span-3">
                <div className="grid grid-cols-3">
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {jobPanelData.location}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal">
                      Location
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">{jobPanelData.salary}</p>
                    <p className="text-sm 2xl:text-base font-normal">Salary</p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {jobPanelData.no_valid_details}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal text-center">
                      No details{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** Ada matches section */}
        <div className="job-metrics-panel border-l-2 border-l-yellow">
          <div
            className="block py-6 px-6 bg-white border-b"
            aria-label="ada-match-details-section"
          >
            <div className="grid grid-cols-7 gap-3">
              <div className="col-span-2 md:col-span-2 flex py-2 flex-col justify-center">
                <h2 className="font-medium text-md">Ada Matches</h2>
              </div>
              <div className="col-span-1">
                <h2 className="font-bold text-md flex justify-start items-start">
                  <img src={adaIcon} alt="Ada Icon" className="w-8 mt-3" />
                </h2>
              </div>
              <div className="col-span-1">
                <div className="flex flex-col justify-start items-start mt-0 xl:mt-0">
                  <p className="font-medium text-lg">
                    {jobPanelData.matches.matches}
                  </p>
                  <p className="text-sm 2xl:text-base font-normal">
                    {jobPanelData.matches.matches === 0 ||
                    jobPanelData.matches.matches > 1
                      ? "Matches"
                      : "Match"}
                  </p>
                </div>
              </div>
              <div className="col-span-4 md:col-span-3">
                <div className="grid grid-cols-3">
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {jobPanelData.matches.per_job}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal">Per job</p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {jobPanelData.matches.per_owner}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal">
                      Per owner
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** Email section */}
        <div className="job-metrics-panel border-l-2 border-l-purple">
          <div
            className="block py-6 px-6 bg-white border-b"
            aria-label="email-details-section"
          >
            <div className="grid grid-cols-7 gap-3">
              <div className="col-span-2 md:col-span-2 flex py-2 flex-col justify-center">
                <div className="col-span-1 flex flex-col">
                  <div className="flex flex-row">
                    <h2 className="font-medium text-md">Email</h2>
                  </div>
                  <p className="text-sm font-medium mt-1">
                    {jobPanelData.email.total_sent} emails sent
                  </p>
                </div>
              </div>
              <div className="col-span-1">
                <h2 className="font-bold text-md flex justify-start items-start">
                  <img
                    src={emailImage}
                    alt="Email Image"
                    className="w-8 mt-3"
                  />
                </h2>
              </div>
              <div className="col-span-1">
                <div className="flex flex-col justify-start items-start mt-0 xl:mt-0">
                  <p className="font-medium text-lg">
                    {jobPanelData.email.total_sent}
                  </p>
                  <p className="text-sm 2xl:text-base font-normal">
                    {jobPanelData.email.total_sent === 0 ||
                    jobPanelData.email.total_sent > 1
                      ? "Candidates"
                      : "Candidate"}
                  </p>
                </div>
              </div>
              <div className="col-span-4 md:col-span-3">
                <div className="grid grid-cols-3">
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {jobPanelData.email.engaged}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal">Engaged</p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {jobPanelData.email.applied}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal">Applied</p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {!Number.isNaN(emailApplied)
                        ? Math.round(emailApplied) + "%"
                        : "0%"}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal text-center">
                      Applied
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/** sms details section */}
          <div
            className="block py-6 px-6 bg-white border-b"
            aria-label="sms-details-section"
          >
            <div className="grid grid-cols-7 gap-3">
              <div className="col-span-2 md:col-span-2 flex py-2 flex-col justify-center">
                <div className="col-span-1 flex flex-col">
                  <div className="flex flex-row">
                    <h2 className="font-medium text-md">SMS</h2>
                  </div>
                  <p className="text-sm font-medium mt-1">
                    {jobPanelData.sms.sent} SMS sent
                  </p>
                </div>
              </div>
              <div className="col-span-1">
                <h2 className="font-bold text-md flex justify-start items-start">
                  <img src={smsImage} alt="SMS Image" className="w-8 mt-3" />
                </h2>
              </div>
              <div className="col-span-1">
                <div className="flex flex-col justify-start items-start mt-0 xl:mt-0">
                  <p className="font-medium text-lg">{jobPanelData.sms.sent}</p>
                  <p className="text-sm 2xl:text-base font-normal">
                    {jobPanelData.sms.sent === 0 || jobPanelData.sms.sent > 1
                      ? "Candidates"
                      : "Candidate"}
                  </p>
                </div>
              </div>
              <div className="col-span-4 md:col-span-3">
                <div className="grid grid-cols-3">
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {jobPanelData.sms.engaged}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal">Engaged</p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {jobPanelData.sms.applied}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal">Applied</p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {!Number.isNaN(smsApplied)
                        ? Math.round(smsApplied) + "%"
                        : "0%"}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal text-center">
                      Applied
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/** Applied details section */}
          <div
            className="block py-6 px-6 bg-white border-b"
            aria-label="applied-details-section"
          >
            <div className="grid grid-cols-7 gap-3">
              <div className="col-span-2 md:col-span-2 flex py-2 flex-col justify-center">
                <h2 className="font-medium text-md">Applied</h2>
              </div>
              <div className="col-span-1">
                <h2 className="font-bold text-md flex justify-start items-start">
                  <img
                    src={vincereImage}
                    alt="Vincere Image"
                    className="w-8 mt-3"
                  />
                </h2>
              </div>
              <div className="col-span-1">
                <div className="flex flex-col justify-start items-start mt-0 xl:mt-0">
                  <p className="font-medium text-lg">{candidatesApplied}</p>
                  <p className="text-sm 2xl:text-base font-normal">
                    {candidatesApplied === 0 || candidatesApplied > 1
                      ? "Candidates"
                      : "Candidate"}
                  </p>
                </div>
              </div>
              <div className="col-span-4 md:col-span-3">
                <div className="grid grid-cols-3">
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {candidatesAppliedViaEmail}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal">
                      Via email
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {candidatesAppliedViaSMS}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal">Via SMS</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/** Unsubscribed details section */}
          <div
            className="block py-6 px-6 bg-white border-b-0 mb-3"
            aria-label="applied-details-section"
          >
            <div className="grid grid-cols-7 gap-3">
              <div className="col-span-2 md:col-span-2 flex py-2 flex-col justify-center">
                <h2 className="font-medium text-md">Unsubscribed</h2>
              </div>
              <div className="col-span-1">
                <h2 className="font-bold text-md flex justify-start items-start">
                  <img
                    src={vincereImage}
                    alt="Vincere Image"
                    className="w-8 mt-3"
                  />
                </h2>
              </div>
              <div className="col-span-1">
                <div className="flex flex-col justify-start items-start mt-0 xl:mt-0">
                  <p className="font-medium text-lg">
                    {candidatesUnsubscribed}
                  </p>
                  <p className="text-sm 2xl:text-base font-normal">
                    {candidatesUnsubscribed === 0 || candidatesUnsubscribed > 1
                      ? "Candidates"
                      : "Candidate"}
                  </p>
                </div>
              </div>
              <div className="col-span-4 md:col-span-3">
                <div className="grid grid-cols-3">
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {candidatesUnsubscribedViaEmail}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal">
                      Via email
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-start items-start">
                    <p className="font-medium text-lg">
                      {candidatesUnsubscribedViaSMS}
                    </p>
                    <p className="text-sm 2xl:text-base font-normal">Via SMS</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowPerformance;
