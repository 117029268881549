import SideBar from "./SideBar.component";
import BasicComponent from './workflows/ucd/Basic.component';
import JobCoverage from './workflows/job_coverage/JobCoverage.component';
import { useParams } from "react-router-dom";
import path from "path";

const Workflow = () => {
  const { id } = useParams();

  return (
    <div className="flex h-screen" aria-label="workflow-panel">
      <div className="flex flex-1 overflow-hidden">
        <div className="flex bg-gray-100">
          <SideBar pathName={window.location.pathname} />
        </div>
        { id === "job_coverage" ? <JobCoverage /> : id === "ucd" ? <BasicComponent/> : <p>There is no component found</p>}
      </div>
    </div>
  );
};

export default Workflow;
