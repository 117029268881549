import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Table, ClickableButton, Button } from "@autometry/ui-components/";
import { SortingRule } from "react-table";
import { RevenueInfo } from "./hooks/useRevenueDetails";

// icons
import { MagnifyingGlassIcon, ArrowLeftIcon } from "@heroicons/react/24/solid";
import actionIcon from "../assets/action-icon.svg";

import { CSVLink } from "react-csv";

import desktopImage from "../assets/desktop.svg";
import csvIcon from "../assets/csv.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { format, isValid, parseISO } from "date-fns";
import LandingPageLoader from "./Loading/LandingPageLoader";

const storageCol = [
  {
    Header: "Name",
    accessor: "name",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.name && (
            <div className="text-sm">{props.cell.row.original.name}</div>
          )}
        </>
      );
    },
  },
  {
    Header: "Candidate Email",
    accessor: "email",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.email && (
            <div className="text-sm">{props.cell.row.original.email}</div>
          )}
        </>
      );
    },
  },
  {
    Header: "Job Title",
    accessor: "job_title",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.job_title && (
            <div className="text-sm">{props.cell.row.original.job_title}</div>
          )}
        </>
      );
    },
  },
  {
    Header: "Job Type ",
    accessor: "job_type",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.job_type && (
            <div className="text-sm">{props.cell.row.original.job_type}</div>
          )}
        </>
      );
    },
  },

  {
    Header: "Company Name",
    accessor: "company_name",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.company_name && (
            <div className="text-sm">
              {props.cell.row.original.company_name}
            </div>
          )}
        </>
      );
    },
  },
  {
    Header: "Contact Name",
    accessor: "contact_name",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.contact_name && (
            <div className="text-sm">
              {props.cell.row.original.contact_name}
            </div>
          )}
        </>
      );
    },
  },
  {
    Header: "Prior Activity",
    accessor: "prior_activity_date",
    width: "5%",
    Cell: (props: { cell: any; value: any }) => {
      if (
        props.cell.row.original.prior_activity_date.length === 0 ||
        props.cell.row.original.prior_activity_date === ""
      ) {
        return null;
      } else {
        let date = format(
          parseISO(props.cell.row.original.prior_activity_date),
          "dd/MM/y"
        );
        let time = format(
          new Date(parseISO(props.cell.row.original.prior_activity_date)),
          "HH:mm a"
        );
        return (
          <div className="flex flex-col text-sm">
            <span>{date as any}</span>
            <span className="text-darkGrey">{time as any}</span>
          </div>
        );
      }
    },
  },
  {
    Header: "Contacted",
    accessor: "contacted_date",
    width: "5%",
    Cell: (props: { cell: any; value: any }) => {
      if (props.cell.row.original.contacted_date === "") {
        return null;
      } else {
        let date = format(
          parseISO(props.cell.row.original.contacted_date),
          "dd/MM/y"
        );
        let time = format(
          new Date(parseISO(props.cell.row.original.contacted_date)),
          "HH:mm a"
        );
        return (
          <div className="flex flex-col text-sm">
            <span>{date as any}</span>
            <span className="text-darkGrey">{time as any}</span>
          </div>
        );
      }
    },
  },
  {
    Header: "1st Interview Date",
    accessor: "first_interview_date",
    width: "5%",
    Cell: (props: { cell: any; value: any }) => {
      if (
        props.cell.row.original.first_interview_date.length === 0 ||
        props.cell.row.original.first_interview_date === ""
      ) {
        return null;
      } else {
        let date = format(
          parseISO(props.cell.row.original.first_interview_date),
          "dd/MM/y"
        );
        let time = format(
          new Date(parseISO(props.cell.row.original.first_interview_date)),
          "HH:mm a"
        );
        return (
          <div className="flex flex-col text-sm">
            <span>{date as any}</span>
            <span className="text-darkGrey">{time as any}</span>
          </div>
        );
      }
    },
  },
  {
    Header: "Placement Date",
    accessor: "placement_date",
    width: "5%",
    Cell: (props: { cell: any; value: any }) => {
      if (props.cell.row.original.placement_date === "") {
        return null;
      } else {
        let date = format(
          parseISO(props.cell.row.original.placement_date),
          "dd/MM/y"
        );
        let time = format(
          new Date(parseISO(props.cell.row.original.placement_date)),
          "HH:mm a"
        );
        return (
          <div className="flex flex-col text-sm">
            <span>{date as any}</span>
            <span className="text-darkGrey">{time as any}</span>
          </div>
        );
      }
    },
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    width: "5%",
    Cell: (props: { cell: any; value: any }) => {
      if (props.cell.row.original.start_date === "") {
        return null;
      } else {
        let date = format(
          parseISO(props.cell.row.original.start_date),
          "dd/MM/y"
        );
        let time = format(
          new Date(parseISO(props.cell.row.original.start_date)),
          "HH:mm a"
        );
        return (
          <div className="flex flex-col text-sm">
            <span>{date as any}</span>
            <span className="text-darkGrey">{time as any}</span>
          </div>
        );
      }
    },
  },
  {
    Header: "Placement Profit",
    accessor: "booked_fee",
    width: "5%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.booked_fee && (
            <div className="text-sm">{props.cell.row.original.booked_fee}</div>
          )}
        </>
      );
    },
  },
  {
    Header: "Job Owner ",
    accessor: "job_owner",
    width: "10%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.job_owner && (
            <div className="text-sm">{props.cell.row.original.job_owner}</div>
          )}
        </>
      );
    },
  },
  {
    Header: "Candidate Owner",
    accessor: "candidate_owner",
    width: "5%",
    Cell: (props: { cell: any; value: any }) => {
      return (
        <>
          {props.cell.row.original.candidate_owner && (
            <div className="text-sm">
              {props.cell.row.original.candidate_owner}
            </div>
          )}
        </>
      );
    },
  },
  {
    Header: "Action",
    accessor: "ats_link",
    width: "10%",
    Cell: (row: any) => {
      return (
        <>
          {row.value && (
            <div className="group relative">
              <ClickableButton
                onClick={() => window.open(row.value, "_blank")}
                buttonProps={{
                  color: "transparent",
                  size: "small",
                  dynamicClassProperty:
                    "rounded-2xl border-successText hover:bg-success !py-2",
                }}
              >
                <img src={actionIcon} alt="action-icon" />
              </ClickableButton>

              <span className="!absolute -top-6 -left-24 scale-0 danger-arrow-right bg-white p-2 m-5 border-grey text-black border border-solid rounded-lg text-center align-content-center group-hover:scale-100">
                View Job
              </span>
            </div>
          )}
        </>
      );
    },
  },
];

interface RevenueInterviewDrillDownTableProps {}

const RevenueInterviewDrillDownTable: React.FC<
  RevenueInterviewDrillDownTableProps
> = () => {
  const navigate = useNavigate();

  let entity_name = useSelector((state: any) => state.selection.parameterName);
  let query_param = useSelector((state: any) => state.selection.queryParameter);
  let header_name = useSelector((state: any) => state.selection.headerName);
  let tagName = useSelector((state: any) => state.selection.filterTags);
  let adminUser = localStorage.getItem("admin") as any;

  const storageColumns = useMemo(() => storageCol, [storageCol]);

  const [param, setParam] = useState({
    q: query_param,
    offset: 0,
    limit: 50,
    sort_field: entity_name,
    sort_by: 1,
  });

  const {
    data: candidateData,
    isFetching: candidatesLoading,
    isError: candidateDataError,
  } = RevenueInfo(param);

  const [tableData, setTableData] = useState([] as any);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageNo, setPageNo] = useState<number>(0);
  const [sortTableBy, setSortTableBy] = useState<
    SortingRule<Record<string, unknown>>[] | undefined
  >([]);
  const [searchFilterText, setSearchFilterText] = useState<string>("" as any);

  useEffect(() => {
    if (candidateData !== undefined) {
      if (candidateData.total >= 0)
        setPageCount(Math.ceil(candidateData.total / rowsPerPage));
      if (candidateData.total === 0) {
        setPageCount(1);
        setPageNo(0);
      }
    }
  }, [candidateData, candidatesLoading, rowsPerPage]);

  useEffect(() => {
    if (!candidatesLoading && candidateData) {
      setTableData(candidateData.data);
    }
  }, [candidateData, candidatesLoading]);

  useEffect(() => {
    if (candidateDataError) {
      setTableData([]);
    }
  }, [candidateDataError]);

  useEffect(() => {
    let updatedQuery = `${searchFilterText}`;
    if (searchFilterText !== "") {
      const handler = setTimeout(() => {
        setParam((prevState) => ({
          ...prevState,
          q: updatedQuery,
        }));
      }, 2000);

      return () => {
        clearTimeout(handler);
      };
    } else {
      setParam((prevState) => ({
        ...prevState,
        q: query_param,
      }));
    }
  }, [searchFilterText]);

  const handleSearch = (event: any) => {
    let searchValue = event.target.value;

    // Set the state to trigger a re-rendering
    setSearchFilterText(searchValue);
  };

  const handleFetchData = useCallback(
    ({ sortBy, pageIndex, pageSize }: any) => {
      if (sortBy?.length) {
        let currentPageNo: number;
        if (sortBy !== sortTableBy) {
          currentPageNo = 0;
        } else {
          currentPageNo = pageIndex;
        }
        setSortTableBy(sortBy);
        setPageNo(currentPageNo);
        setRowsPerPage(pageSize);
        const sortReq = sortBy?.map((item: any) => {
          return { field: item.id, order: item.desc ? -1 : 1 };
        });

        setParam((prevState) => ({
          ...prevState,
          offset: currentPageNo * pageSize,
          limit: pageSize,
          sort_field: sortReq[0].field,
          sort_by: sortReq[0].order,
        }));
      } else {
        if (
          pageIndex !== pageNo ||
          pageSize !== rowsPerPage ||
          sortBy !== sortTableBy
        ) {
          setSortTableBy(sortBy);
          setPageNo(pageIndex);
          setRowsPerPage(pageSize);
          setParam((prevState) => ({
            ...prevState,
            offset: pageIndex * pageSize,
            limit: pageSize,
          }));
        }
      }
    },
    [sortTableBy, pageNo, rowsPerPage]
  );

  return (
    <div aria-label="recruiter-interview-drilldown-table" className="w-full">
      {/** restricting view on small devices */}
      <div className="block lg:hidden w-full">
        <div className="flex flex-col justify-center items-center h-screen">
          <img src={desktopImage} alt="desktop-image" />
          <div className="mt-5 text-center">
            <h1 className="font-bold text-3xl"> View on desktop</h1>
            <p className="font-normal text-lg mt-3 m-auto max-w-[65%]">
              This page is not available on mobile. Please visit the desktop
              site to access this page.
            </p>
          </div>
        </div>
      </div>
      {/** table display on big screens */}
      <div className="hidden lg:block w-full block p-10 mt-5 h-screen">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <div className="flex flex-row xl:mt-0 mt-8">
              <ClickableButton
                onClick={() => navigate(`/workflows/ucd`)}
                buttonProps={{
                  color: "transparent",
                  size: "small",
                  dynamicClassProperty: "rounded-2xl hover:bg-grey !py-1",
                }}
              >
                <ArrowLeftIcon className="w-6 h-6" />
              </ClickableButton>

              <p className="font-bold text-2xl pl-3">{header_name}</p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="flex items-end justify-end mb-5 mt-8 xl:mt-0">
              <div className="flex flex-row relative">
                <input
                  type="text"
                  value={searchFilterText}
                  className="px-4 w-80 rounded-full border border-black py-4 focus:ring-0 focus:border-grey focus:outline-none"
                  placeholder="Search"
                  onChange={handleSearch}
                />
                <span className="flex items-center justify-center absolute right-8 top-5">
                  <MagnifyingGlassIcon className="w-6 h-6" />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/** Filter tags and export CSV button */}
        <div className="grid grid-cols-2">
          <div className="col-span-1">
            <div className="flex flex-row mb-3">
              {tagName.map((item: any, index: any) => (
                <div key={index} className="bg-sidebar p-3 rounded-md mr-2">
                  <span key={index}>{item}</span>
                </div>
              ))}
            </div>
          </div>

          {adminUser === "true" && (
            <div className="col-span-1">
              <div className="flex justify-end items-end mb-3">
                {candidateData !== undefined ? (
                  <Button
                    size="small"
                    color="black"
                    role="button"
                    isRounded={true}
                    dynamicClassProperty="!py-3"
                  >
                    <CSVLink data={candidateData.data} filename="export">
                      <span className="flex flex-row items-center justify-center font-bold">
                        <img src={csvIcon} className="w-6 h-6 mr-2" />
                        Export data
                      </span>
                    </CSVLink>
                  </Button>
                ) : null}
              </div>
            </div>
          )}
        </div>

        {candidateData !== undefined ? (
          <Table
            columnDef={storageColumns}
            dataArray={candidateData.data}
            isLoading={candidatesLoading}
            pageCount={pageCount}
            currentPageNo={pageNo}
            fetchData={handleFetchData}
            perPage={rowsPerPage as any}
            sort={sortTableBy}
            handleSearchTerm={setSearchFilterText}
            debounceTime={275}
          />
        ) : (
          <LandingPageLoader showLoader={true} />
        )}
      </div>
    </div>
  );
};

export default RevenueInterviewDrillDownTable;
