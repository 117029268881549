import emailImage from "../../../../assets/email.svg";

interface RecruiterPanelMobilePropsDefinition {
  recruiterPanelData: any;
}

const RecruiterPanelMobile: React.FC<RecruiterPanelMobilePropsDefinition> = ({
  recruiterPanelData,
}) => {
  let viewed =
    (recruiterPanelData.candidates_viewed /
      recruiterPanelData.candidate_leads) *
    100;

  return (
    <div aria-label="recruiter-panel-mobile" className="w-[375px]">
      {/** Candidates section */}
      <div className="border border-grey border-t-0 rounded-b-xl">
        <div className="block py-5 bg-sidebar px-6 border-b">
          <h2 className="text-xl font-medium pl-3">Recruiters</h2>
        </div>
        <div className=" rounded-b-xl">
          <div className="flex flex-row border-l-2 border-metricsGreen mb-3">
            <div className="heading-section border-r border-r-grey py-5 px-6 pr-16 -mb-3">
              <div className="flex flex-col">
                <p className="font-medium mt-2 mb-3 pl-2">Tools</p>
                <p className="font-medium mb-3 pl-2">Targets</p>
                <p className="font-medium mb-4 pl-2">Results</p>
              </div>
            </div>
            <div className="content-section py-3 px-7 -mb-3">
              <div className="flex flex-col">
                <img
                  src={emailImage}
                  alt="Email Logo"
                  className="w-1/4 mb-3 mt-4"
                />
                <div
                  className="flex flex-row mb-3"
                  aria-label="total-recruiters"
                >
                  <p className="font-semibold">
                    {recruiterPanelData.total_recruiters}
                  </p>
                  <p className="text-md font-normal pl-2">Recruiters</p>
                </div>
                <div className="flex flex-col mb-3">
                  <div
                    className="flex flex-row mb-2"
                    aria-label="candidates-leads"
                  >
                    <p className="font-semibold">
                      {recruiterPanelData.candidate_leads}
                    </p>
                    <p className="text-md font-normal pl-2">Live jobs</p>
                  </div>
                  <div
                    className="flex flex-row mb-2"
                    aria-label="candidates-viewed"
                  >
                    <p className="font-semibold">
                      {recruiterPanelData.candidates_viewed}
                    </p>
                    <p className="text-md font-normal pl-2">
                      1+ CVs
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p className="font-semibold">
                      {!Number.isNaN(viewed) ? Math.round(viewed) + "%" : "0%"}
                    </p>
                    <p className="text-md font-normal pl-2">
                      3+ CVs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterPanelMobile;
