import {
  MutationFunction,
  useMutation,
  UseMutationResult,
} from "@tanstack/react-query";
import {
  AuthenticateRequest,
  AuthenticateResponse,
} from "../types/authentication";
import { setItem } from "../utils/persistanceStorage";
import { useNavigate } from "react-router";
import initialiseAnalytics from "../utils/pendoInitilisation";
import sha256 from "crypto-js/sha256";
import { Authentication } from "../services/authentication";

type AuthMutationFn = MutationFunction<
  AuthenticateRequest,
  AuthenticateResponse
>;

export const useMutateAuthenticate = (
  setIsError: (value: boolean) => void
): UseMutationResult<
  AuthenticateResponse,
  unknown,
  AuthenticateRequest,
  AuthMutationFn
> => {
  const navigate = useNavigate();
  return useMutation<
    AuthenticateResponse,
    unknown,
    AuthenticateRequest,
    AuthMutationFn
  >({
    mutationFn: (auth: AuthenticateRequest) => Authentication(auth),
    onSuccess: (data: AuthenticateResponse) => {
      if (data) {
        const {
          token,
          organisation,
          other_organisations,
          permissions,
          personal_information,
        } = data;

        setItem("token", token);
        setItem("organisation", organisation);
        setItem("otherOrgs", other_organisations);
        setItem("permissions", permissions);
        setItem("personalInfo", personal_information);
        setItem("name", personal_information.name);
        setItem("admin", permissions.admin);
        setItem("dashboard", permissions.featureDashboard);

        initialiseAnalytics({
          id: sha256(token).toString(),
          personal_information,
          organisation
        })
        
        setIsError(false);
        console.clear();
        navigate(permissions.featureDashboard ? "/dashboard" : "/workflows/ucd");

        /** Passing location parameters */
        
        /*navigate("/dashboard", { state: {
          recruiter_name: data.personal_information.name
        } });*/
      }
    },
    onError: () => {
      setIsError(true);
    },
  });
};
