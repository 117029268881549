import { combineReducers, configureStore } from "@reduxjs/toolkit";
import jobSelection from "./reducers/job-selection.reducer";
import monthSelection from "./reducers/month-selection.reducer";
import jobMetricsSelection from "./reducers/job-metrics.reducer";
import selectedItems from "./reducers/common-reducer";
import dateRangeSelection from "./reducers/date-selection.reducer";
import metricsSelection from "./reducers/metrics-reducer";

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const rootReducer = combineReducers({ 
  jobType: jobSelection,
   monthType: monthSelection,
   jobMetrics: jobMetricsSelection,
   selection: selectedItems,
   rangeSelection: dateRangeSelection,
   metricsSelection: metricsSelection
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});