import axios from "../utils/axiosHandler";

// types
import {
  ResetPasswordPayload,
  ConfirmPasswordPayload,
} from "../types/passwordAuthentication";


export const passwordReset = async (payload: ResetPasswordPayload) => {

  const data = {
    url: `http://${window.location.host}/callback`,
    principal: payload.email,
  };

  return axios
    .post('/validate/reset', data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "object") {
        throw new Error(error);
      } else console.error(error);
    });
};

export const changePassword = async (payload: ConfirmPasswordPayload) => {

  const data = {
    new_password: payload.password,
    reset_code: payload.code,
    principal: payload.email,
  };

  return axios
    .post('/validate/confirm', data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (typeof error === "string") {
        throw new Error(error);
      } else console.error(error);
    });
};
