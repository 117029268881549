import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";

// components
import Toast from "../../Toast/Toast.component";
import LoadingComponent from "../../Loading/LoadingComponent";
import { Button } from "@autometry/ui-components";

// hooks
import { useMutateAuthenticate } from "../../hooks/useMutateAuthenticate";
import { setSelectionID } from "../../../reducers/common-reducer";
import { setItem } from "../../utils/persistanceStorage";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
  remember: yup.boolean(),
});

interface PropsDefinition {
  setCurrentStage: (stage: number) => void;
}

const SignInForm = (props: PropsDefinition) => {
  //stored state
  const dispatch = useDispatch();

  const [isError, setIsError] = useState(false);
  const [displayToast, setDisplayToast] = useState(false);
  const authenticate = useMutateAuthenticate(setIsError);
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: { remember: false },
  });

  function formSubmit(data: any) {
    dispatch(setSelectionID(""));
    setItem("selectedID", "");
    // handle submitting the form
    authenticate.mutate(
      {
        email: data.email,
        password: data.password,
        rememberMe: data.remember,
      },
      {
        onSuccess: (res: any) => {
          if (!res) {
            setDisplayToast(true);
            setIsError(true);
          }
        },
        onError: () => {
          setIsError(true);
        },
      }
    );
  }

  if (authenticate.isError) {
    localStorage.clear();
    queryClient.clear();
  }

  if (authenticate.isLoading) {
    return <LoadingComponent showLoader={true} />;
  }

  return (
    <div
      className="flex lg:h-screen w-full justify-center items-center w-full flex-col"
      aria-label="sign-in-form"
    >
      {displayToast ? (
        <Toast
          displayToast={displayToast}
          text={"No active account found with the given credentials"}
        />
      ) : null}
      <h2 className="text-black font-bold text-3xl pb-8 text-center">
        Sign In
      </h2>

      <div className="w-1/2 lg:w-1/2 xl:w-2/5 2xl:w-2/5 3xl:w-1/4 block">
        <form onSubmit={handleSubmit(formSubmit)}>
          {/** Email form field */}
          <div className="mb-8">
            <label
              htmlFor="email"
              className={`block font-normal text-sm mb-2 text-left ${
                errors.email ? "text-red-400" : "text-black"
              }`}
            >
              {errors.email ? "Error - Email address" : "Email address"}
            </label>
            <input
              type="text"
              {...register("email")}
              id="email"
              role="textbox"
              className={`block w-full bg-transparent border-b py-2 px-4 rounded placeholder-grey focus:border-black focus:outline-none focus:ring-transparent ${
                errors.email
                  ? "text-red-300 border-red-400 focus:border-red-400"
                  : "text-black border-lightgrey"
              }`}
            />
          </div>
          {/** Password form field */}
          <div className="mb-5">
            <label
              htmlFor="password"
              className={`block font-normal text-sm mb-2 text-left ${
                errors.password ? "text-red-400" : "text-black"
              }`}
            >
              {errors.password ? "Error - Password" : "Password"}
            </label>
            <input
              type="password"
              {...register("password")}
              id="password"
              role="textbox"
              className={`block w-full bg-transparent border-b py-2 px-4 rounded placeholder-grey focus:border-black focus:outline-none focus:ring-transparent ${
                errors.password
                  ? "border-red-400 focus:border-red-400"
                  : "border-lightgrey text-black"
              }`}
            />
          </div>
          {/** Forgot Password form field */}
          <div className="mb-5">
            <div className="grid grid-cols-2 gap4">
              <div className="col-auto">
                <input
                  id="remember-me"
                  {...register("remember")}
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-black border-gray-300 rounded focus:ring-0 cursor-pointer"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 text-sm font-medium text-gray-900"
                >
                  Remember me
                </label>
              </div>
              <div className="col-auto flex justify-end pt-1">
                <button
                  type="button"
                  className="text-sm text-darkGreen dark:text-green hover:text-lightgreen hover:text-underline font-medium"
                  onClick={() => props.setCurrentStage(2)}
                >
                  Forgot Password?
                </button>
              </div>
            </div>
          </div>
          <Button
            children="Sign in"
            size="large"
            color="black"
            role="button"
            isRounded={true}
            dynamicClassProperty="w-full"
          />
        </form>
      </div>
    </div>
  );
};

export default SignInForm;
