import React from "react"
import { useMutation } from "@tanstack/react-query";
import { passwordReset } from "../services/passwordAuthentication";

const useResetPasswordMutation = (setIsError: React.Dispatch<React.SetStateAction<boolean>>, setDisplayToast: React.Dispatch<React.SetStateAction<boolean>>) => useMutation({
	mutationFn: (payload: { email: string }) => passwordReset(payload),
	onError: () => {
		setIsError(true)
		setDisplayToast(true);
	}
})

export default useResetPasswordMutation
