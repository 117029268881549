import { createSlice } from "@reduxjs/toolkit";

export const selectedItems = createSlice({
  name: "selection",
  initialState: {
    selectionID: 0,
    parameterName: "",
    queryParameter: "",
    headerName: "",
    activityMetrics: false,
    revenueMetrics: false,
    jobMetrics: false,
    dateSelected: false,
    filterTags: [],
  },
  reducers: {
    setSelectionID: (state, action) => {
      state.selectionID = action.payload;
    },
    setParameterName: (state, action) => {
      state.parameterName = action.payload;
    },
    setQueryparameter: (state,action) => {
      state.queryParameter = action.payload
    },
    setHeaderName: (state,action) => {
      state.headerName = action.payload
    },
    setFilterTags: (state,action) => {
      state.filterTags = action.payload
    },
    setDateSelected: (state,action) => {
      state.dateSelected = action.payload
    },
    setActivityMetricsRange: (state,action) => {
      state.activityMetrics = action.payload
    },
    setRevenueMetricsRange: (state,action) => {
      state.revenueMetrics = action.payload
    },
    setJobMetricsRange: (state,action) => {
      state.jobMetrics = action.payload
    },
  },
});

export const { setSelectionID, setParameterName, setQueryparameter, setHeaderName, setFilterTags, setActivityMetricsRange, setRevenueMetricsRange, setJobMetricsRange, setDateSelected } = selectedItems.actions;

export default selectedItems.reducer;
