import { createSlice } from "@reduxjs/toolkit";

export const metricsSelection = createSlice({
  name: "metricSelection",
  initialState: {
    activityMetricSelection: "Last 30 days",
    revenueMetricSelection: "Last 30 days",
    jobMetricSelection: "Last 30 days",
  },
  reducers: {
    setSelectedActivityMetricsType: (state, action) => {
      state.activityMetricSelection = action.payload;
    },
    setSelectedRevenueMetrics: (state, action) => {
      state.revenueMetricSelection = action.payload;
    },
    setSelectedJobMetricsType: (state, action) => {
      state.jobMetricSelection = action.payload;
    },
  },
});

export const {
  setSelectedActivityMetricsType,
  setSelectedRevenueMetrics,
  setSelectedJobMetricsType,
} = metricsSelection.actions;

export default metricsSelection.reducer;
